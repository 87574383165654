import React from "react";
import { Modal, Nav, Tab, Row, Col, Image } from "react-bootstrap";
import Profiles from "./DashboardModal/Profiles";
import Notifications from "./DashboardModal/Notifications";
import QrCodes from "./DashboardModal/QrCodes";
import "./DashboardModal/dashboard.css";
import Settings from "./DashboardModal/Settings";
import logo from "../assests/logo.png";
import icon1 from "../assests/modal-1.svg";
import icon2 from "../assests/modal-2.svg";
import icon3 from "../assests/modal-3.svg";
import icon4 from "../assests/modal-4.svg";

const DashboardModal = ({ show, handleClose, activeKey, setActiveKey, notificationLength }) => {
  return (
    <Modal
      className="dash-modal"
      show={show}
      onHide={handleClose}
      size="xl"
      centered
    >
      <Modal.Header className="border-bottom-0" closeButton style={{ backgroundColor: "#2B9595" }} >
        <Image src={logo} className="ms-3" height={40} />
      </Modal.Header>
      <Modal.Body className="py-0 dash-body">
        <Tab.Container
          className=""
          id=""
          activeKey={activeKey}
          onSelect={(k) => setActiveKey(k)}
        >
          <Row>
            <Col sm={12} lg={3} className="relative border-end py-3  ">
              <Nav variant="pills" className="flex-column gap-2">
                <Nav.Item >
                  <Nav.Link className="d-flex flex-row" eventKey="first">
                    <Image src={icon1} />
                    <span className="mb-0 ms-2">Profiles</span> </Nav.Link>
                </Nav.Item>
                <Nav.Item className="">
                  <Nav.Link className="d-flex flex-row align-items-center justify-content-between" eventKey="second">
                    <div>
                      <Image src={icon2} />
                      <span className="mb-0 ms-2">Notifications</span>
                    </div>
                    {notificationLength !== 0 && (
                      <span className="badge badge-notify" style={{ position: 'static' }}>{notificationLength}</span>
                    )}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="">
                  <Nav.Link className="d-flex flex-row" eventKey="third">
                    <Image src={icon3} />
                    <span className="mb-0 ms-2">QR Codes</span> </Nav.Link>
                </Nav.Item>
                <Nav.Item
                  className="settings"
                >
                  <Nav.Link className="d-flex flex-row" eventKey="fourth">
                    <Image src={icon4} />
                    <span className="mb-0 ms-2">Settings</span> </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={12} lg={9} className="dash-tabs" style={{ overflow: "auto" }}>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <Profiles handleClose={handleClose} />
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <Notifications />
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                  <QrCodes />
                </Tab.Pane>
                <Tab.Pane eventKey="fourth">
                  <Settings />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer> */}
    </Modal>
  );
};

export default DashboardModal;
