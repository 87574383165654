// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging} from 'firebase/messaging';
const firebaseConfig = {
  apiKey: "AIzaSyCgd94V2bGSPX9ioyZEqnzcmNhqoCRsDXE",
  authDomain: "capsule-of-memories.firebaseapp.com",
  projectId: "capsule-of-memories",
  storageBucket: "capsule-of-memories.appspot.com",
  messagingSenderId: "549459648577",
  appId: "1:549459648577:web:a2abc4460562d82fa966c0",
  measurementId: "G-HV148LSV9F"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging };