// Template1.js
import React, { useState, useEffect } from 'react';
import { Button, Image, Modal } from 'react-bootstrap';
import Cross from "../../assests/cross-tracker.png"
import upload from "../../assests/profile-upload.png"
import profile from "../../assests/smallupload.png";
import { FaCheck, FaPencilAlt, FaRegCalendarAlt, FaTimes } from 'react-icons/fa'
import EditDateModal from './EditDateModal';
import { toast } from 'react-toastify';
import EditNameModal from './EditNameModal';
import { uploadUserFile } from '../../services/user/user.service';
import moment from 'moment';
import Cropper from "react-easy-crop";
import { useCallback } from 'react';
import getCroppedImg from '../ImageCrop/CropImage';
import EditModal from './EditModal';

const Template1 = ({ isEditingPage, templateHead, prayerHead, prayer, profilename, dob, deceasedDate, profileImage, memorialLocation, onUpdate }) => {
    const [localFullname, setLocalFullname] = useState(profilename);
    const [show, setShow] = useState(false);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedArea, setCroppedArea] = useState(null);
    const [localDob, setLocalDob] = useState(dob);
    const [localTemplateHead, setLocalTemplateHead] = useState(templateHead);
    const [localPrayerHead, setLocalPrayerHead] = useState(prayerHead);
    const [localPrayer, setLocalPrayer] = useState(prayer);
    const [localDeceasedDate, setLocalDeceasedDate] = useState(deceasedDate);
    const [localProfileImage, setLocalProfileImage] = useState(profileImage);
    const [localProfileImageCrop, setLocalProfileImageCrop] = useState(profileImage);
    const [showModal, setShowModal] = useState(false);
    const [showDateModal, setShowDateModal] = useState(false);
    const [showTemplateHeadModal, setShowTemplateHeadModal] = useState(false);
    const [showPrayerHeadnModal, setShowPrayerHeadModal] = useState(false);
    const [showPrayerModal, setShowPrayerModal] = useState(false);

    useEffect(() => {
        setLocalFullname(profilename);
        setLocalDob(dob);
        setLocalDeceasedDate(deceasedDate);
        setLocalProfileImage(profileImage);
        setLocalProfileImageCrop(profileImage)
        setLocalPrayer(prayer)
    }, [profilename, dob, deceasedDate, profileImage, localPrayer]);

    const handleNameSave = (newName) => {
        setLocalFullname(newName);
        onUpdate(localTemplateHead, localPrayerHead, localPrayer, newName, localDob, localDeceasedDate, localProfileImage, memorialLocation);
    };

    const handleDateSave = (newDOB, newDOD) => {
        setLocalDob(newDOB);
        setLocalDeceasedDate(newDOD);
        onUpdate(localTemplateHead, localPrayerHead, localPrayer, localFullname, newDOB, newDOD, localProfileImage, memorialLocation);
    };
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedArea(croppedAreaPixels);
    }, []);

    const handleCropSave = async () => {
        const toastId = toast.loading("Saving cropped image", { closeButton: true });
        try {
            const croppedImageBlob = await getCroppedImg(localProfileImage, croppedArea);
            // Convert Blob to File
            const croppedImageFile = new File([croppedImageBlob], "cropped.jpg", {
                type: "image/jpeg",
            });
            const formData = new FormData();
            formData.append('attachments', croppedImageFile);
            const response = await uploadUserFile(formData);
            setLocalProfileImage(response.data.data[0]);
            onUpdate(localTemplateHead, localPrayerHead, localPrayer, localFullname, localDob, localDeceasedDate, response.data.data[0], memorialLocation);
            toast.update(toastId, {
                render: "Image updated successfully",
                type: "success",
                isLoading: false,
                autoClose: 2000,
                hideProgressBar: true,
                closeButton: false,
            });
            setShow(false);
        } catch (e) {
            toast.update(toastId, {
                render: "Error saving cropped image",
                type: "error",
                isLoading: false,
                autoClose: 2000,
                hideProgressBar: true,
                closeButton: false,
            });
            console.error(e);
        }
    };
    const handleImageUpload = async (e) => {
        e.preventDefault();
        const toastId = toast.loading("Uploading Image", { closeButton: true });
        const file = e.target.files[0];
        e.target.value = null;
        if (file) {
            try {
                const formData = new FormData();
                formData.append('attachments', file);
                const response = await uploadUserFile(formData);
                setLocalProfileImage(response.data.data[0]);
                toast.update(toastId, {
                    render: "Image Uploaded. Please crop the image.",
                    type: "success",
                    isLoading: false,
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeButton: false,
                });
                handleShowImage(); // Show the cropping modal
            } catch (error) {
                toast.update(toastId, {
                    render: error.response.data.data,
                    type: "error",
                    isLoading: false,
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeButton: false,
                });
            }
        }
    };
    const handlePrayer = (newPrayer) => {
        setLocalPrayer(newPrayer);
        onUpdate(localTemplateHead, localPrayerHead, newPrayer, localFullname, localDob, localDeceasedDate, localProfileImage, memorialLocation);
    };
    const handlePrayerHead = (newPrayerHead) => {
        setLocalPrayerHead(newPrayerHead);
        onUpdate(localTemplateHead, newPrayerHead, localPrayer, localFullname, localDob, localDeceasedDate, localProfileImage, memorialLocation);
    };
    const handleTemplateHead = (newTemplateHead) => {
        setLocalTemplateHead(newTemplateHead);
        onUpdate(newTemplateHead, localPrayerHead, localPrayer, localFullname, localDob, localDeceasedDate, localProfileImage, memorialLocation);
    };
    const handleCloseImage = () => {
        setShow(false);
        setLocalProfileImage(localProfileImageCrop);
    };
    const handleShowImage = () => setShow(true);
    const formattedDob = localDob !== "Enter Date of Birth" ? moment(localDob).format('LL') : localDob;
    const formattedDeceasedDate = localDeceasedDate !== "Enter Date Of Death" ? moment(localDeceasedDate).format('LL') : localDeceasedDate;
    return (
        <>
            <div className="my-1">
                <div className="py-4 " style={{ width: "570px", height: "940px", backgroundColor: 'white' }}>
                    <div className="row align-items-center  justify-content-center">
                        <div className="">
                            <div className="text-center w-100 justify-content-center d-flex gap-2">
                                <h4 style={{ width: '13rem' }}>{localTemplateHead}</h4>
                                {isEditingPage && (
                                    <FaPencilAlt
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setShowTemplateHeadModal(true)}
                                    />
                                )}
                            </div>
                            <div className="text-center justify-content-center d-flex gap-2">
                                <p className="yellow-color fs-3 text-capitalize">
                                    <i>{localFullname}</i>
                                </p>
                                {isEditingPage && (
                                    <FaPencilAlt
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setShowModal(true)}
                                    />
                                )}
                            </div>
                            <div className="w-100 d-flex gap-2 align-items-center justify-content-center pt-2">
                                <h4 className="text-center mb-0">
                                    {formattedDob} - <br />
                                    {formattedDeceasedDate}
                                </h4>
                                {isEditingPage && (
                                    <FaRegCalendarAlt
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setShowDateModal(true)}
                                    />
                                )}
                            </div>
                            <div className="position-relative">
                                <div className="outer-div-img mt-3">
                                    <Image
                                        src={localProfileImage || profile}
                                        alt="user"
                                        className="Image"
                                        style={{ objectFit: 'cover' }}
                                    />
                                    {isEditingPage && (
                                        <div className="camera-icon-temp">
                                            <label htmlFor="dpImageInput" className="">
                                                <div className="upload-icon">
                                                    <Image
                                                        src={upload}
                                                        className="w-100 h-100"
                                                        alt="upload-icon"
                                                    />
                                                </div>
                                                <input
                                                    id="dpImageInput"
                                                    type="file"
                                                    accept="image/jpeg, image/png"
                                                    style={{ display: "none" }}
                                                    onChange={handleImageUpload}
                                                />
                                            </label>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="text-center pt-3  justify-content-center d-flex gap-2">
                                <h1>{localPrayerHead}</h1>
                                {isEditingPage && (
                                    <FaPencilAlt
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setShowPrayerHeadModal(true)}
                                    />
                                )}
                            </div>
                            <div className="text-center">
                                <Image src={Cross} alt="cross" width={150} />
                            </div>
                            <div className="text-center w-100  justify-content-center d-flex gap-2 pt-3">
                                <h3 className="fw-light mb-0" style={{ width: "19rem" }}>
                                    {localPrayer}
                                </h3>
                                {isEditingPage && (
                                    <FaPencilAlt
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setShowPrayerModal(true)}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Edit Modal */}
            <EditNameModal
                initialValue={localFullname}
                onSave={handleNameSave}
                onHide={() => setShowModal(false)} // Close modal on hide
                show={showModal} // Pass showModal state to control modal visibility
            />
            <EditDateModal
                dob={formattedDob}
                dod={formattedDeceasedDate}
                onSave={handleDateSave}
                onHide={() => setShowDateModal(false)} // Close modal on hide
                show={showDateModal} // Pass showModal state to control modal visibility
            />
            <EditModal
                label="Template Head"
                limit={36}
                initialValue={localTemplateHead}
                onSave={handleTemplateHead}
                onHide={() => setShowTemplateHeadModal(false)}
                show={showTemplateHeadModal}
            />
            <EditModal
                label="Prayer Head"
                limit={22}
                initialValue={localPrayerHead}
                onSave={handlePrayerHead}
                onHide={() => setShowPrayerHeadModal(false)}
                show={showPrayerHeadnModal}
            />
            <EditModal
                label="Prayer"
                limit={65}
                initialValue={localPrayer}
                onSave={handlePrayer}
                onHide={() => setShowPrayerModal(false)}
                show={showPrayerModal}
            />
            <Modal show={show} onHide={handleCloseImage} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Profile Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex align-items-center justify-content-center modal-crop">
                        <Cropper
                            image={localProfileImage}
                            crop={crop}
                            zoom={zoom}
                            aspect={1 / 1.5}
                            onCropChange={setCrop}
                            onZoomChange={setZoom}
                            onCropComplete={onCropComplete}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="edit-btn border d-flex align-items-center justify-content-center gap-1 fw-bolds" onClick={handleCloseImage} style={{ color: 'black', backgroundColor: 'transparent', borderColor: '#dee2e6' }}>
                        <FaTimes size={15} color="black" />
                        CANCEL
                    </Button>
                    <Button className="edit-btn border d-flex align-items-center justify-content-center gap-2 fw-bold" onClick={handleCropSave}>
                        <FaCheck size={13} color="white" />
                        Crop & Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Template1;
