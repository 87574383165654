import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import pic1 from "../../assests/anonymous-avatar-icon-25.png";
import { Button, Col, Image, ProgressBar, Row } from 'react-bootstrap';
import { getProfiles, createProfileForUser } from '../../services/Dashboard/dashboard.service';
import ImageModal from '../ImageModal/ImageModal';

const Profiles = ({ handleClose }) => {
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedName, setSelectedName] = useState();
  const BASE_URL = process.env.REACT_APP_WEB_BASE_URL;

  const fetchProfiles = async () => {
    setLoading(true);
    try {
      const response = await getProfiles();
      setProfiles(response.data.data);
    } catch (error) {
      toast.error('Error fetching profiles');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProfiles();
  }, []);

  const handleCreate = async () => {
    setCreating(true);
    toast.info('Creating profile...', { autoClose: false, toastId: 'creatingProfile' });

    try {
      const response = await createProfileForUser();
      toast.update('creatingProfile', {
        render: 'Profile created successfully!',
        type: "success",
        autoClose: 5000
      });
      fetchProfiles();
    } catch (error) {
      toast.update('creatingProfile', {
        render: "Error creating profile",
        type: "error",
        isLoading: false,
        autoClose: 2000,
        hideProgressBar: true,
        closeButton: false,
      });
      console.error(error);
    } finally {
      setCreating(false);
    }
  };

  const handleView = (profileId) => {
    const viewUrl = `${BASE_URL}/${profileId}`;
    window.location.href = viewUrl;
    handleClose();
  };

  const handleEdit = (profileId) => {
    const editUrl = `${BASE_URL}/${profileId}?edit=true`;
    window.location.href = editUrl;
    handleClose();
  };

  const openImageModal = (imageUrl, name) => {
    setSelectedImage(imageUrl);
    setSelectedName(name)
    setShowImageModal(true);
  };

  const closeImageModal = () => {
    setShowImageModal(false);
    setSelectedImage(null);
  };

  return (
    <div className='p-3'>
      <div className='d-flex flex-row justify-content-between '>
        <h3>Profiles</h3>
        <Button
          className="fw-bold see-btn"
          onClick={handleCreate}
          disabled={creating}
        >
          {creating ? 'Creating...' : '+ Create'}
        </Button>
      </div>
      <Row className='px-2'>
        {loading ? (
          <div>Loading profiles...</div>
        ) : (
          profiles.map((profile, index) => (
            <Col key={index} sm={12} className='prof-item rounded-4 py-4 px-3 text-white position-relative my-2'>
              <div className='d-flex flex-row gap-2 btn-div'>
                <Button className='prof-btn' onClick={() => handleView(profile._id)}>View</Button>
                <Button className='prof-btn' onClick={() => handleEdit(profile._id)}>Edit</Button>
              </div>
              <Row>
                <Col
                  sm={2}
                  className='img-col ms-2 mb-2 ms-lg-0 mb-lg-0 text-start text-lg-center px-0'
                  onClick={() => openImageModal(profile?.profileImage || pic1, profile.fullname || "Profile Name")}
                  style={{ cursor: 'pointer' }}
                >
                  <Image src={profile?.profileImage || pic1} className='pro-pic' />
                </Col>
                <Col sm={10} className='prof-detail'>
                  <h5 className='mb-0'>{profile.fullname || "Profile Name"}</h5>
                  <ul className={`m-0 privacy-bullet ${profile.isPrivate ? 'private' : 'public'}`}>
                    <li className='d-flex align-items-center' style={{ fontSize: "15px" }}>{profile.isPrivate ? "Private" : "Public"}</li>
                  </ul>
                  <p style={{ fontSize: "10px" }} className='mt-3'>Profile Completed</p>
                  <div className='d-flex flex-row align-items-center' style={{ marginTop: "-20px" }}>
                    <ProgressBar variant="danger" className='w-100' style={{ height: "10px" }} now={profile?.profileCompletion || 0} />
                    <span className='ps-2'>{Math.trunc(profile?.profileCompletion) || "0"}%</span>
                  </div>
                </Col>
              </Row>
            </Col>
          ))
        )}
      </Row>
      <ImageModal
        show={showImageModal}
        onHide={closeImageModal}
        imageUrl={selectedImage}
        Name={selectedName}
      />
    </div>
  );
};

export default Profiles;
