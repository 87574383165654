import React, { useState, useEffect } from 'react';
import { Col, Image, Row, Button, Modal, Form, Dropdown } from 'react-bootstrap';
import defaultUploader from "../assests/uploader.png";
import Trash from "../assests/trash.svg";
import { toast } from 'react-toastify';
import { uploadUserFile } from '../services/user/user.service';
import ImageModal from './CommentModal/ImageModal';
import CaptionModal from './CommentModal/CaptionModal';

const Photos = ({ isEditingPage, photos = [], updatePhotos, deleteArray = [], updateDeleteArray, isloading, mediaId, fetchUser }) => {
    const [visibleImages, setVisibleImages] = useState(5);
    const [showModal, setShowModal] = useState(false);
    const [showCaptionModal, setShowCaptionModal] = useState(false);
    const [showCaptionEditModal, setShowCaptionEditModal] = useState(false);
    const [selectedCaption, setSelectedCaption] = useState();
    const [editCaption, setEditCaption] = useState();
    const [photomediaId, setPhotoMediaId] = useState();
    const [profileId, setProfileId] = useState();
    const [selectedImage, setSelectedImage] = useState(null);
    const [captions, setCaptions] = useState({});
    const [commentsID, setCommentsID] = useState([]);
    const [newPhotos, setNewPhotos] = useState([]);
    const [opened, setOpened] = useState(false);

    useEffect(() => {
        if (mediaId && !opened) {
            const matchingPhoto = photos.find(photo => photo._id === mediaId);
            if (matchingPhoto) {
                openModal(matchingPhoto);
                setOpened(true);
            }
        }
    }, [mediaId, photos]);

    const handleDelete = (src) => {
        const updatedDeleteArray = [...deleteArray, src._id];
        updateDeleteArray(updatedDeleteArray);
        const updatedPhotos = photos.filter((photo) => photo !== src);
        updatePhotos(updatedPhotos);
    };

    const handleLoadMore = () => {
        setVisibleImages(prevVisibleImages => prevVisibleImages + 12);
    };

    const handleImageUpload = async (e) => {
        const toastId = toast.loading("Uploading...", { closeButton: true });
        const files = Array.from(e.target.files);
        e.target.value = null;

        // Check if the number of files exceeds 10
        if (files.length > 10) {
            toast.update(toastId, {
                render: "You can upload up to 10 images at a time.",
                type: "warning",
                isLoading: false,
                autoClose: 2000,
                hideProgressBar: true,
                closeButton: false,
            });
            return;
        }

        // Check if any file exceeds 4MB
        const oversizedFiles = files.filter(file => file.size > 4 * 1024 * 1024); // 4MB in bytes
        if (oversizedFiles.length > 0) {
            toast.update(toastId, {
                render: "Each image must be less than 4MB.",
                type: "warning",
                isLoading: false,
                autoClose: 2000,
                hideProgressBar: true,
                closeButton: false,
            });
            return;
        }

        if (files.length > 0 && files.length <= 10) {
            try {
                const formData = new FormData();
                files.forEach(file => {
                    formData.append('attachments', file);
                });

                const response = await uploadUserFile(formData);
                const newImageSources = response.data.data; // Assuming response contains the new image sources as an array

                const newPhotos = newImageSources.map(src => ({
                    data: {
                        urls: [src],
                    },
                    type: 'photo',
                }));

                setNewPhotos(newPhotos);
                setSelectedImage(newPhotos[0].data.urls[0]); // Set the first image as selected by default
                setShowCaptionModal(true);
                toast.dismiss(toastId);
            } catch (error) {
                toast.update(toastId, {
                    render: error?.response?.data?.error,
                    type: "error",
                    isLoading: false,
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeButton: false,
                });
            }
        } else {
            toast.update(toastId, {
                render: "Please select up to 10 images.",
                type: "warning",
                isLoading: false,
                autoClose: 2000,
                hideProgressBar: true,
                closeButton: false,
            });
        }
    };


    const handleCaptionChange = (e) => {
        const updatedCaptions = {
            ...captions,
            [selectedImage]: e.target.value,
        };
        setCaptions(updatedCaptions);
    };

    const handleSaveCaption = () => {
        const updatedPhotos = newPhotos.map(photo => {
            const url = photo.data.urls[0];
            return {
                ...photo,
                data: {
                    ...photo.data,
                    caption: captions[url] || '',
                },
            };
        });

        updatePhotos([...photos, ...updatedPhotos]);
        setShowCaptionModal(false);
        setCaptions({});
        setSelectedImage(null);
    };

    const openModal = (src) => {
        if (src && src.data && src.data.urls && src.data.urls[0] && src._id) {
            setSelectedImage(src.data.urls[0]);
            setSelectedCaption(src.data.caption);
            setCommentsID(src._id);
            setShowModal(true);
        }
    };

    const closeModalCaption = () => {
        setShowCaptionModal(false);
        setSelectedImage(null);
    };

    const closeModal = () => {
        setSelectedImage(null);
        setShowModal(false);
    };
    const closeEditModal = () => {
        setShowCaptionEditModal(false);
    }
    const openEditModal = (photo) => {
        setSelectedImage(photo.data.urls[0]);
        setProfileId(photo?.profile)
        console.log("MEdia Id", photo.profile);
        setPhotoMediaId(photo?.data?._id)
        setEditCaption(photo?.data?.caption)
        setShowCaptionEditModal(true);
    };

    return (
        <div className=''>
            <Row className='mx-3 pb-2 mt-4'>
                {isloading ? (
                    <div className="d-flex justify-content-center">
                        <div className="loader-small"></div>
                    </div>
                ) : (
                    photos.length === 0 ? (
                        <div className="text-center w-100">
                            <p className='about-section'>No Photos</p>
                        </div>
                    ) : (
                        photos?.slice(0, visibleImages).map((photo, index) => (
                            <Col key={index} xs={6} md={4} className='pb-4'>
                                <div className='min-img-size position-relative card-item'>
                                    {/* {isEditingPage && (
                                        <Button className="remove-btn" onClick={() => handleDelete(photo)}>
                                            <Image src={Trash} />
                                        </Button>
                                    )} */}
                                    {isEditingPage && photo?.data?._id && (
                                        <Dropdown className="remove-btn-dropdown">
                                            <Dropdown.Toggle className="custom-drop-edit px-2" variant="link" id="dropdown-basic" style={{ textDecoration: "none" }}>
                                                •••
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => openEditModal(photo)}>Edit Caption</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleDelete(photo)}>Delete</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    )}

                                    <Image src={photo.data.urls[0]} className='gallery-img' alt='gallery' onClick={() => openModal(photo)} />
                                </div>
                            </Col>
                        ))
                    ))}
                {!isloading && isEditingPage && (
                    <Col xs={6} md={4} className='pb-4'>
                        <label htmlFor="fileInput" className='w-100'>

                            <div className='min-img-size position-relative'>
                                <div
                                    className="text-center mx-auto w-100"
                                    style={{
                                        position: "absolute",
                                        top: "20px",
                                        left: "50%",
                                        transform: "translateX(-50%)",
                                        margin: "auto"
                                    }}
                                >
                                    <p className="mb-0 w-100" style={{ fontSize: "14px", color: "grey" }}>Up to 10 Multiple Files</p>
                                </div>
                                <Image src={defaultUploader} className='gallery-img' alt='uploader' style={{ cursor: "pointer" }} />
                            </div>
                        </label>
                        <input id="fileInput" type="file" accept="image/jpeg, image/png, image/webp" multiple onChange={handleImageUpload} style={{ display: 'none' }} />
                    </Col>
                )}
            </Row>
            {visibleImages < photos.length && (
                <div className="text-center">
                    <Button className='load-btn' onClick={handleLoadMore}>Load More...</Button>
                </div>
            )}

            <Modal show={showCaptionModal} onHide={closeModalCaption}>
                <Modal.Header closeButton>
                    <Modal.Title className='sign-heading text-center pb-2'>Add Caption</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3 d-flex gap-2 " style={{ overflow: "auto" }}>
                        {newPhotos && newPhotos.map((photo, index) => (
                            <div key={index} >
                                <Image
                                    src={photo.data.urls[0]}
                                    className={`gallery-img ${selectedImage === photo.data.urls[0] ? 'selected' : ''}`}
                                    alt='thumbnail'
                                    onClick={() => setSelectedImage(photo.data.urls[0])}
                                    style={{ cursor: 'pointer', height: "100px", width: "100px", border: selectedImage === photo.data.urls[0] ? '2px solid blue' : 'none' }}
                                />
                            </div>
                        ))}
                    </div>
                    <div className='mx-auto d-flex align-items-center'>
                        <Image src={selectedImage} style={{ width: "300px", height: "300px", objectFit: "contain" }} className='gallery-img mb-3 mx-auto' alt='selected' />

                    </div>
                    <Form.Group controlId="caption" className='pt-3'>
                        <Form.Label className='pass-input'>Caption</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter caption"
                            value={captions[selectedImage] || ''}
                            onChange={handleCaptionChange}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModalCaption}>
                        Close
                    </Button>
                    <Button variant="primary" className='login-button' onClick={handleSaveCaption}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
            <CaptionModal
                showCaptionEditModal={showCaptionEditModal}
                closeEditModal={closeEditModal}
                selectedImage={selectedImage}
                profileId={profileId}
                photomediaId={photomediaId}
                editCaption={editCaption}
                fetchUser={fetchUser}
                type="Photo"
            />
            <ImageModal
                showModal={showModal}
                isEditingPage={isEditingPage}
                closeModal={closeModal}
                selectedImage={selectedImage}
                commentId={commentsID}
                caption={selectedCaption}
            />
        </div>
    );
};

export default Photos;
