import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

const EditDateModal = ({ dob, dod, onSave, onHide, show }) => {
    const [DOB, setDOB] = useState(dob); // State for Date of Birth
    const [DOD, setDOD] = useState(dod); // State for Date of Death

    const handleSave = () => {
        const toastId = toast.loading("Updating", { closeButton: true });
        toast.update(toastId, {
            render: "Date is Succesfully Updated",
            type: "success",
            isLoading: false,
            autoClose: 2000,
            hideProgressBar: true,
            closeButton: false,
        });
        onSave(DOB, DOD); // Pass updated values to parent component
        onHide(); // Close the modal
    };
    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header className='justify-content-center align-items-center border-0'>
                <Modal.Title className='sign-heading text-center pb-2'>Edit Dates</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId={`dateInput-1`} className='pb-3'>
                        <Form.Label className='pass-input'>Date of Birth</Form.Label>
                        <Form.Control
                            type="date"
                            value={DOB} // Use DOB state here
                            onChange={(e) => setDOB(e.target.value)} // Update DOB state
                        />
                    </Form.Group>
                    <Form.Group controlId={`dateInput-2`} className='pb-3'>
                        <Form.Label className='pass-input'>Date of Death</Form.Label>
                        <Form.Control
                            type="date"
                            value={DOD} // Use DOD state here
                            onChange={(e) => setDOD(e.target.value)} // Update DOD state
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
                <Button type="button" className='login-button' onClick={handleSave}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditDateModal;
