import { GetApiData } from "../../utils/http-client";

export const GetUserInfo = function (id) {
    return GetApiData(`/profile/${id}`, 'GET', null, true);
}
export const GetUserData = function () {
    return GetApiData(`/user`, 'GET', null, true);
}
export const UpdateUserInfo = function (id, data) {
    return GetApiData(`/profile/${id}`, 'PUT', data, null, true);
}
export const UpdateFlower = function (id, data) {
    return GetApiData(`/profile/flower/${id}`, 'PUT', data, null, true);
}
export const uploadUserFile = function (data) {
    return GetApiData(`/upload/multiple`, 'POST', data, null, true);
}
export const uploadTarget = function (data) {
    return GetApiData(`/upload/target`, 'POST', data, null, true);
}
export const DeleteMedia = function (id, media) {
    return GetApiData(`/media/${id}`, 'DELETE', { media }, null, true);
}
export const editCaptionAPI = function (profileId, mediaId, caption) {
    return GetApiData(`/media/${profileId}/${mediaId}`, 'PUT', { caption }, null, true);
}
export const SaveToken = function (data) {
    return GetApiData(`/user/save-token`, 'POST', data, null, true);
}