import React, { useEffect, useState, useRef } from "react";
import Artemplate1 from "./ArTemplate/Artemplate1";
import { Image, Button } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import tempfr1 from "../assests/t-1.gif";
import tempfr2 from "../assests/t-2.gif";
import tempfr3 from "../assests/t-3.gif";
import Header from "./Header";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { UpdateAR } from "../services/AR/ar.service";
import { GetUserInfo } from "../services/user/user.service";
import { compileTargetImage } from "../services/AR/Compiler";
import QRCode from "qrcode.react";
import { BsDownload } from "react-icons/bs";
import Footer from "./Footer";

const cardData = [
    { id: 1, title: "Animation 1", contentfront: "Artemplate1", imgfr: tempfr1 },
    { id: 2, title: "Animation 2", contentfront: "Artemplate2", imgfr: tempfr2 },
    { id: 3, title: "Animation 3", contentfront: "Artemplate2", imgfr: tempfr3 },
];

const Template = {
    Artemplate1: Artemplate1,
    Artemplate2: Artemplate1,
    Artemplate3: Artemplate1,
};

const ArAnimation = () => {
    const { id } = useParams();
    const [selectedCard, setSelectedCard] = useState(cardData[0]);
    const contentRefFront = useRef(null);
    const [template, setTemplate] = useState(0);
    const [isEditingPage, setIsEditingPage] = useState(false);
    const [heading, setHeading] = useState();
    const [showSavePublishButtons, setShowSavePublishButtons] = useState(true);
    const [showDownload, setShowDownload] = useState(false);
    const [loading, setLoading] = useState(true);
    const [videos, setVideos] = useState({});
    const [isUploading, setIsUploading] = useState(false);
    const baseUrl= process.env.REACT_APP_WEB_BASE_URL;


    const handleEditPage = () => {
        setIsEditingPage(!isEditingPage);
        setShowSavePublishButtons(true);
    };

    const handleUpdate = (newVideos) => {
        setVideos(newVideos);
    };

    const generateQrCodeDataUrl = () => {
        const qrCanvas = document.getElementById('qrCodeCanvas');
        return qrCanvas.toDataURL('image/png');
    };

    const fetchProfile = async () => {
        try {
            const response = await GetUserInfo(id);
            const data = response.data;
            if (data && data.data.arAnimation === null) {
                const qrCodeDataUrl = generateQrCodeDataUrl();
                const fileUrl = await compileTargetImage(qrCodeDataUrl);
                await UpdateAR(id, { target: fileUrl });
            } else {
                const media = data.data.arAnimation.media;
                console.log("this media",media);
                setVideos({
                    media1: media[0]?.url || null,
                    media2: media[1]?.url || null,
                    media3: media[2]?.url || null,
                    mediaType1: media[0]?.type || '',
                    mediaType2: media[1]?.type || '',
                    mediaType3: media[2]?.type || '',
                });
                setShowDownload(true);
            }
        } catch (error) {
            if (error.response?.data.code === 404) {
                console.error("Error:", error);
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchProfile();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSave = async () => {
        const media = [
            { url: videos.media1 || "", type: videos.mediaType1 || "" },
            { url: videos.media2 || "", type: videos.mediaType2 || "" },
            { url: videos.media3 || "", type: videos.mediaType3 || "" },
        ];
        // console.log("filtered",filteredMedia);
        console.log("media",media);
        const filteredMedia = media
            .map((media) => {
                if (media.type.startsWith("image")) {
                    return { url: media.url, type: "image" };
                } else if (media.type.startsWith("video")) {
                    return { url: media.url, type: "video" };
                } else {
                    return { url: media.url, type: "url" };
                }
            })
            .filter((media) => media.url !== "");
        const toastId = toast.loading("AR Updating", { closeButton: true });
        try {
            await UpdateAR(id, { media: filteredMedia, template });
            toast.update(toastId, {
                render: "AR updated And Activated successfully",
                type: "success",
                isLoading: false,
                autoClose: 2000,
                hideProgressBar: true,
                closeButton: false,
            });
            fetchProfile();
        } catch (error) {
            console.error("Error updating AR:", error);
            toast.update(toastId, {
                render: error?.response?.data?.error.message,
                type: "error",
                isLoading: false,
                autoClose: 2000,
                hideProgressBar: true,
                closeButton: false,
            });
            fetchProfile();
        }
    };

    const handleCardClick = (card) => {
        setSelectedCard(card);
        setTemplate(card.id);
        setHeading(card.title);
    };

    useEffect(() => {
        setSelectedCard(cardData[0]);
        setTemplate(cardData[0].id);
        setHeading(cardData[0].title);
    }, []);

    const downloadQrCode = () => {
        const qrCanvas = document.getElementById('qrCodeCanvas');
        const qrDataUrl = qrCanvas.toDataURL('image/png', 1.0); 
        const link = document.createElement('a');
        link.href = qrDataUrl;
        link.download = 'qrcode.png';
        link.click();
    };

    return (
        <>
            <Header
                onEditPage={handleEditPage}
                showButtons={showSavePublishButtons}
            />
            {loading ? (
                <div className="loader-block">
                    <div className="loader"></div>
                </div>
            ) : (
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 sidebar p-0">
                            <div className="bgColor-green text-white p-4">
                                <h2 className="sidebar-header text-center">AR Animations</h2>
                            </div>
                            <div className="p-4">
                                {cardData.map((card, index) => (
                                    <div key={card.id}>
                                        <h5 className="mt-2 text-white">
                                            {index + 1}. {card.title}
                                        </h5>
                                        <div
                                            onClick={() => handleCardClick(card)}
                                            className={`card my-3 p-3 card-container ${selectedCard.id === card.id ? "bg-card" : ""}`}
                                            style={{
                                                cursor: "pointer",
                                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                                borderRadius: "16px",
                                            }}
                                        >
                                            <div className="row">
                                                <div className="col-12">
                                                    <div
                                                        style={{
                                                            height: "250px",
                                                            borderRadius: "16px",
                                                            overflow: "hidden",
                                                        }}
                                                    >
                                                        <Image
                                                            src={card.imgfr}
                                                            alt={card.title}
                                                            style={{
                                                                width: "100%",
                                                                height: "100%",
                                                                objectFit: "contain",
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="col-md-8 p-0 d-flex flex-lg-row flex-xl-row flex-md-column flex-column">
                            <div className="p-lg-5 p-3 w-sm-100 ar-width">
                                <div className="bg-white" ref={contentRefFront}>
                                    {Template[selectedCard.contentfront] &&
                                        React.createElement(Template[selectedCard.contentfront], {
                                            isEditingPage,
                                            video1: videos.media1,
                                            video2: videos.media2,
                                            video3: videos.media3,
                                            videoType1:videos.mediaType1,
                                            videoType2:videos.mediaType2,
                                            videoType3:videos.mediaType3,
                                            heading: heading,
                                            onUpdate: handleUpdate,
                                            setIsUploading,  // Pass setIsUploading to the template
                                        })}
                                </div>
                            </div>
                            <div className="pt-lg-5 buttons-center">
                                {videos.media1 && showDownload && (
                                    <Button
                                        onClick={downloadQrCode}
                                        variant="primary"
                                        className="mt-3 edit-btn d-flex justify-content-center align-items-center"
                                    >
                                        <BsDownload className="me-2" /> Download QR Code
                                    </Button>
                                )}
                                <Button
                                    className="mt-3 edit-btn d-flex justify-content-center align-items-center"
                                    onClick={handleSave}
                                    disabled={isUploading}  
                                >
                                    <FaCheck className="me-2" color="white" />
                                    Save AR
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <QRCode
                id="qrCodeCanvas"
                value={`${baseUrl}/ARView/${id}`}
                size={512}
                level={"H"}
                includeMargin={true}
                style={{ display: "none" }}
            />
            {
                !loading && (
                    <Footer />
                )
            }
        </>
    );
};

export default ArAnimation;
