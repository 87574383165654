import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import QRCode from 'qrcode.react';
import profile from "../../assests/smallupload.png";
import { Image } from 'react-bootstrap';
import { FaPencilAlt } from 'react-icons/fa';
import EditInfoModal from './EditInfoModal';
const TemplateBack3 = ({ isEditingPage, templateHead, sign, number, company, profilename, dob, deceasedDate, info, prayerLocation, prayerDate, intermentLocation, profileImage, arlink, onUpdate }) => {
    const { id } = useParams();
    const baseUrl = process.env.REACT_APP_WEB_BASE_URL;
    const [showModal, setShowModal] = useState(false);
    const [localProfileImage, setLocalProfileImage] = useState(profileImage);
    const [localInfo, setLocalInfo] = useState(info);
    const qrLink = `${baseUrl}/${id}`;
    useEffect(() => {
        console.log(setLocalInfo)
        setLocalProfileImage(profileImage);
        setLocalInfo(info)
    }, [profilename, dob, deceasedDate, info, profileImage]);
    const handleNameSave = (newInfo) => {
        setLocalInfo(newInfo);
        onUpdate(templateHead, sign, number, company, profilename, dob, deceasedDate, newInfo, prayerLocation, prayerDate, intermentLocation);
    };

    return (
        <>
            <div className="main-background-image bg-card-temp " style={{ width: 'fit-content' }}>
                <div className="d-flex  justify-content-start flex-column pt-3 card-width temp-font position-relative " style={{ height: '775px', width: "486px" }}>
                    <div className="d-flex align-items-center justify-content-center pt-2" style={{ border: 'unset', width: "unset" }}>
                        <div className=''>
                            <Image
                                src={localProfileImage || profile}
                                alt="user"
                                style={{ width: "154px" }}
                                className="rounded-circle"
                            />
                        </div>
                    </div>
                    {/* <div className="text-center pt-2">
                        <p className="fw-Bolder mb-0 lh-1 fs-3">Poem</p>
                    </div> */}
                    <div className="text-center d-flex justify-content-center pt-5 gap-2">
                        <p className="memory-width lh-md mb-0" style={{ width: "16rem", fontSize: "0.8rem", wordWrap: "break-word" }}>
                            {localInfo}
                        </p>
                        {isEditingPage && (
                            <FaPencilAlt
                                style={{ cursor: "pointer", marginTop: "-10px" }}
                                onClick={() => setShowModal(true)}
                            />
                        )}
                    </div>
                    <div className="d-flex justify-content-center my-2 mx-2 pt-2">
                        {/* {arlink &&
                            <div className='d-flex flex-column align-items-center justify-content-center'>
                                <QRCode value={arlink} size={110} level={"H"} includeMargin={true} className="qr-code-left" />
                                <p className='m-0 text-primary-emphasis lh-sm' style={{ fontSize: "0.7rem" }}>Experience AR</p>
                            </div>
                        } */}
                        <div className='d-flex flex-column align-items-center justify-content-center' style={{ position: "absolute", bottom: "15px" }}>
                            <QRCode value={qrLink} size={110} level={"H"} includeMargin={true} className="qr-code-left" />
                            <p className='m-0 text-primary-emphasis lh-sm' style={{ fontSize: "0.7rem" }}>Visit Profile</p>
                        </div>
                    </div>
                </div>
            </div>
            <EditInfoModal
                initialValue={info}
                onSave={handleNameSave}
                limit={660}
                onHide={() => setShowModal(false)} // Close modal on hide
                show={showModal} // Pass showModal state to control modal visibility
            />
        </>
    )
}

export default TemplateBack3

