import React, { useEffect, useState } from 'react';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { Modal, Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import moment from 'moment';

const formatDisplayDate = (isoString) => {
    if (!isoString) return '';
    return moment(isoString).format('MM/DD/YYYY');
};

const extractISODate = (isoString) => {
    return isoString ? isoString.split('T')[0] : '';
};

const Dateedit = ({ initialValue, onSave, isEditingPage }) => {
    const [dates, setDates] = useState(initialValue.map(extractISODate));
    const [editedDates, setEditedDates] = useState(initialValue.map(extractISODate));
    const [isEditing, setIsEditing] = useState(false);

    const handleEdit = () => {
        setEditedDates(dates);
        setIsEditing(true);
    };

    const handleChange = (index, value) => {
        const newDates = [...editedDates];
        newDates[index] = value;
        setEditedDates(newDates);
    };

    const handleSave = () => {
        const toastId = toast.loading("Updating", { closeButton: true });
        toast.update(toastId, {
            render: "Date is Successfully Updated",
            type: "success",
            isLoading: false,
            autoClose: 2000,
            hideProgressBar: true,
            closeButton: false,
        });
        onSave(editedDates);
        setIsEditing(false);
        setDates(editedDates);
    };

    const handleClose = () => {
        setIsEditing(false);
    };

    const handleDateInputChange = (index, event) => {
        handleChange(index, event.target.value);
    };

    useEffect(() => {
        setDates(initialValue.map(extractISODate));
        setEditedDates(initialValue.map(extractISODate));
    }, [initialValue]);

    return (
        <div>
            <div className='d-flex gap-2 align-items-center date-div'>
                <p className='name-para ps-1 mb-0'>
                    {dates?.map((date, index) => (
                        date ? formatDisplayDate(date) : (index === 0 ? 'Enter Birth Date' : 'Enter Passing Date')
                    )).join(' - ')}
                </p>
                {isEditingPage && (
                    <FaRegCalendarAlt onClick={handleEdit} style={{ cursor: "pointer", zIndex: "10" }} />
                )}
            </div>
            <Modal show={isEditing} onHide={handleClose} centered>
                <Modal.Header className='justify-content-center align-items-center border-0'>
                    <Modal.Title className='sign-heading text-center pb-2'>Edit Dates</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        {dates.map((date, index) => (
                            <Form.Group controlId={`dateInput-${index}`} className='pb-3' key={index}>
                                <Form.Label className='pass-input'>
                                    {index === 0 ? 'Date of Birth' : 'Date of Death'}
                                </Form.Label>
                                <Form.Control
                                    type="date"
                                    value={editedDates[index] || ''}
                                    onChange={(event) => handleDateInputChange(index, event)}
                                    placeholder={index === 0 ? 'Enter Birth Date' : 'Enter Passing Date'}
                                />
                            </Form.Group>
                        ))}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button type="button" className='login-button' onClick={handleSave}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Dateedit;
