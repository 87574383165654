import { GetApiData } from "../../utils/http-client";

export const GetComments = function (id) {
    return GetApiData(`/comment/${id}`, 'GET', null, true);
}
export const UpdateComments = function (id, data) {
    return GetApiData(`/comment/${id}`, 'POST', data, null, true);
}
export const DeleteComments = function (id) {
    return GetApiData(`/comment/${id}`, 'DELETE', null, true);
}