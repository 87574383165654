import React from 'react'
import { Container, Row, Col, Image, Button } from 'react-bootstrap'
import about from '../assests/about1.svg'
const About = () => {
    return (
        <>
             <section id="about" style={{backgroundColor:'#e9e7e9'}}  >
            <Container className='py-5'>
                <Row>
                    <Col lg={6} className="order-lg-1 order-2 d-flex justify-content-center align-items-center">
                     
                      <Image src={about} width="90%"  alt="About Image" className="rounded" />

                    
                    </Col>
                    <Col lg={6} className="order-lg-2 order-1 d-flex flex-column justify-content-center align-items-center align-items-lg-start">
                        <h2 className='mb-4' style={{ fontWeight: 'bold' }}>About Us</h2>
                        <p className='text-center text-lg-start' style={{ fontSize: '1.1rem', lineHeight: '1.5' }}>
                            Capsule of Memories is at the forefront of innovative technology, offering a groundbreaking approach to preserving and cherishing personal memories. Our services ensure that the precious moments of your life remain vivid and accessible forever. Capsule of Memories leverages cutting-edge technology to create digital time capsules, where users can store photos, videos, messages, and other significant memorabilia.
                        </p>
                    </Col>
                    
                </Row>
            </Container>
        </section>
        </>
    )
}

export default About
