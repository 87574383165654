import React, { useEffect, useState } from "react";
import { Image, Button, Modal, Row, Col } from "react-bootstrap";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import defaultUploader from "../assests/uploader.png";
import Trash from "../assests/trash.svg";
import Share from "../assests/share.svg";
import { useParams } from 'react-router-dom';
import { AiOutlineCheckCircle } from 'react-icons/ai'; // Import the tick icon
import SlideModal from "./CommentModal/SlideModal";
import { toast } from "react-toastify";
import { downloadVideo } from "../services/videoDownload/download";

const Slideshare = ({ mediaId, isEditingPage, images, slideshare, updateSlideShare, deleteArray = [], updateDeleteArray }) => {
  const [visibleImages, setVisibleImages] = useState(5);
  const { id } = useParams();
  const [imageSources, setImageSources] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [slideshows, setSlideshows] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [showSlideModal, setShowSlideModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [commentsID, setCommentsID] = useState(null);
  const [shareLoading, setShareLoading] = useState(false);
  // New states for video and modal
  const [videoFile, setVideoFile] = useState(null);
  const [showShareModal, setShowShareModal] = useState(false); // Modal to show after video download

  const [opened, setOpened] = useState(false);

  useEffect(() => {
    if (mediaId && !opened) {
      const matchingPhoto = slideshows.find(slideshow => slideshow._id === mediaId);
      if (matchingPhoto) {
        openSlideModal(matchingPhoto);
        setOpened(true);
      }
    }
  }, [mediaId, slideshare]);

  useEffect(() => {
    setSlideshows(slideshare);
  }, [slideshare]);

  const handleLoadMore = () => {
    setVisibleImages(prevVisibleImages => prevVisibleImages + 12);
  };

  const handleAddImages = () => {
    if (selectedImages.length === 0) {
      return;
    }
    setShowModal(false);
    const newSlideshare = {
      data: {
        urls: selectedImages,
      },
      type: 'slideshare',
    };
    const updatedSlideshows = [...slideshows, newSlideshare];
    setSlideshows(updatedSlideshows);
    updateSlideShare(updatedSlideshows);
    setSelectedImages([]);
  };

  const openModal = () => {
    setShowModal(true);
    setImageSources(images);
  };

  const closeModal = () => {
    setImageSources([]);
    setShowModal(false);
  };

  const openSlideModal = (src) => {
    if (src && src.data && src.data.urls && src.data.urls[0]) {
      setSelectedImage(src.data.urls);
      setCommentsID(src._id);
      setShowSlideModal(true);
    }
  };

  const closeSlideModal = () => {
    setSelectedImage(null);
    setShowSlideModal(false);
  };

  const toggleImageSelection = (src) => {
    if (selectedImages.includes(src)) {
      setSelectedImages(selectedImages.filter((img) => img !== src));
    } else {
      setSelectedImages([...selectedImages, src]);
    }
  };

  const handleRemoveSlideshow = (index, slideshow) => {
    const updatedDeleteArray = [...deleteArray, slideshow._id];
    updateDeleteArray(updatedDeleteArray);
    const updatedSlideshows = [...slideshows];
    updatedSlideshows.splice(index, 1);
    setSlideshows(updatedSlideshows);
    updateSlideShare(updatedSlideshows);
  };

  // Modified handleShareLink
  const handleShareLink = async (src) => {
    const toastId = toast.loading("Preparing video for sharing", { closeButton: true });

    try {
      setShareLoading(true);
      const response = await downloadVideo({ images: src?.data?.urls });
      const url = response?.data?.videoUrl;

      const fetchResponse = await fetch(url);
      const blob = await fetchResponse.blob();

      const videoFile = new File([blob], "video.mp4", { type: "video/mp4" });

      // Store video in state and open the share modal
      setVideoFile(videoFile);
      setShowShareModal(true);
      setShareLoading(false);
      toast.update(toastId, {
        render: "Video is ready!",
        type: "success",
        isLoading: false,
        autoClose: 2000,
        hideProgressBar: true,
        closeButton: false,
      });
    } catch (error) {
      toast.update(toastId, {
        render: error.message || "Failed to prepare the video",
        type: "error",
        isLoading: false,
        autoClose: 2000,
        hideProgressBar: true,
        closeButton: false,
      });
      setShareLoading(false);
      console.error('Error downloading or sharing video:', error);
    }
  };

  // Function to share the video
  const shareVideo = async () => {
    if (navigator.canShare && navigator.canShare({ files: [videoFile] })) {
      try {
        await navigator.share({
          files: [videoFile],
          title: 'Check out this video!',
          text: 'Here’s a video I wanted to share with you.',
        });
        toast.success("Video shared successfully!", { autoClose: 2000 });
        setShowShareModal(false);
      } catch (error) {
        toast.error("Failed to share the video", { autoClose: 2000 });
        console.error('Error sharing video:', error);
      }
    } else {
      toast.error("Sharing is not supported on this device.", { autoClose: 2000 });
    }
  };

  // Function to save the video locally
  const saveVideo = () => {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(videoFile);
    link.download = "video.mp4";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    toast.success("Video saved successfully!", { autoClose: 2000 });
    setShowShareModal(false);
  };

  return (
    <div className="">
      <Row className='mx-3 pb-2 mt-4'>
        {slideshows?.length === 0 ? (
          <div className="text-center w-100">
            <p className='about-section'>No Slide Shares</p>
          </div>
        ) : (
          slideshows.slice(0, visibleImages).map((slideshow, index) => (
            <Col key={index} xs={6} md={4} className='pb-4'>
              <Fade autoplay={true} arrows={false} duration={3000} pauseOnHover={false} key={index}>
                {slideshow.data.urls.map((slideImage, i) => (
                  <div className="min-img-size position-relative card-item" key={i}>
                    {isEditingPage ? (
                      <Button className="remove-btn" onClick={() => handleRemoveSlideshow(index, slideshow)}>
                        <Image src={Trash} />
                      </Button>
                    ) : (
                      <div>
                        <Button className="share-btn" onClick={() => handleShareLink(slideshow)} disabled={shareLoading}>
                          <Image src={Share} />
                        </Button>
                      </div>
                    )}
                    <Image src={slideImage} className="gallery-img" onClick={() => openSlideModal(slideshow)} />
                  </div>
                ))}
              </Fade>
            </Col>
          ))
        )}
        {isEditingPage && (
          <Col xs={6} md={4} className='pb-4 d-flex'>
            <div className='min-img-size'>
              <Image src={defaultUploader} className='gallery-img' alt='uploader' style={{ cursor: "pointer" }} onClick={openModal} />
            </div>
          </Col>
        )}
      </Row>
      {visibleImages < slideshare.length && (
        <div className="text-center">
          <Button className='load-btn' onClick={handleLoadMore}>Load More...</Button>
        </div>
      )}

      {/* Share Modal */}
      <Modal centered show={showShareModal} onHide={() => setShowShareModal(false)}>
        <Modal.Header className='justify-content-center align-items-center' closeButton style={{ background: 'rgba(43, 148, 151, 0.1)' }}>
          <Modal.Title className='sign-heading text-center pb-2'>Video Ready to Share or Save</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-1 about-section">Your video is ready. Would you like to share it or save it to your device?</p>
        </Modal.Body>
        <Modal.Footer className="border-0 d-flex justify-content-between">
          <Button className="share-btn px-2" style={{ position: 'static' }} onClick={shareVideo} >
            <Image src={Share} />
          </Button>
          <Button className="share-btn px-3 py-2" style={{ position: 'static' }} variant="secondary" onClick={saveVideo}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size="lg" show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Slideshow Content</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mx-3 pb-2 mt-4">
            {imageSources.map((photo, index) => (
              <Col key={index} xs={6} md={4} lg={3} className="pb-4">
                <div className="modal-img">
                  <Image
                    src={photo.data.urls[0]}
                    style={{
                      objectFit: "cover",
                      objectPosition: "center",
                      width: "100%",
                      height: "200px",

                    }}
                    className="modal-image"
                    alt="gallery"
                    onClick={() => toggleImageSelection(photo.data.urls[0])}
                  />
                  {selectedImages.includes(photo.data.urls[0]) && (
                    <div className="tick-icon-container ps-2">
                      <AiOutlineCheckCircle size={25} color="green" className="tick-icon" />
                    </div>
                  )}
                </div>
              </Col>
            ))}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddImages}>
            Create Slide Show
          </Button>
        </Modal.Footer>
      </Modal>

      <SlideModal
        showSlideModal={showSlideModal}
        isEditingPage={isEditingPage}
        closeSlideModal={closeSlideModal}
        slideShare={selectedImage}
        commentId={commentsID}
      />
    </div>
  );
};

export default Slideshare;
