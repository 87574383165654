import React, { useEffect, useState, useRef } from "react";
import Template1 from "./Template/TemplateFront1";
import { Image, Button, Offcanvas, Modal, Spinner } from "react-bootstrap";
import tempfr1 from "../assests/front1.png";
import tempfr2 from "../assests/front2.png";
import tempback1 from "../assests/back1.png";
import tempback2 from "../assests/back2.png";
import tempfr3 from "../assests/front3.png";
import tempback3 from "../assests/back3.png";
import html2canvas from "html2canvas";
import Share from "../assests/share.svg";
import jsPDF from "jspdf";
import TemplateBack1 from "./Template/TempalteBack1";
import TemplateFront2 from "./Template/TemplateFront2";
import { BsDownload, BsFillPencilFill } from "react-icons/bs";
import Header from "./Header";
import TemplateBack2 from "./Template/TemplateBack2";
import TemplateFront3 from "./Template/TemplateFront3";
import TemplateBack3 from "./Template/TemplateBack3";
import {
  GetPrayerCard,
  UpdatePrayerCard,
} from "../services/prayerCard/prayer.service";
import { UpdateAR } from "../services/AR/ar.service";
import { useParams, useLocation } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import { toast } from "react-toastify";
import { AuthVerify } from "../utils/auth.utils";
import { GetUserInfo } from "../services/user/user.service";
import Footer from "./Footer";
import { compileTargetImage } from "../services/AR/Compiler";
import ShareComponent from "./ShareComponent";

const cardData = [
  {
    id: 1,
    title: "Prayer Card 1",
    contentfront: "Template1",
    contentback: "TemplateBack1",
    imgfr: tempfr1,
    imgback: tempback1,
  },
  {
    id: 2,
    title: "Prayer Card 2",
    contentfront: "TemplateFront2",
    contentback: "TemplateBack2",
    imgfr: tempfr2,
    imgback: tempback2,
  },
  {
    id: 3,
    title: "Prayer Card 3",
    contentfront: "TemplateFront3",
    contentback: "TemplateBack3",
    imgfr: tempfr3,
    imgback: tempback3,
  },
];

const Template = {
  Template1: Template1,
  TemplateBack1: TemplateBack1,
  TemplateFront2: TemplateFront2,
  TemplateBack2: TemplateBack2,
  TemplateFront3: TemplateFront3,
  TemplateBack3: TemplateBack3,
};

const Prayercard = () => {
  const { id } = useParams();
  const verify = AuthVerify();
  const baseUrl = process.env.REACT_APP_WEB_BASE_URL;
  const [loading, setLoading] = useState(true);
  const [selectedCard, setSelectedCard] = useState(cardData[0]);
  const contentRefFront = useRef(null);
  const contentRefBack = useRef(null);
  const [isEditingPage, setIsEditingPage] = useState(false);
  const [showSavePublishButtons, setShowSavePublishButtons] = useState(false);
  const [profilename, setProfilename] = useState();
  const [intermentLocation, setIntermentLocation] = useState();
  const [prayerDate, setPrayerDate] = useState();
  const [prayerLocation, setPrayerLocation] = useState();
  const [memorialLocation, setmemorialLocation] = useState();
  const [arlink, setArlink] = useState();
  const [template, setTemplate] = useState(cardData[0].id);
  const [dob, setDob] = useState();
  const [prayer, setPrayer] = useState(
    "Until we meet again, may god hold you in the palm of his hands."
  );
  const [grace, setGrace] = useState(
    "Hope Of Grace Chapel"
  );
  const [templateHead, setTemplateHead] = useState("In Loving Memory Of");
  const [prayerHead, setPrayerHead] = useState("Forever in Our Hearts");
  const [sign, setsign] = useState("Seiaraffa");
  const [number, setNumber] = useState("(773) 619-7725");
  const [company, setCompany] = useState("Funeral Directors, P.C.");
  const [deceasedDate, setDeceasedDate] = useState("Enter Date Of Death");
  const [info, setInfo] = useState(
    "The Lord is my shepherd; I shall not want. He makes me lie down in green pastures. He leads me beside still waters. He restores my soul. Forever in Our Hearts + Until we meet again, may God hold you in the palm of his hands. He leads me in paths of righteousness for his name's sake. Even though I walk through the valley of the shadow of death, I will fear no evil, For you are with me; Your rod and your staff, they comfort me. You prepare a table before me in the presence of my enemies; You anoint my head with oil; My cup overflows. Surely goodness and mercy shall follow me all the days."
  );
  const [profileImage, setProfileImage] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [showGeneratingPDF, setShowGeneratingPDF] = useState(false);
  const [messageShown, setMessageShown] = useState(false);
  const location = useLocation();

  const handleCloseOffcanvas = () => setShowOffcanvas(false);
  const handleShowOffcanvas = () => setShowOffcanvas(true);

  const handleEditPage = () => {
    setIsEditingPage(!isEditingPage);
    setShowSavePublishButtons(true);
  };

  const handleCardClick = (card) => {
    if (isEditingPage) {
      setSelectedCard(card);
      setTemplate(card.id);
      setShowOffcanvas(false);
    }
  };

  const fetchCards = async () => {
    setLoading(true);
    try {
      const response = await GetPrayerCard(id);
      const card = response?.data?.data;
      setProfilename(card.profilename || "Enter Profile Name");
      setProfileImage(card.profileImage);
      setDob(card.dob || "Enter Date of Birth");
      setDeceasedDate(card.deceasedDate || "Enter Date of Death");
      setIntermentLocation(
        card.intermentLocation || "Enter Interment Location"
      );
      setPrayerDate(card.prayerDate || "Enter Prayer Date And Time");
      setPrayerLocation(card.prayerLocation || "Enter Prayer Location");
      setmemorialLocation(
        card.memorialLocation || "Enter Memorial Day Location"
      );
      setTemplate(card.template || 0);
      setSelectedCard(cardData[card?.template - 1] || cardData[0]);
      setCompany(card.company || "Funeral Directors, P.C.");
      setNumber(card.number || "(773) 619-7725");
      setsign(card.sign || "Seiaraffa");
      setPrayerHead(card.prayerHead || "Forever in Our Hearts");
      setTemplateHead(card.templateHead || "In Loving Memory Of");
      setPrayer(
        card.prayer ||
        "Until we meet again, may god hold you in the palm of his hands."
      );
      setProfileImage(card.profileImage);
      setInfo(
        card.info ||
        "The Lord is my shepherd; I shall not want. He makes me lie down in green pastures. He leads me beside still waters. He restores my soul. Forever in Our Hearts + Until we meet again, may God hold you in the palm of his hands. He leads me in paths of righteousness for his name's sake. Even though I walk through the valley of the shadow of death, I will fear no evil, For you are with me; Your rod and your staff, they comfort me. You prepare a table before me in the presence of my enemies; You anoint my head with oil; My cup overflows. Surely goodness and mercy shall follow me all the days of my life, And I shall dwell in the house of the Lord forever."
      );
      if (!verify) {
        setSelectedCard(cardData[card.template - 1] || cardData[0]);
      }
    } catch (error) {
      toast.error(error, {
        isLoading: false,
        autoClose: 2000,
        hideProgressBar: true,
        closeButton: false,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCards();
    const queryParams = new URLSearchParams(location.search);
    const armessage = queryParams.get("armessage");
    if (armessage === "true" && !messageShown) {
      toast.warning("Kindly Select a prayer card first to proceed", {
        autoClose: 3000,
        hideProgressBar: true,
        closeButton: true,
      });
      setMessageShown(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pixelToInch = (pixels) => pixels / 96;
  let widthInInches, heightInInches;
  if (
    selectedCard.contentfront === "Template1" ||
    selectedCard.contentback === "TemplateBack1"
  ) {
    widthInInches = pixelToInch(570);
    heightInInches = pixelToInch(940);
  } else if (
    selectedCard.contentfront === "TemplateFront2" ||
    selectedCard.contentback === "TemplateBack2"
  ) {
    widthInInches = pixelToInch(486);
    heightInInches = pixelToInch(630);
  } else if (
    selectedCard.contentfront === "TemplateFront3" ||
    selectedCard.contentback === "TemplateBack3"
  ) {
    widthInInches = pixelToInch(486);
    heightInInches = pixelToInch(775);
  }

  const handleUpdate = (
    head,
    prayerHead,
    prayer,
    name,
    birthDate,
    deathDate,
    image,
    memorial,
    grace
  ) => {
    console.log("Memorial", memorial)
    setTemplateHead(head);
    setPrayerHead(prayerHead);
    setPrayer(prayer);
    setProfilename(name);
    setDob(birthDate);
    setDeceasedDate(deathDate);
    setProfileImage(image);
    setmemorialLocation(memorial);
    setGrace(grace)
  };

  const handleInfoUpdate = (
    head,
    sign,
    number,
    company,
    name,
    birthDate,
    deathDate,
    info,
    prayerLocation,
    prayerDate,
    intermentLocation
  ) => {
    setProfilename(name);
    setTemplateHead(head);
    setsign(sign);
    setNumber(number);
    setCompany(company);
    setDob(birthDate);
    setDeceasedDate(deathDate);
    setInfo(info);
    setPrayerLocation(prayerLocation);
    setPrayerDate(prayerDate);
    setIntermentLocation(intermentLocation);
  };

  const downloadPDF = () => {
    setShowGeneratingPDF(true); // Show the modal when PDF generation starts

    const cardContainer = document.querySelector(".card-cover");
    const originalStyles = cardContainer?.getAttribute("style");
    cardContainer.classList.remove("card-cover");

    cardContainer.style.display = "flex";
    cardContainer.style.justifyContent = "center";
    cardContainer.style.alignItems = "center";
    cardContainer.style.padding = "0"; // Optional: Remove padding if it's affecting centering

    setTimeout(() => {
      const inputFront = contentRefFront.current;
      const inputBack = contentRefBack.current;

      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "in",
        format: [widthInInches, heightInInches],
      });

      const canvasOptions = {
        useCORS: true,
        width: widthInInches * 96,
        height: heightInInches * 96,
        scale: 1,
      };

      html2canvas(inputFront, canvasOptions).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        pdf.addImage(imgData, "PNG", 0, 0, widthInInches, heightInInches);
        pdf.addPage();

        html2canvas(inputBack, canvasOptions).then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          pdf.addImage(imgData, "PNG", 0, 0, widthInInches, heightInInches);

          // Create a blob from the generated PDF
          const pdfBlob = pdf.output("blob");
          const file = new File([pdfBlob], "PrayerCard.pdf", { type: "application/pdf" });

          // Trigger the Web Share API for sharing the file directly
          if (navigator.share && navigator.canShare({ files: [file] })) {
            navigator.share({
              title: 'Have a look at the Prayer Card',
              files: [file],
            })
              .then(() => {
                console.log('Successfully shared');
              })
              .catch((error) => {
                console.error('Error sharing:', error);
              });
          } else {
            // Fallback if the Web Share API is not supported
            alert("Sharing not supported on this device.");
          }

          // Restore original styles
          cardContainer.setAttribute("style", originalStyles);
          cardContainer.classList.add("card-cover");
          setShowGeneratingPDF(false); // Hide the modal when PDF generation is complete
        });
      });
    }, 0);
  };



  const handleSave = async () => {
    if (prayerDate === "Enter Prayer Date And Time") {
      setPrayerDate(null);
    }
    const toastId = toast.loading("Updating", { closeButton: true });

    // Hide the save and publish buttons
    setShowSavePublishButtons(false);
    setIsEditingPage(false);
    setIsSaving(true);

    // Wait for the state to update
    setTimeout(async () => {
      try {
        // Capture front page as PNG image
        const canvas = await html2canvas(contentRefFront.current, {
          useCORS: true,
          width: widthInInches * 96,
          height: heightInInches * 96,
          scale: 1,
        });
        const imageDataUrl = canvas.toDataURL("image/png");

        // // Function to download the generated image
        // const downloadImage = (dataUrl, filename) => {
        //     const a = document.createElement('a');
        //     a.href = dataUrl;
        //     a.download = filename;
        //     document.body.appendChild(a);
        //     a.click();
        //     document.body.removeChild(a);
        // };

        // // Download the generated image
        // downloadImage(imageDataUrl, 'prayer_card_front.png');

        const response = await GetUserInfo(id);
        const data = response?.data;
        const fileUrl = await compileTargetImage(imageDataUrl);
        await UpdateAR(id, { target: fileUrl, template: selectedCard.id || 1 });

        await UpdatePrayerCard(id, {
          profilename,
          profileImage,
          dob: dob === "Enter Date of Birth" ? null : dob,
          deceasedDate:
            deceasedDate === "Enter Date of Death" ? null : deceasedDate,
          template,
          memorialLocation,
          intermentLocation,
          prayerDate:
            prayerDate === "Enter Prayer Date And Time" ? null : prayerDate,
          prayerLocation,
          info,
          templateHead,
          prayerHead,
          prayer,
          sign,
          number,
          company,
        });
        toast.update(toastId, {
          render: "Profile updated and activated successfully",
          type: "success",
          isLoading: false,
          autoClose: 2000,
          hideProgressBar: true,
          closeButton: false,
        });
        fetchCards();
      } catch (error) {
        // Handle errors
        toast.update(toastId, {
          render: error.response?.data?.error?.message || error.message,
          type: "error",
          isLoading: false,
          autoClose: 2000,
          hideProgressBar: true,
          closeButton: false,
        });
      } finally {
        setShowSavePublishButtons(false);
        setIsSaving(false);
      }
    }, 100); // Adjust the timeout duration if needed
  };

  const fetchProfile = async () => {
    try {
      const response = await GetUserInfo(id);
      const data = response?.data;
      if (
        data?.data?.arAnimation === null ||
        data?.data?.arAnimation?.media?.length === 0
      ) {
        setArlink(null);
      } else {
        setArlink(`${baseUrl}/ARView/${id}`);
      }
    } catch (error) {
      if (error.response?.data?.code === 404) {
        console.log("error");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProfile();
    // console.log("arlink", arlink)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header
        onEditPage={handleEditPage}
        showButtons={showSavePublishButtons}
      />
      {loading ? (
        <>
          <div className="loader-block">
            <div className="loader"></div>
          </div>
        </>
      ) : verify ? (
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-4 d-none d-lg-block sidebar p-0">
              <div className="bgColor-green text-white p-4">
                <h2 className="sidebar-header text-center">Prayer Card</h2>
              </div>
              <div className="p-4">
                {cardData.map((card, index) => {
                  return (
                    <div key={card.id}>
                      <h5 className="mt-2 text-white">
                        {index + 1}. {card.title}
                      </h5>
                      <div
                        onClick={() => handleCardClick(card)}
                        className={`card my-3 p-3 card-container ${selectedCard.id === card.id ? "bg-card" : ""
                          }`}
                        style={{
                          cursor: isEditingPage ? "pointer" : "not-allowed",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                          borderRadius: "16px",
                        }}
                      >
                        <div className="row">
                          <div className="col-6">
                            <div
                              style={{
                                height: "250px",
                                borderRadius: "30px",
                                overflow: "hidden",
                              }}
                            >
                              <Image
                                src={card.imgfr}
                                alt={card.title}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "contain",
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-6">
                            <div
                              style={{
                                height: "250px",
                                borderRadius: "30px",
                                overflow: "hidden",
                              }}
                            >
                              <Image
                                src={card.imgback}
                                alt={card.title}
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "contain",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-md-12 col-lg-8 col-xl-7 p-0 main-content d-flex  flex-md-column flex-column">
              <div className="pt-lg-5 px-2 d-flex justify-content-between ">
                {!showSavePublishButtons && !isSaving && (
                  // <Button
                  //   onClick={downloadPDF}
                  //   variant="primary"
                  //   className="mt-3 edit-btn d-flex justify-content-center align-items-center"
                  // >
                  //   {/* <BsDownload className="me-2" /> Download PDF */}
                  //   <ShareComponent
                  //     profileImage={profileImage}
                  //     fullname={profilename}
                  //   />
                  // </Button>
                  <Button className="mt-3 edit-btn d-flex justify-content-center align-items-center" onClick={downloadPDF}>
                    <Image src={Share} />
                  </Button>
                )}
                {showSavePublishButtons && (
                  <>
                    <Button
                      className="d-lg-none edit-btn mt-3"
                      onClick={handleShowOffcanvas}
                    >
                      Choose Template
                    </Button>
                    <Button
                      className="mt-3 edit-btn d-flex justify-content-center align-items-center"
                      onClick={handleSave}
                    >
                      <FaCheck className="me-2" color="white" />
                      Save Card
                    </Button>
                  </>
                )}
                {!isEditingPage && !isSaving && (
                  <Button
                    onClick={handleEditPage}
                    variant="primary"
                    className="mt-3 edit-btn d-flex justify-content-center align-items-center"
                  >
                    <BsFillPencilFill className="me-2" /> Edit Card
                  </Button>
                )}
              </div>
              <div className="d-flex flex-column justify-content-center mx-lg-0 mx-3 card-cover">
                <div
                  className="d-flex justify-content-center "
                  ref={contentRefFront}
                >
                  {Template[selectedCard.contentfront] &&
                    React.createElement(Template[selectedCard.contentfront], {
                      isEditingPage,
                      templateHead,
                      prayerHead,
                      prayer,
                      profilename,
                      dob,
                      deceasedDate,
                      profileImage,
                      memorialLocation,
                      grace,
                      onUpdate: handleUpdate,
                    })}
                </div>
                <div
                  className="d-flex justify-content-center "
                  ref={contentRefBack}
                  style={{ marginTop: "20px" }}
                >
                  {Template[selectedCard.contentback] &&
                    React.createElement(Template[selectedCard.contentback], {
                      isEditingPage,
                      templateHead,
                      sign,
                      number,
                      company,
                      profilename,
                      dob,
                      deceasedDate,
                      info,
                      prayerLocation,
                      prayerDate,
                      intermentLocation,
                      profileImage,
                      arlink,
                      onUpdate: handleInfoUpdate,
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="container ">
            <div className="row justify-content-center">

              <div className="col-md-8 p-0 main-content d-flex  flex-column align-items-center">
                {!showSavePublishButtons && (
                  <div className="text-end">
                    {/* <Button
                      onClick={downloadPDF}
                      variant="primary"
                      className="mt-3 edit-btn d-flex justify-content-center align-items-center"
                    >
                      <BsDownload className="me-2" /> Download PDF
                    </Button> */}
                    <Button className="mt-3 edit-btn d-flex justify-content-center align-items-center" onClick={downloadPDF}>
                      <Image src={Share} />
                    </Button>
                  </div>
                )}
                <div className="d-flex flex-column justify-content-center mx-lg-0 mx-3 card-cover">
                  <div
                    className="d-flex justify-content-center"
                    ref={contentRefFront}
                  >
                    {Template[selectedCard.contentfront] &&
                      React.createElement(Template[selectedCard.contentfront], {
                        isEditingPage,
                        templateHead,
                        prayerHead,
                        prayer,
                        profilename,
                        dob,
                        deceasedDate,
                        profileImage,
                        memorialLocation,
                        grace,
                        onUpdate: handleUpdate,
                      })}
                  </div>
                  <div
                    className="d-flex justify-content-center"
                    ref={contentRefBack}
                    style={{ marginTop: "20px" }}
                  >
                    {Template[selectedCard.contentback] &&
                      React.createElement(Template[selectedCard.contentback], {
                        isEditingPage,
                        templateHead,
                        sign,
                        number,
                        company,
                        profilename,
                        dob,
                        deceasedDate,
                        info,
                        prayerLocation,
                        prayerDate,
                        intermentLocation,
                        profileImage,
                        arlink,
                        onUpdate: handleInfoUpdate,
                      })}
                  </div>
                </div>
                <div className="pt-lg-5 buttons-center"></div>
              </div>
            </div>
          </div>
        </>
      )}
      {!loading && <Footer />}
      <Offcanvas
        show={showOffcanvas}
        onHide={handleCloseOffcanvas}
        responsive="lg"
        className="d-block d-lg-none "
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Prayer Card</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="h-100">
          <div className="primary-bg text-white p-4">
            <h2 className="sidebar-header text-white text-center">
              Choose Template
            </h2>
          </div>
          <div className="p-4 mb-4">
            {cardData.map((card, index) => {
              return (
                <div key={card.id}>
                  <h5 className="mt-2 text-dark">
                    {index + 1}. {card.title}
                  </h5>
                  <div
                    onClick={() => handleCardClick(card)}
                    className={`card my-3 p-3 card-container ${selectedCard.id === card.id ? "bg-card" : ""
                      }`}
                    style={{
                      cursor: isEditingPage ? "pointer" : "not-allowed",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      borderRadius: "16px",
                    }}
                  >
                    <div className="row">
                      <div className="col-6">
                        <div
                          style={{
                            height: "250px",
                            borderRadius: "30px",
                            overflow: "hidden",
                          }}
                        >
                          <Image
                            src={card.imgfr}
                            alt={card.title}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div
                          style={{
                            height: "250px",
                            borderRadius: "30px",
                            overflow: "hidden",
                          }}
                        >
                          <Image
                            src={card.imgback}
                            alt={card.title}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <div
              id="pdf-clone-container"
              style={{ position: "absolute", top: "-9999px", left: "-9999px" }}
            />
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <Modal
        show={showGeneratingPDF}
        style={{ position: "absolute" }}
        centered
        backdrop="static"
        backdropClassName="gen-backdrop"
        keyboard={false}
      >
        <Modal.Body className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Generating PDF...</span>
          </Spinner>
          <p className="mt-3">Generating your PDF, please wait...</p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Prayercard;
