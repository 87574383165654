import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Button,
  Image,
  Modal,
  Form,
  Dropdown,
} from "react-bootstrap";
import ReactPlayer from "react-player";
import defaultUploader from "../assests/uploader.png";
import { toast } from "react-toastify";
import { uploadUserFile } from "../services/user/user.service";
import VideoModal from "./CommentModal/VideoModal";
import ImageModal from "./CommentModal/ImageModal";
import CaptionModal from "./CommentModal/CaptionModal";

const Videos = ({
  mediaId,
  isEditingPage,
  videos = [],
  updateVideos,
  deleteArray = [],
  updateDeleteArray,
  isloading,
  fetchUser,
}) => {
  const [selectedCaption, setSelectedCaption] = useState(null);
  const [showCaptionModal, setShowCaptionModal] = useState(false);
  const [visibleVideos, setVisibleVideos] = useState(5);
  const [showModal, setShowModal] = useState(false);
  const [VideoArray, setVideoArray] = useState(videos);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [captions, setCaptions] = useState({});
  const [photomediaId, setPhotoMediaId] = useState();
  const [showCaptionEditModal, setShowCaptionEditModal] = useState(false);
  const [editCaption, setEditCaption] = useState();
  const [profileId, setProfileId] = useState();
  const [commentsID, setCommentsID] = useState(null);
  const [newVideos, setNewVideos] = useState([]);
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    if (mediaId && !opened) {
      const matchingVideo = VideoArray?.find((video) => video._id === mediaId);
      if (matchingVideo) {
        openModal(matchingVideo);
        setOpened(true);
      }
    }
    console.log("Videos", videos);
    setVideoArray(videos);
  }, [mediaId, videos]);

  const handleLoadMore = () => {
    setVisibleVideos((prevVisibleVideos) => prevVisibleVideos + 12);
  };

  const handleDelete = (src) => {
    const updatedDeleteArray = [...deleteArray, src._id];
    updateDeleteArray(updatedDeleteArray);
    const updatedVideos = VideoArray.filter((video) => video !== src);
    updateVideos(updatedVideos);
  };

  const handleVideoUpload = async (e) => {
    const toastId = toast.loading("Uploading...", { closeButton: true });
    const files = Array.from(e.target.files);
    e.target.value = null;

    if (files.length > 10) {
      toast.update(toastId, {
        render: "You can upload up to 10 videos at a time.",
        type: "warning",
        isLoading: false,
        autoClose: 2000,
        hideProgressBar: true,
        closeButton: false,
      });
      return;
    }

    const oversizedFiles = files.filter((file) => file.size > 25 * 1024 * 1024);
    if (oversizedFiles.length > 0) {
      toast.update(toastId, {
        render: "Each video must be less than 25MB.",
        type: "warning",
        isLoading: false,
        autoClose: 2000,
        hideProgressBar: true,
        closeButton: false,
      });
      return;
    }

    if (files.length > 0 && files.length <= 10) {
      try {
        const formData = new FormData();
        files.forEach((file) => {
          formData.append("attachments", file);
        });

        const response = await uploadUserFile(formData);
        const newVideoSources = response?.data?.data; // Assuming response contains the new video sources as an array

        const newVideos = newVideoSources.map((src) => ({
          data: {
            urls: [src],
          },
          type: "video",
        }));

        setNewVideos(newVideos);
        setSelectedVideo(newVideos[0].data.urls[0]); // Set the first video as selected by default
        setShowCaptionModal(true);
        toast.dismiss(toastId);
      } catch (error) {
        toast.update(toastId, {
          render: error.response.data.error,
          type: "error",
          isLoading: false,
          autoClose: 2000,
          hideProgressBar: true,
          closeButton: false,
        });
      }
    } else {
      toast.update(toastId, {
        render: "Please select up to 10 videos.",
        type: "warning",
        isLoading: false,
        autoClose: 2000,
        hideProgressBar: true,
        closeButton: false,
      });
    }
  };

  const handleCaptionChange = (e) => {
    const updatedCaptions = {
      ...captions,
      [selectedVideo]: e.target.value,
    };
    setCaptions(updatedCaptions);
  };

  const handleSaveCaption = () => {
    const updatedVideos = newVideos.map((video) => {
      const url = video.data.urls[0];
      return {
        ...video,
        data: {
          ...video.data,
          caption: captions[url] || "",
        },
      };
    });

    updateVideos([...VideoArray, ...updatedVideos]);
    setShowCaptionModal(false);
    setCaptions({});
    setSelectedVideo(null);
  };

  const openModal = (src) => {
    if (src && src.data && src.data.urls && src.data.urls[0] && src._id) {
      setSelectedVideo(src.data.urls[0]);
      setSelectedCaption(src.data.caption);
      setCommentsID(src._id);
      setShowModal(true);
    }
  };

  const closeModalCaption = () => {
    setShowCaptionModal(false);
    setSelectedVideo(null);
  };

  const closeModal = () => {
    setSelectedVideo(null);
    setShowModal(false);
  };
  const closeEditModal = () => {
    setShowCaptionEditModal(false);
  };
  const openEditModal = (photo) => {
    setSelectedVideo(photo.data.urls[0]);
    setProfileId(photo?.profile);
    console.log("MEdia Id", photo.profile);
    setPhotoMediaId(photo?.data?._id);
    setEditCaption(photo?.data?.caption);
    setShowCaptionEditModal(true);
  };
  return (
    <>
      <div className="">
        <Row className="mx-3 pb-2 mt-4">
          {isloading ? (
            <div className="d-flex justify-content-center">
              <div className="loader-small"></div>
            </div>
          ) : VideoArray?.length === 0 ? (
            <div className="text-center w-100">
              <p className="about-section">No videos</p>
            </div>
          ) : (
            VideoArray?.slice(0, visibleVideos).map((video, index) => (
              <Col key={index} xs={6} md={4} className="pb-4">
                <div className="videos-main min-img-size position-relative">
                  {/* {isEditingPage && (
                                            <Button className="remove-btn" style={{ zIndex: 2 }} onClick={() => handleDelete(video)}>
                                                <Image src={Trash} />
                                            </Button>
                                        )} */}
                  {isEditingPage && video?.data?._id && (
                    <Dropdown
                      className="remove-btn-dropdown"
                      style={{ zIndex: 2 }}
                    >
                      <Dropdown.Toggle
                        className="custom-drop-edit px-2"
                        variant="link"
                        id="dropdown-basic"
                        style={{ textDecoration: "none" }}
                      >
                        •••
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => openEditModal(video)}>
                          Edit Caption
                        </Dropdown.Item>
                        <Dropdown.Item onClick={() => handleDelete(video)}>
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                  <ReactPlayer
                    className="react-player gallery-img"
                    url={video.data.urls[0]}
                    width="100%"
                    height="100%"
                    onClick={() => {
                      openModal(video);
                    }} // Open modal on video click
                  />
                </div>
              </Col>
            ))
          )}
          {!isloading && isEditingPage && (
            <Col xs={6} md={4} className="pb-4">
              <label htmlFor="VideoInput" className="w-100">
                <div className="min-video-size position-relative text-center">
                  <div
                    className="text-center mx-auto w-100"
                    style={{
                      position: "absolute",
                      top: "10px",
                      left: "50%",
                      transform: "translateX(-50%)",
                      margin: "auto"
                    }}
                  >
                    <p className="mb-0 w-100" style={{ fontSize: "12px", color: "grey" }}>Up to 10 Multiple Files</p>
                  </div>
                  <Image
                    src={defaultUploader}
                    className="gallery-img"
                    alt="uploader"
                    style={{ cursor: "pointer", borderRadius: "10px" }}
                  />
                </div>
              </label>
              <input
                id="VideoInput"
                accept="video/mp4, video/quicktime, video/x-msvideo"
                type="file"
                multiple
                onChange={handleVideoUpload}
                style={{ display: "none" }}
              />
            </Col>
          )}
        </Row>
        {visibleVideos < VideoArray?.length && (
          <div className="text-center">
            <Button className="load-btn" onClick={handleLoadMore}>
              Load More...
            </Button>
          </div>
        )}
      </div>
      <Modal show={showCaptionModal} onHide={closeModalCaption}>
        <Modal.Header closeButton>
          <Modal.Title className="sign-heading text-center pb-2">
            Add Caption
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="d-flex gap-2 mb-2"
            style={{ overflowX: "auto", overflowY: "hidden" }}
          >
            {newVideos &&
              newVideos.map((video, index) => (
                <div key={index}>
                  <ReactPlayer
                    className={`gallery-img ${selectedVideo === video.data.urls[0] ? "selected" : ""
                      }`}
                    url={video.data.urls[0]}
                    width="100px"
                    height="80px"
                    onClick={() => setSelectedVideo(video.data.urls[0])}
                    style={{
                      cursor: "pointer",
                      border:
                        selectedVideo === video.data.urls[0]
                          ? "2px solid blue"
                          : "none",
                    }}
                  />
                </div>
              ))}
          </div>
          <div
            className="player-wrapper mb-5 mb-lg-0"
            style={{
              paddingTop: "56.25%",
              marginBottom: "20px",
              height: "100%",
            }}
          >
            <ReactPlayer
              className="video-player comment-player h-100 "
              url={selectedVideo}
              width="100%"
              height={"100%"}
              controls
            />
          </div>
          <Form.Group controlId="caption" className="pt-3">
            <Form.Label className="pass-input">Caption</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter caption"
              value={captions[selectedVideo] || ""}
              onChange={handleCaptionChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModalCaption}>
            Close
          </Button>
          <Button
            variant="primary"
            className="login-button"
            onClick={handleSaveCaption}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      <CaptionModal
        showCaptionEditModal={showCaptionEditModal}
        closeEditModal={closeEditModal}
        selectedVideo={selectedVideo}
        profileId={profileId}
        photomediaId={photomediaId}
        editCaption={editCaption}
        fetchUser={fetchUser}
        type="Video"
      />
      <VideoModal
        showModal={showModal}
        isEditingPage={isEditingPage}
        closeModal={closeModal}
        selectedVideo={selectedVideo}
        commentId={commentsID}
        selectedCaption={selectedCaption}
      />
    </>
  );
};

export default Videos;