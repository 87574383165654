import React, { useState } from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import defaultUploader from "../assests/uploadAudio.png";
import Trash from "../assests/trash.svg";
import { toast } from "react-toastify";
import { uploadUserFile } from "../services/user/user.service";

const Audios = ({ isEditingPage, audios, updateAudios }) => {
  const [visibleAudios, setVisibleAudios] = useState(4);

  const handleLoadMore = () => {
    setVisibleAudios((prevVisibleAudios) => prevVisibleAudios + 4);
  };

  const handleAudioUpload = async (e) => {
    const toastId = toast.loading("Uploading...", { closeButton: true });
    const files = Array.from(e.target.files);

    if (files.length > 0) {
      try {
        const formData = new FormData();
        files.forEach((file) => {
          formData.append("attachments", file);
        });

        const response = await uploadUserFile(formData);
        const newAudioSources = response.data.data; // Assuming response contains the new audio sources as an array

        const updatedAudios = [...audios, ...newAudioSources];
        updateAudios(updatedAudios);

        toast.update(toastId, {
          render: "Audio(s) successfully added",
          type: "success",
          isLoading: false,
          autoClose: 2000,
          hideProgressBar: true,
          closeButton: false,
        });
      } catch (error) {
        toast.update(toastId, {
          render: error.response.data.data || "Error uploading audio(s)",
          type: "error",
          isLoading: false,
          autoClose: 2000,
          hideProgressBar: true,
          closeButton: false,
        });
      }
    }
  };

  const handleDelete = (src) => {
    const updatedAudios = audios.filter((audio) => audio !== src);
    updateAudios(updatedAudios);
  };

  return (
    <div className="">
      <Row className="mx-3 pb-2 mt-4">
        {audios?.length === 0 ? (
          <div className="text-center w-100">
            <p className="about-section">No Audios</p>
          </div>
        ) : (
          audios?.slice(0, visibleAudios).map((src, index) => (
            <Col key={index} xs={12} md={12} className="pb-4">
              <div className="Audio-wrapper card-item position-relative">
                {isEditingPage && (
                  <Button
                    className="audio-btn"
                    onClick={() => handleDelete(src)}
                  >
                    <Image src={Trash} />
                  </Button>
                )}
                <AudioPlayer
                  src={src}
                  customAdditionalControls={isEditingPage ? [] : ["LOOP"]}
                />
              </div>
            </Col>
          ))
        )}
        {isEditingPage && (
          <Col xs={12} md={12} className="pb-4">
            <label htmlFor="AudioInput">
              <div className="min-audio-size position-relative" style={{ border: "1px dashed" }}>
                <div
                  className="text-center mx-auto w-100"
                  style={{
                    position: "absolute",
                    top: "10px",
                    left: "50%",
                    transform: "translateX(-50%)",
                    margin: "auto",
                  }}
                >
                  <p
                    className="mb-0 w-100"
                    style={{ fontSize: "12px", color: "grey" }}
                  >
                    Up to 10 Multiple Files
                  </p>
                </div>
                <Image
                  src={defaultUploader}
                  className="gallery-audio"
                  alt="uploader"
                  style={{ cursor: "pointer" }}
                />
              </div>
            </label>
            <input
              id="AudioInput"
              className="w-100"
              type="file"
              accept="audio/mpeg, audio/wav, audio/ogg, audio/mp3"
              multiple
              onChange={handleAudioUpload}
              style={{ display: "none" }}
            />
          </Col>
        )}
      </Row>
      {visibleAudios < audios?.length && (
        <div className="text-center">
          <Button className="load-btn" onClick={handleLoadMore}>
            Load More...
          </Button>
        </div>
      )}
    </div>
  );
};

export default Audios;
