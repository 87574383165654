import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import hero from "../assests/bigupload.png";
import profile from "../assests/smallupload.png";
import Toggle from "react-toggle";
import "../toggle.css";
import {
  Button,
  Col,
  Container,
  Image,
  Modal,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import Photos from "./Photos";
import GraveyardLocation from "./GraveyardLocation";
import Videos from "./Videos";
import Audios from "./Audios";
import Header from "./Header";
import upload from "../assests/profile-upload.png";
import Infoedit from "./EditFields/NameEdit";
import Dateedit from "./EditFields/DateEdit";
import AboutEdit from "./EditFields/AboutEdit";
import Footer from "./Footer";
import confetti from "canvas-confetti";
import { toast } from "react-toastify";
import ShareComponent from "./ShareComponent";
import Slideshare from "./Slideshare";
import {
  DeleteMedia,
  GetUserInfo,
  SaveToken,
  UpdateFlower,
  UpdateUserInfo,
  uploadUserFile,
} from "../services/user/user.service";
import { useNavigate } from "react-router-dom";
import PasswordModal from "./EditFields/PasswordModal";
import { AuthVerify } from "../utils/auth.utils";
import ProtectedPageModal from "./EditFields/ProtectedPageModal";
import { FaCheck, FaTimes } from "react-icons/fa";
import Tribute from "./Tribute";
import Cropper from "react-easy-crop";
import getCroppedImg from "./ImageCrop/CropImage";
import { useCallback } from "react";
import { messaging } from "../firebase";
import { getToken, onMessage } from "firebase/messaging";
import DashboardModal from "./DashboardModal";
import ImageModal from "./ImageModal/ImageModal";

const MainPage = () => {
  const verify = AuthVerify();
  const baseUrl = process.env.REACT_APP_WEB_BASE_URL;
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [isEditingPage, setIsEditingPage] = useState(false);
  const [showSavePublishButtons, setShowSavePublishButtons] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showProtectedModal, setShowProtectedModal] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [videos, setVideos] = useState([]);
  const [slideshare, setSlideShare] = useState();
  const [croppedArea, setCroppedArea] = useState(null);
  const [show, setShow] = useState(false);
  const [showBanner, setShowBaneer] = useState(false);
  const [audios, setAudios] = useState();
  const [coordinates, setCoordinates] = useState();
  const [address, setAddress] = useState();
  const [isEmptyPhotos, setIsEmptyPhotos] = useState(false);
  const [isEmptyVideos, setIsEmptyVideos] = useState(false);
  const [isEmptySlideshare, setIsEmptySlideshare] = useState(false);
  const [occupation, setOccupation] = useState();
  const [infoAddress, setInfoAddress] = useState();
  const [infoCountry, setInfoCountry] = useState();
  const [infoCity, setInfoCity] = useState();
  const [isPrivate, setIsPrivate] = useState();
  const [deleteArray, setDeleteArray] = useState([]);
  const [arlink, setArlink] = useState();
  const [fullname, setFullname] = useState("");
  const defaultfullname = "Enter Profile Name";
  const [dob, setDob] = useState("");
  const [deceasedDate, setDeceasedDate] = useState("");
  const [loading, setLoading] = useState(true);
  const [mediaLoading, setMediaLoading] = useState(true);
  const [bio, setBio] = useState("");
  const [profileImage, setProfileImage] = useState(null);
  const [profileImageCrop, setProfileImageCrop] = useState(null);
  const [userInfo, setUserInfo] = useState([]);
  const [userData, setUserData] = useState({});
  const [bannerImage, setBannerImage] = useState(null);
  const [bannerImageCrop, setBannerImageCrop] = useState(null);
  const [password, setPassword] = useState("");
  const currentURL = window.location.href;
  const [flowerCount, setFlowerCount] = useState(0);
  const [showDashboard, setShowDashboard] = useState(false);
  const [activeKey, setActiveKey] = useState("first");
  const [arAnimation, setArAnimation] = useState(null);
  const [flowerLoading, setFlowerLoading] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedName, setSelectedName] = useState();
  function randomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }

  const handleShowModal = () => setShowDashboard(true);
  const handleCloseModal = () => {
    setShowDashboard(false);
    setActiveKey("first");
  };

  const handleFlowerClick = async () => {
    setFlowerLoading(true);
    const toastId = toast.loading("Sending Digital Flowers", {
      closeButton: true,
    });
    try {
      await UpdateFlower(id, {
        flowerCount,
      });
      toast.update(toastId, {
        render: "Flower Added successfully",
        type: "success",
        isLoading: false,
        autoClose: 2000,
        hideProgressBar: true,
        closeButton: false,
      });
      const duration = 5 * 1000;
      const animationEnd = Date.now() + duration;
      let skew = 1;
      const scalar = 1.5;
      const flower = confetti.shapeFromText({ text: "🌸", scalar });
      const flower1 = confetti.shapeFromText({ text: "💐", scalar });
      const frame = () => {
        const timeLeft = animationEnd - Date.now();
        const ticks = Math.max(200, 500 * (timeLeft / duration));
        skew = Math.max(0.8, skew - 0.001);

        confetti({
          particleCount: 1,
          startVelocity: 0,
          ticks: ticks,
          origin: {
            x: Math.random(),
            y: Math.random() * skew - 0.2,
          },
          shapes: [flower, flower1],
          scalar,
          gravity: randomInRange(0.4, 0.6),
          // scalar: randomInRange(0.4, 1),
          drift: randomInRange(-0.4, 0.4),
        });

        if (timeLeft > 0) {
          requestAnimationFrame(frame);
        }
      };

      frame();

      setFlowerCount(flowerCount + 1);
      setFlowerLoading(false);
    } catch (error) {
      // Error handling
      console.error("err", error);
      toast.error({
        render: error?.response?.data?.error,
        isLoading: false,
        autoClose: 2000,
        hideProgressBar: true,
        closeButton: false,
      });
    } finally {
      setMediaLoading(false);
    }
  };

  const [key, setKey] = useState("photos");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const slideshareActive = searchParams.get("tab") === "slideshare";
  const type = searchParams.get("type");
  const media = searchParams.get("media");
  let edit = searchParams.get("edit");
  const dashboard = searchParams.get("dashboard");
  const [editParam, setEditParam] = useState(edit);
  const [dashboardActive, setDashboardActive] = useState(dashboard);

  const { id } = useParams();

  useEffect(() => {
    if (slideshareActive) {
      setKey("slide share");
      const section = document.getElementById("memories");
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location, slideshareActive]);

  const navigate = useNavigate();
  const fetchUser = async () => {
    try {
      const response = await GetUserInfo(id);
      const user = response?.data?.data;
      console.log("users", user);
      localStorage.setItem("profile", JSON.stringify(user));
      setUserInfo(response.data.data);
      setBannerImage(response.data.data.bannerImage);
      setProfileImage(response.data.data.profileImage);
      setFullname(response.data.data.fullname);
      setBio(response.data.data.bio || "");
      setInfoCity(response.data.data.bioCity || "");
      setInfoCountry(response.data.data.bioCountry || "");
      setInfoAddress(response.data.data.bioAdress || "");
      setOccupation(response.data.data.bioOccupation || "");
      setIsPrivate(response.data.data.isPrivate);
      setPassword(response.data.data.password);
      setPhotos(response.data.data.photos);
      setVideos(response.data.data.videos);
      setIsEmptyVideos(response.data.data?.videos?.length === 0);
      setIsEmptyPhotos(response.data.data?.photos?.length === 0);
      setIsEmptySlideshare(response.data.data?.slideshare?.length === 0);
      setAudios(response.data.data.audios);
      setArAnimation(response.data.data.arAnimation);
      setCoordinates(
        response.data.data.coordinates[0] === 0
          ? [40.7375664, -74.0024799]
          : response.data.data.coordinates
      );
      setAddress(response.data.data.address || "W 12th St, New York, NY, USA");
      setDob(response.data.data.dob || "");
      setDeceasedDate(response.data.data.deceasedDate || "");
      setShowProtectedModal(response.data.data.isPrivate);
      setSlideShare(response.data.data.slideshare);
      setFlowerCount(response.data.data.flowerCount);
      if (
        response?.data?.data?.arAnimation === null ||
        response.data?.data?.arAnimation?.media?.length === 0
      ) {
        setArlink(null);
      } else {
        setArlink(`${baseUrl}/ARView/${id}`);
      }
      if (type) {
        if (type === "tribute") {
          setKey("tribute");
          const section = document.getElementById("memories");
          if (section) {
            section.scrollIntoView({ behavior: "smooth" });
          }
        } else if (type === "comment" && media) {
          setKey("photos");
          const section = document.getElementById("memories");
          if (section) {
            section.scrollIntoView({ behavior: "smooth" });
          }
        }
      }
      if (editParam) {
        handleEditPage();
        setEditParam(false);
      }
      if (dashboardActive) {
        handleShowModal();
        setDashboardActive(false);
      }
    } catch (error) {
      // console.log("errro code", error.response.data.code)
      if (error.response.data.code === 404 || error.code === "ERR_NETWORK") {
        navigate("*");
      }
    } finally {
      setLoading(false);
      setMediaLoading(false);
    }
  };
  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    let user = userInfo.user;
    const loggedInUser = JSON.parse(localStorage?.getItem("user"));
    setUserData(loggedInUser);
    console.log("userrr", user);
    if (user === null) {
      navigate(`/sign-up/${id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, navigate]);

  const handleEditPage = () => {
    // console.log("pass", password)
    setIsEditingPage(!isEditingPage);
    setShowSavePublishButtons(true);
  };
  const handleDpImageUpload = async (e) => {
    e.preventDefault();
    const toastId = toast.loading("Uploading Image", { closeButton: true });
    const file = e.target.files[0];
    e.target.value = null;
    if (file) {
      try {
        const formData = new FormData();
        formData.append("attachments", file);
        const response = await uploadUserFile(formData);
        setProfileImageCrop(response.data.data[0]);
        toast.update(toastId, {
          render: "Image Uploaded. Please crop the image.",
          type: "success",
          isLoading: false,
          autoClose: 2000,
          hideProgressBar: true,
          closeButton: false,
        });
        handleShowImage(); // Show the cropping modal
      } catch (error) {
        console.log("error uploading", error);
        toast.update(toastId, {
          render: error.response.data.error,
          type: "error",
          isLoading: false,
          autoClose: 2000,
          hideProgressBar: true,
          closeButton: false,
        });
      }
    }
  };
  const handleSaveDates = (dates) => {
    setDob(dates[0]);
    setDeceasedDate(dates[1]);
  };
  const handleDpCoverUpload = async (e) => {
    e.preventDefault();
    const toastId = toast.loading("Uploading Image", { closeButton: true });
    const file = e.target.files[0];
    console.log("file image", file);
    e.target.value = null;
    if (file) {
      try {
        const formData = new FormData();
        formData.append("attachments", file);
        const response = await uploadUserFile(formData);
        setBannerImageCrop(response.data.data[0]);
        toast.update(toastId, {
          render: "Image Uploaded. Please crop the image.",
          type: "success",
          isLoading: false,
          autoClose: 2000,
          hideProgressBar: true,
          closeButton: false,
        });
        handleShowBanner(); // Show the cropping modal
      } catch (error) {
        toast.update(toastId, {
          render: error.response.data.error,
          type: "error",
          isLoading: false,
          autoClose: 2000,
          hideProgressBar: true,
          closeButton: false,
        });
      }
    }
  };
  const handleInfoSave = (updatedBio, updatedDetails) => {
    setBio(updatedBio);
    setOccupation(updatedDetails.occupation);
    setInfoCountry(updatedDetails.country);
    setInfoCity(updatedDetails.city);
    setInfoAddress(updatedDetails.address);
  };
  const handleSave = async () => {
    const toastId = toast.loading("Updating", { closeButton: true });
    try {
      // Check if deleteArray is not empty
      if (deleteArray?.length > 0) {
        await DeleteMedia(id, deleteArray); // Replace with your actual delete API call
        // toast.update(toastId, {
        //     render: "Media deleted successfully",
        //     type: "success",
        //     isLoading: false,
        //     autoClose: 2000,
        //     hideProgressBar: true,
        //     closeButton: false,
        // });
      }
      await UpdateUserInfo(id, {
        fullname,
        bio,
        profileImage,
        bannerImage,
        dob,
        deceasedDate,
        isPrivate,
        password,
        photos,
        videos,
        audios,
        address,
        coordinates,
        slideshare,
        bioAdress: infoAddress,
        bioOccupation: occupation,
        bioCity: infoCity,
        bioCountry: infoCountry,
      });

      toast.update(toastId, {
        render: "Profile updated successfully",
        type: "success",
        isLoading: false,
        autoClose: 2000,
        hideProgressBar: true,
        closeButton: false,
      });
      setDeleteArray([]);
      setVideos([]);
      setPhotos([]);
      fetchUser();
    } catch (error) {
      // Error handling
      console.error("err", error);
      toast.update(toastId, {
        render: error?.response?.data?.error,
        type: "error",
        isLoading: false,
        autoClose: 2000,
        hideProgressBar: true,
        closeButton: false,
      });
      fetchUser();
      setDeleteArray([]);
    } finally {
      setMediaLoading(false);
    }

    setShowSavePublishButtons(false);
    setIsEditingPage(false);
  };

  // const handlePublish = () => {
  //     setShowSavePublishButtons(false);
  //     setIsEditingPage(false)
  //     // Handle publishing logic here
  // };

  const handleCancel = () => {
    setShowSavePublishButtons(false);
    setIsEditingPage(false);
    fetchUser();
    setDeleteArray([]);
  };
  const handleTogglePrivacy = () => {
    if (isPrivate) {
      setPassword("");
    }
    setIsPrivate(!isPrivate);
    setShowPasswordModal(true);
  };
  const updatePhotos = (updatedPhotos) => {
    console.log("photos Update", updatedPhotos);
    setPhotos(updatedPhotos);
  };
  const updateDeleteArray = (updatedDeleteArray) => {
    setDeleteArray(updatedDeleteArray);
  };
  const updateVideos = (updatedVideos) => {
    setVideos(updatedVideos);
  };
  const updateAudios = (updatedAudios) => {
    setAudios(updatedAudios);
  };
  const updateAddress = (updatedAddress) => {
    setAddress(updatedAddress);
  };
  const updateCoordinates = (updatedCoordinates) => {
    setCoordinates(updatedCoordinates);
  };
  const updateSlideShare = (updatedSlideShare) => {
    setSlideShare(updatedSlideShare);
  };
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  }, []);

  const handleCropSave = async () => {
    const toastId = toast.loading("Saving cropped image", {
      closeButton: true,
    });
    try {
      const croppedImageBlob = await getCroppedImg(
        profileImageCrop,
        croppedArea
      );
      // Convert Blob to File
      const croppedImageFile = new File([croppedImageBlob], "cropped.jpg", {
        type: "image/jpeg",
      });
      // Create form data
      const formData = new FormData();
      formData.append("attachments", croppedImageFile);

      // Upload cropped image to Cloudinary
      const response = await uploadUserFile(formData);
      setProfileImage(response.data.data[0]);
      toast.update(toastId, {
        render: "Profile image updated successfully",
        type: "success",
        isLoading: false,
        autoClose: 2000,
        hideProgressBar: true,
        closeButton: false,
      });
      setShow(false);
    } catch (e) {
      toast.update(toastId, {
        render: "Error saving cropped image",
        type: "error",
        isLoading: false,
        autoClose: 2000,
        hideProgressBar: true,
        closeButton: false,
      });
      console.error(e);
    }
  };
  const handleCropSaveBanner = async () => {
    const toastId = toast.loading("Saving cropped image", {
      closeButton: true,
    });
    try {
      const croppedImageBlob = await getCroppedImg(
        bannerImageCrop,
        croppedArea
      );
      const croppedImageFile = new File([croppedImageBlob], "cropped.jpg", {
        type: "image/jpeg",
      });
      const formData = new FormData();
      formData.append("attachments", croppedImageFile);
      const response = await uploadUserFile(formData);
      setBannerImage(response.data.data[0]);
      toast.update(toastId, {
        render: "Banner image updated successfully",
        type: "success",
        isLoading: false,
        autoClose: 2000,
        hideProgressBar: true,
        closeButton: false,
      });
      setShowBaneer(false);
    } catch (e) {
      toast.update(toastId, {
        render: "Error saving cropped image",
        type: "error",
        isLoading: false,
        autoClose: 2000,
        hideProgressBar: true,
        closeButton: false,
      });
      console.error(e);
    }
  };
  const handleCloseImage = () => setShow(false);
  const handleShowImage = () => setShow(true);
  const handleCloseBanner = () => setShowBaneer(false);
  const handleShowBanner = () => setShowBaneer(true);

  const calculateAge = (dob, deceasedDate) => {
    const birthDate = new Date(dob);
    const deathDate = new Date(deceasedDate);

    let age = deathDate.getFullYear() - birthDate.getFullYear();
    const monthDiff = deathDate.getMonth() - birthDate.getMonth();
    const dayDiff = deathDate.getDate() - birthDate.getDate();

    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }

    if (age < 1) {
      // Calculate months difference
      let months = (deathDate.getFullYear() - birthDate.getFullYear()) * 12;
      months += deathDate.getMonth() - birthDate.getMonth();

      if (dayDiff < 0) {
        months--; // Adjust if the current day hasn't occurred yet in the month
      }

      if (months === 1) {
        return `${months} Month`;
      }
      else {
        return `${months} Months`;

      }
    }
    if (age === 1) {
      return `${age} Year`;
    }
    else {
      return `${age} Years`;
    }
  };
  const openImageModal = (imageUrl, name, large) => {
    setSelectedImage(imageUrl);
    setSelectedName(name)
    setShowImageModal(true);
  };

  const closeImageModal = () => {
    setShowImageModal(false);
    setSelectedImage(null);
  };
  return (
    <>
      {loading ? (
        <div className="loader-block">
          <div className="loader"></div>
        </div>
      ) : (
        <div>
          <Header
            user={userData}
            handleShowModal={handleShowModal}
            onEditPage={handleEditPage}
            showButtons={showSavePublishButtons}
            arlink={arlink}
          />

          <div>
            <Container className="position-relative banner-p-0">
              <div className="hero-img " onClick={() => openImageModal(bannerImage || hero, fullname || defaultfullname, true)}
                style={{ cursor: 'pointer' }}>
                <Image
                  className="card-img-top"
                  src={bannerImage || hero}
                  alt="Card image cap"
                />
              </div>
              {isEditingPage && (
                <div className="camera-icon-cover position-absolute">
                  <label htmlFor="coverImageInput" className="">
                    <div className="upload-icon">
                      <Image
                        src={upload}
                        className="w-100 h-100"
                        alt="upload-icon"
                      ></Image>
                    </div>
                    <input
                      id="coverImageInput"
                      type="file"
                      onChange={handleDpCoverUpload}
                      accept="image/jpeg, image/png"
                      style={{ display: "none" }}
                    />
                  </label>
                </div>
              )}
            </Container>
            <Container className="border-bottom">
              <div className="card-body little-profile position-relative">
                <Row className="justify-content-between align-items-center">
                  <Col xs={12} md={12} lg={6} className="pb-lg-4 px-0">
                    <div className="d-flex img-layout position-relative">
                      <div className="pro-img position-relative" onClick={() => openImageModal(profileImage || profile, fullname || defaultfullname)}
                        style={{ cursor: 'pointer' }}>
                        <Image
                          src={profileImage || profile}
                          alt="user"
                          className="rounded-circle"
                        />
                      </div>
                      {isEditingPage && (
                        <div className="camera-icon">
                          <label htmlFor="dpImageInput" className="">
                            <div className="upload-icon">
                              <Image
                                src={upload}
                                className="w-100 h-100"
                                alt="upload-icon"
                              ></Image>
                            </div>
                            <input
                              id="dpImageInput"
                              type="file"
                              onChange={handleDpImageUpload}
                              accept="image/jpeg, image/png"
                              style={{ display: "none" }}
                            />
                          </label>
                        </div>
                      )}
                      <div className="p-md-4 p-0 ps-md-4 text-center text-md-start">
                        <Infoedit
                          initialValue={fullname || defaultfullname}
                          onSave={(value) => setFullname(value)}
                          isEditingPage={isEditingPage}
                        />
                        <Dateedit
                          initialValue={[dob, deceasedDate]}
                          onSave={handleSaveDates}
                          isEditingPage={isEditingPage}
                        />
                        {dob && deceasedDate && (
                          <p className="name-para ps-1 mb-0">
                            Age: {calculateAge(dob, deceasedDate)}
                          </p>
                        )}
                        {!isEditingPage && (
                          <div className="d-flex pt-lg-1 align-items-center date-div">
                            🌸
                            <div className="name-para ps-1 mb-0">
                              {flowerCount}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} md={12} lg={6} className="py-4">
                    {isEditingPage ? (
                      <div className="d-flex align-items-center toggle-section justify-content-end pe-md-0 pe-2">
                        <label className="d-flex align-items-center">
                          {isPrivate ? (
                            <span className="pe-md-4 pe-2 private-toogle-text">
                              Private:
                            </span>
                          ) : (
                            <span className="pe-md-4 pe-2 private-toogle-text">
                              Public:
                            </span>
                          )}
                          <div className="border-color">
                            <Toggle
                              defaultChecked={isPrivate}
                              icons={false}
                              onChange={handleTogglePrivacy}
                            />
                          </div>
                        </label>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-center justify-content-md-end">
                        {
                          arAnimation ? (
                            <a href={`/ARView/${id}`} target="_blank" rel="noopener noreferrer">
                              <Button className="edit-btn mx-2">
                                AR Portal
                              </Button>
                            </a>
                          ) : verify ? (
                            <Link to={`/prayer-card/${id}?armessage=true`}>
                              <Button
                                className="edit-btn mx-2"
                              >
                                AR Portal
                              </Button>
                            </Link>
                          ) : ""
                        }
                        <Link to={`/prayer-card/${id}`}>
                          <Button className='edit-btn me-2'>Prayer Card</Button>
                        </Link>
                        <Button
                          className="edit-btn"
                          whileTap={{ scale: 1.2 }}
                          onClick={handleFlowerClick}
                          disabled={flowerLoading}
                        >
                          Send Digital Flowers
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
          <Container>
            <Row>
              <Col md={4}>
                <AboutEdit
                  bio={bio}
                  bioBorn={dob}
                  bioDied={deceasedDate}
                  bioCity={infoCity}
                  bioCountry={infoCountry}
                  bioAddress={infoAddress}
                  bioOccupation={occupation}
                  onSave={handleInfoSave}
                  isEditingPage={isEditingPage}
                />
              </Col>
              <Col md={8} className="border-left g-0">
                {/* Memories Section */}
                <div>
                  <Tabs
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    id="memories"
                    className="mb-3 tab-text"
                    fill
                  >
                    <Tab eventKey="photos" title="Photos">
                      <Photos
                        mediaId={media}
                        isEditingPage={isEditingPage}
                        photos={photos}
                        updatePhotos={updatePhotos}
                        deleteArray={deleteArray}
                        updateDeleteArray={updateDeleteArray}
                        isloading={mediaLoading}
                        isEmpty={isEmptyPhotos}
                        fetchUser={fetchUser}
                      />
                    </Tab>
                    <Tab eventKey="videos" title="Videos">
                      <Videos
                        mediaId={media}
                        isEditingPage={isEditingPage}
                        videos={videos}
                        updateVideos={updateVideos}
                        deleteArray={deleteArray}
                        updateDeleteArray={updateDeleteArray}
                        isloading={mediaLoading}
                        isEmpty={isEmptyVideos}
                        fetchUser={fetchUser}
                      />
                    </Tab>
                    <Tab eventKey="audios" className="audios" title="Audios">
                      <Audios
                        isEditingPage={isEditingPage}
                        audios={audios}
                        updateAudios={updateAudios}
                      />
                    </Tab>
                    <Tab
                      eventKey="slide share"
                      className="audios"
                      title="Slide Share"
                    >
                      <Slideshare
                        mediaId={media}
                        isEditingPage={isEditingPage}
                        images={photos}
                        slideshare={slideshare}
                        updateSlideShare={updateSlideShare}
                        deleteArray={deleteArray}
                        updateDeleteArray={updateDeleteArray}
                        isloading={mediaLoading}
                        isEmpty={isEmptySlideshare}
                      />
                    </Tab>
                    <Tab eventKey="tribute" className="audios" title="Tribute">
                      <Tribute isEditingPage={isEditingPage} />
                    </Tab>
                  </Tabs>
                </div>
              </Col>
            </Row>
          </Container>

          {/* About Section */}
          <Container className="mb-5">
            <GraveyardLocation
              isEditingPage={isEditingPage}
              address={address}
              coordinates={coordinates}
              updateAddress={updateAddress}
              updateCoordinates={updateCoordinates}
            />

            {showSavePublishButtons && (
              <>
                <div className="fixed-bottom d-flex justify-content-end gap-3 p-3 bg-light">
                  <Button
                    variant="light"
                    className="border d-flex align-items-center justify-content-center gap-1 fw-bolds"
                    onClick={handleCancel}
                  >
                    <FaTimes size={15} color="black" />
                    CANCEL
                  </Button>
                  <Button
                    className="edit-btn border d-flex align-items-center justify-content-center gap-2 fw-bold"
                    onClick={handleSave}
                  >
                    <FaCheck size={13} color="white" />
                    SAVE
                  </Button>
                </div>
              </>
            )}
            {showPasswordModal && isPrivate && (
              <PasswordModal
                show={showPasswordModal}
                onSubmit={(enteredPassword) => setPassword(enteredPassword)}
                onClose={() => setShowPasswordModal(false)}
              />
            )}
            {showProtectedModal && isPrivate && !verify && (
              <ProtectedPageModal
                show={showProtectedModal}
                // password={password}
                onClose={() => setShowProtectedModal(false)}
              />
            )}
          </Container>

          {/* Footer */}
          <Footer />
          <ShareComponent
            url={currentURL}
            profileImage={profileImage}
            fullname={fullname}
          />
        </div>
      )}
      <Modal show={show} onHide={handleCloseImage} centered>
        <Modal.Header closeButton>
          <Modal.Title>Profile Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center justify-content-center modal-crop">
            <Cropper
              image={profileImageCrop}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="edit-btn border d-flex align-items-center justify-content-center gap-1 fw-bolds"
            onClick={handleCloseImage}
            style={{
              color: "black",
              backgroundColor: "transparent",
              borderColor: "#dee2e6",
            }}
          >
            <FaTimes size={15} color="black" />
            CANCEL
          </Button>
          <Button
            className="edit-btn border d-flex align-items-center justify-content-center gap-2 fw-bold"
            onClick={handleCropSave}
          >
            <FaCheck size={13} color="white" />
            Crop & Save
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showBanner} onHide={handleCloseBanner} centered>
        <Modal.Header closeButton>
          <Modal.Title>Banner Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center justify-content-center modal-crop">
            <Cropper
              image={bannerImageCrop}
              crop={crop}
              zoom={zoom}
              aspect={3 / 1}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="edit-btn border d-flex align-items-center justify-content-center gap-1 fw-bolds"
            onClick={handleCloseBanner}
            style={{
              color: "black",
              backgroundColor: "transparent",
              borderColor: "#dee2e6",
            }}
          >
            <FaTimes size={15} color="black" />
            CANCEL
          </Button>
          <Button
            className="edit-btn border d-flex align-items-center justify-content-center gap-2 fw-bold"
            onClick={handleCropSaveBanner}
          >
            <FaCheck size={13} color="white" />
            Crop & Save
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <DashboardModal
        show={showDashboard}
        handleClose={handleCloseModal}
        activeKey={activeKey}
        setActiveKey={setActiveKey}
      /> */}
      <ImageModal
        show={showImageModal}
        onHide={closeImageModal}
        imageUrl={selectedImage}
        Name={selectedName}
      />
    </>
  );
};

export default MainPage;
