import React, { useEffect, useState } from 'react';
import { FaPencilAlt } from 'react-icons/fa';
import { Modal, Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

const InfoEdit = ({ initialValue, onSave, isEditingPage }) => {
    const [value, setValue] = useState(initialValue);
    const [editedValue, setEditedValue] = useState(initialValue);
    const [isEditing, setIsEditing] = useState(false);

    const handleEdit = () => {
        setEditedValue(value);
        setIsEditing(true);
    };

    const handleChange = (e) => {
        setEditedValue(e.target.value);
    };
    useEffect(() => {
        setValue(initialValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue]);

    const handleSave = () => {
        const toastId = toast.loading("Updating", { closeButton: true });
        toast.update(toastId, {
            render: "Name is Succesfully Updated",
            type: "success",
            isLoading: false,
            autoClose: 2000,
            hideProgressBar: true,
            closeButton: false,
        });
        onSave(editedValue);
        setIsEditing(false);
        setValue(editedValue); // Update the displayed value after saving
    };

    const handleClose = () => {
        setIsEditing(false);
        // setEditedValue(value); // Reset edited value to original value
    };

    return (
        <div>
            <div className='justify-content-center d-flex justify-content-lg-start gap-2'>
                <h3 className="mb-0 name-heading text-capitalize">{value}</h3>
                {isEditingPage && (
                    <FaPencilAlt onClick={handleEdit} style={{ cursor: "pointer" }} />
                )}
            </div>
            <Modal show={isEditing} onHide={handleClose} centered>
                <Modal.Header className='justify-content-center align-items-center border-0'>
                    <Modal.Title className='sign-heading text-center pb-2'>Edit Name</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formBasicPassword" className='pb-3'>
                            <Form.Label className='pass-input'>Name</Form.Label>
                            <Form.Control type="text" placeholder="Name" value={editedValue} onChange={handleChange} />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button type="button" className='login-button' onClick={handleSave}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default InfoEdit;
