import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import indiviual from '../assests/individual.svg'
import family from '../assests/Family.svg'
import bussiness from '../assests/businesses (1).svg'

const TargetMarket = () => {
  return (
    <>
     <section id="target-market" className="py-5" >
    <Container>
        <h2 className="text-center mb-4 pb-4">Our Target Market</h2>
        <Row className='py-3 d-flex align-items-stretch justify-content-center gap-lg-0'>
            <Col lg={4} md={6} className="mb-3">
                <Card className="text-center card-transparent justify-content-center align-items-center pt-4" style={{minHeight:'320px'}}>
                    <Card.Img variant="top" src={indiviual} style={{ width: '16%', margin: 'auto', padding: '5px' }} />
                    <Card.Body>
                        <h4 className="mt-3">Individuals</h4>
                        <p>Perfect for those looking to preserve personal milestones, such as  weddings and anniversaries.</p>
                    </Card.Body>
                </Card>
            </Col>
            <Col lg={4} md={6} className="mb-3">
                <Card className="text-center card-transparent justify-content-center align-items-center pt-4" style={{minHeight:'320px'}}>
                    <Card.Img variant="top" src={family} style={{ width: '16%', margin: 'auto', padding: '5px' }} />
                    <Card.Body>
                        <h4 className="mt-3">Families</h4>
                        <p>Ideal for creating shared family memories that can be treasured for generations to come.</p>
                    </Card.Body>
                </Card>
            </Col>
            <Col lg={4} md={6} className="mb-3">
                <Card className="text-center card-transparent justify-content-center align-items-center pt-4" style={{minHeight:'320px'}}>
                    <Card.Img variant="top" src={bussiness} style={{ width: '20%', margin: 'auto', padding: '7px' }} />
                    <Card.Body>
                        <h4 className="mt-3">Businesses</h4>
                        <p>Great for companies wanting to commemorate corporate events, anniversaries, and other significant milestones.</p>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Container>
</section>

    </>
  )
}

export default TargetMarket
