import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { FaPencilAlt, FaRegCalendarAlt } from 'react-icons/fa'
import QRCode from 'qrcode.react';
import moment from 'moment';
import EditPrayerTimeModal from './EditPrayerTimeModal';
import EditModal from './EditModal';
import EditInfoModal from './EditInfoModal';
const TemplateBack2 = ({ isEditingPage, templateHead, sign, number, company, profilename, dob, deceasedDate, info, prayerLocation, prayerDate, intermentLocation, profileImage, arlink, onUpdate }) => {
    const { id } = useParams();
    const baseUrl = process.env.REACT_APP_WEB_BASE_URL;

    const [localFullname, setLocalFullname] = useState(profilename);
    const [localDob, setLocalDob] = useState(dob);
    const [showPrayerLocationModal, setShowPrayerLocationModal] = useState(false);
    const [showDateTimeModal, setShowDateTimeModal] = useState(false);
    const [showIntermentLocationModal, setShowIntermentLocationModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [localInfo, setLocalInfo] = useState(info);
    const [localDeceasedDate, setLocalDeceasedDate] = useState(deceasedDate);
    const [localPrayerDateTime, setLocalPrayerDateTime] = useState(prayerDate);
    const [localSign, setLocalsign] = useState(sign);
    const [localNumber, setLocalNumber] = useState(number);
    const [localCompany, setLocalCompany] = useState(company);
    const [showCompanyModal, setShowCompanyModal] = useState(false);
    const [showNumberModal, setShowNumberModal] = useState(false);
    const [showSignModal, setShowSignModal] = useState(false);
    const [localPrayerLocation, setLocalPrayerLocation] = useState(prayerLocation);
    const [localintermentLocation, setLocalIntermentLocation] = useState(intermentLocation);
    // const [localProfileImage, setLocalProfileImage] = useState(profileImage);
    const qrLink = `${baseUrl}/${id}`;
    useEffect(() => {
        setLocalFullname(profilename);
        setLocalDob(dob);
        setLocalDeceasedDate(deceasedDate);
        setLocalPrayerLocation(prayerLocation);
        setLocalPrayerDateTime(prayerDate);
        setLocalIntermentLocation(intermentLocation);
        setLocalsign(sign);
        setLocalNumber(number);
        setLocalCompany(company);
        setLocalInfo(info);
        setLocalPrayerLocation(prayerLocation)
        setLocalIntermentLocation(intermentLocation)
    }, [profilename, dob, deceasedDate, info, prayerLocation, prayerDate, intermentLocation, sign, number, company, info, intermentLocation]);

    const handleDateTimeSave = (newDOP) => {
        console.log(setLocalInfo)
        setLocalPrayerDateTime(newDOP);
        onUpdate(templateHead, localSign, localNumber, localCompany, localFullname, localDob, localDeceasedDate, localInfo, localPrayerLocation, newDOP, localintermentLocation);
        // onUpdate(templateHead, sign, number, company, profilename, localDob, localDeceasedDate, newInfo, prayerLocation, prayerDate, intermentLocation);

    };
    const handlePrayerLocation = (newLocation) => {
        setLocalPrayerLocation(newLocation);
        onUpdate(templateHead, localSign, localNumber, localCompany, localFullname, localDob, localDeceasedDate, localInfo, newLocation, localPrayerDateTime, localintermentLocation);
    };
    const handleNameSave = (newInfo) => {
        setLocalInfo(newInfo);
        onUpdate(templateHead, localSign, localNumber, localCompany, localFullname, localDob, localDeceasedDate, newInfo, prayerLocation, prayerDate, intermentLocation);
    };
    const handleIntermentLocation = (newLocation) => {
        setLocalIntermentLocation(newLocation);
        onUpdate(templateHead, localSign, localNumber, localCompany, localFullname, localDob, localDeceasedDate, localInfo, localPrayerLocation, localPrayerDateTime, newLocation);
    };
    const handleNumber = (newNumber) => {
        setLocalNumber(newNumber);
        onUpdate(templateHead, localSign, newNumber, localCompany, localFullname, localDob, localDeceasedDate, localInfo, localPrayerLocation, localPrayerDateTime, localintermentLocation);
    };
    const handleSign = (newsign) => {
        setLocalsign(newsign);
        onUpdate(templateHead, newsign, localNumber, localCompany, localFullname, localDob, localDeceasedDate, localInfo, localPrayerLocation, localPrayerDateTime, localintermentLocation);
    };
    const handlecompany = (newCompany) => {
        setLocalCompany(newCompany);
        onUpdate(templateHead, localSign, localNumber, newCompany, localFullname, localDob, localDeceasedDate, localInfo, localPrayerLocation, localPrayerDateTime, localintermentLocation);
    };
    const formattedDob = localDob !== "Enter Date of Birth" ? moment(localDob).format('LL') : localDob;
    const formattedDeceasedDate = localDeceasedDate !== "Enter Date Of Death" ? moment(localDeceasedDate).format('LL') : localDeceasedDate;
    const formattedPrayerTime = localPrayerDateTime !== "Enter Prayer Date And Time" ? moment(localPrayerDateTime).format('dddd, MMMM D, YYYY [at] h:mm A') : localPrayerDateTime;

    return (
        <>
            <div className="my-1 ">
                <div className="pt-4 card-width temp-font position-relative " style={{ height: '630px', width: "486px", backgroundColor: 'white' }}>
                    <div className="text-center pb-3">
                        <p className="lh-1 fs-5 mb-0">{templateHead}</p>
                    </div>
                    <div className="text-center">
                        <p className="fw-bold mb-0 lh-1 fs-5 text-capitalize">{localFullname}</p>
                    </div>
                    <div className="w-100 d-flex flex-column align-items-center justify-content-center">
                        <p className="fw-normal mb-0 lh-1 fs-5">  {formattedDob} - {formattedDeceasedDate}</p>
                    </div>
                    <div className="text-center pt-3 mb-0">
                        <p className="fw-Normal mb-0 lh-1 fs-5">Prayer Service</p>
                    </div>
                    <div className="text-center d-flex gap-2 justify-content-center">
                        <p className="fw-Normal mb-0 lh-1 fs-5" >{localPrayerLocation}</p>
                        {isEditingPage && (
                            <FaPencilAlt
                                style={{ cursor: "pointer", marginTop: "-8px" }}
                                onClick={() => setShowPrayerLocationModal(true)}
                            />
                        )}
                    </div>
                    <div className="text-center mb-0 d-flex gap-2 justify-content-center">
                        <p className="fw-normal mb-0 lh-1 fs-5"> {formattedPrayerTime}</p>
                        {isEditingPage && (
                            <FaRegCalendarAlt
                                style={{ cursor: "pointer", marginTop: "-4px" }}
                                onClick={() => setShowDateTimeModal(true)}
                            />
                        )}
                    </div>
                    <div className="text-center pt-3 mb-0">
                        <p className="fw-Normal mb-0 lh-1 fs-5">Interment</p>
                    </div>
                    <div className="text-center d-flex justify-content-center gap-2">
                        <p className="fw-Normal mb-0 lh-1 fs-5">{localintermentLocation}</p>
                        {isEditingPage && (
                            <FaPencilAlt
                                style={{ cursor: "pointer", marginTop: "-8px" }}
                                onClick={() => setShowIntermentLocationModal(true)}
                            />
                        )}
                    </div>
                    <div className="text-center d-flex justify-content-center pt-3 gap-2">
                        <p className="memory-width lh-1 fs-6 mb-0" style={{ wordWrap: "break-word" }}>
                            {localInfo}
                        </p>
                        {isEditingPage && (
                            <FaPencilAlt
                                style={{ cursor: "pointer", marginTop: "-10px" }}
                                onClick={() => setShowModal(true)}
                            />
                        )}
                    </div>
                    <div className="d-flex justify-content-around my-2 align-items-center ms-auto me-auto" style={{ position: "absolute", bottom: "5px", left: 0, right: 0, width: "400px" }}>

                        <div>
                            <div className="text-center  mb-0 justify-content-center d-flex gap-2">
                                <p className="fw-Normal mb-0 fs-4 font-cali mb-0">{localSign}</p>
                                {isEditingPage && (
                                    <FaPencilAlt
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setShowSignModal(true)}
                                    />
                                )}
                            </div>
                            <div className="text-center justify-content-center d-flex gap-2">
                                <p className="fw-Normal mb-0 lh-1 fs-6 ">{localCompany}</p>
                                {isEditingPage && (
                                    <FaPencilAlt
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setShowCompanyModal(true)}
                                    />
                                )}
                            </div>
                            <div className="text-center justify-content-center d-flex gap-2">
                                <p className="fw-Normal mb-0 lh-1 fs-6">{localNumber}</p>
                                {isEditingPage && (
                                    <FaPencilAlt
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setShowNumberModal(true)}
                                    />
                                )}
                            </div>
                        </div>
                        <div className='d-flex flex-column align-items-center justify-content-center'>
                            <QRCode value={qrLink} size={110} level={"H"} includeMargin={true} className="qr-code-left" />
                            <p className='m-0 text-primary-emphasis lh-sm' style={{ fontSize: "0.7rem" }}>Visit Profile</p>
                        </div>
                    </div>
                </div>
            </div>
            <EditModal
                label="Prayer Location"
                limit={50}
                initialValue={localPrayerLocation}
                onSave={handlePrayerLocation}
                onHide={() => setShowPrayerLocationModal(false)}
                show={showPrayerLocationModal}
            />
            <EditModal
                label="Interment Location"
                limit={50}
                initialValue={localintermentLocation}
                onSave={handleIntermentLocation}
                onHide={() => setShowIntermentLocationModal(false)}
                show={showIntermentLocationModal}
            />
            <EditModal
                label="Sign"
                limit={20}
                initialValue={localSign}
                onSave={handleSign}
                onHide={() => setShowSignModal(false)}
                show={showSignModal}
            />
            <EditModal
                label="Company Name"
                limit={30}
                initialValue={localCompany}
                onSave={handlecompany}
                onHide={() => setShowCompanyModal(false)}
                show={showCompanyModal}
            />
            <EditModal
                label="Company number"
                limit={15}
                initialValue={localNumber}
                onSave={handleNumber}
                onHide={() => setShowNumberModal(false)}
                show={showNumberModal}
            />
            <EditInfoModal
                initialValue={info}
                limit={660}
                onSave={handleNameSave}
                onHide={() => setShowModal(false)} // Close modal on hide
                show={showModal} // Pass showModal state to control modal visibility
            />
            <EditPrayerTimeModal
                dop={localPrayerDateTime}
                onSave={handleDateTimeSave}
                onHide={() => setShowDateTimeModal(false)} // Close modal on hide
                show={showDateTimeModal} // Pass showModal state to control modal visibility
            />
        </>
    )
}

export default TemplateBack2
