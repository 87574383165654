import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { GetUserInfo } from '../../services/user/user.service';
import { useParams } from 'react-router-dom';
const ProtectedPageModal = ({ show, onClose }) => {
    const { id } = useParams();
    const [password, setPassword] = useState('');
    const [protect, setProtect] = useState('');
    const handleContinue = () => {
        if (protect === password) {
            handleClose(); // Close the modal
        }
        else {
            toast.error("Incorrect Password", {
                isLoading: false,
                autoClose: 5000,
                // theme: "dark",
                hideProgressBar: true,
                closeButton: false,
            });
        }
    };
    const fetchUser = async () => {
        try {
            const response = await GetUserInfo(id);
            setProtect(response.data.data.password)
        } catch (error) {
            if (error.response.data.code === 404) {
            }
        } finally {
            // setLoading(false);
        }
    };
    useEffect(() => {
        fetchUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleClose = () => {
        onClose(); // Call onClose function
    };
    return (
        <div>
            <div className="modal-blur"></div>
            <Modal show={show} centered backdrop="static">
                <Modal.Header className='justify-content-center align-items-center border-0'>
                    <Modal.Title className='sign-heading text-center pb-2'>This Profile is Password Protected</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formBasicPassword" className='pb-3'>
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Form.Group>
                        <Button variant="primary" type="button" onClick={handleContinue} className='w-100 login-button py-2'>
                            Enter
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ProtectedPageModal
