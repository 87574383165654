import React, { useState } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { SignUpUser, associateUsers } from '../services/siignup/signUp.service';
import { toast } from 'react-toastify';

const Signup = () => {
    const { id } = useParams();
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [nameError, setNameError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setconfirmPasswordError] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const navigate = useNavigate();
    const validateEmail = (email) => {
        // Email validation regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const associateUser = async () => {
        const toastId = toast.loading("Associating", { closeButton: true });
        try {
            await associateUsers(id);
            toast.update(toastId, {
                render: "User is Succesfully Associated",
                type: "success",
                isLoading: false,
                autoClose: 2000,
                hideProgressBar: true,
                closeButton: false,
            });
            navigate(`/${id}`)
        } catch (error) {
            toast.update(toastId, {
                render: error.response.data.error,
                type: "error",
                isLoading: false,
                autoClose: 2000,
                hideProgressBar: true,
                closeButton: false,
            });
            // setError(error.response.data.error);
        }

    };
    const validatePassword = (password) => {
        // Password validation regex
        return password.length >= 8 && password.length <= 20;
    };
    const validateConfirmPassword = (confirmPassword) => {
        // Password validation regex
        return password === confirmPassword;
    };
    const handleNameChange = (e) => {
        setName(e.target.value);
        setNameError('');
    };
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setEmailError('');
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setPasswordError('');
    };
    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        setconfirmPasswordError('');
    };
    const handleSignUp = async (e) => {
        e.preventDefault();
        // Reset previous error messages
        setNameError('');
        setEmailError('');
        setPasswordError('');
        setconfirmPasswordError('');

        let isValid = true;

        if (name.trim() === '') {
            setNameError('Please enter your full name.');
            isValid = false;
        }
        // Validate email
        if (!validateEmail(email)) {
            setEmailError('Please enter a valid email address.');
            isValid = false;
        }

        // Validate confirm password
        if (!validateConfirmPassword(confirmPassword)) {
            setconfirmPasswordError('Password and Confirm Password should Match.');
            isValid = false;
        }

        // Validate password
        if (!validatePassword(password)) {
            setPasswordError('Password must be between 8 and 20 characters.');
            isValid = false;
        }

        if (isValid) {
            try {
                const response = await SignUpUser({
                    fullname: name,
                    email: email,
                    password: password,
                });
                const user = response.data.data;
                localStorage.setItem("user", JSON.stringify(user));
                associateUser();
            } catch (error) {
                toast.error(error.response.data.error, {
                    isLoading: false,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeButton: false,
                });
            }
        }
    };


    return (
        <div className="login-bg d-flex justify-content-center align-items-center">
            <Container>
                <Row className="justify-content-center align-items-center">
                    <Col md={5}>
                        <Card className='card-shadow'>
                            <Card.Body>
                                <Card.Title className='sign-heading text-center pb-2'>Create Account</Card.Title>
                                <Form>
                                    <Form.Group controlId="formBasicText" className='pb-3'>
                                        <Form.Control type="text" placeholder="Full Name" value={name} onChange={handleNameChange} />
                                        <Form.Text className="text-danger">{nameError}</Form.Text>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicEmail" className='pb-3'>
                                        <Form.Control type="email" placeholder="Email" value={email} onChange={handleEmailChange} />
                                        <Form.Text className="text-danger">{emailError}</Form.Text>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicPassword" className='pb-3'>
                                        <Form.Control type="password" placeholder="Password" value={password} onChange={handlePasswordChange} />
                                        <Form.Text className="text-danger">{passwordError}</Form.Text>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicPassword" className='pb-3'>
                                        <Form.Control type="password" placeholder="Confirm Password" value={confirmPassword} onChange={handleConfirmPasswordChange} />
                                        <Form.Text className="text-danger">{confirmPasswordError}</Form.Text>
                                    </Form.Group>
                                    <Row className='justify-content-between pb-3'>
                                        <Col md={12}>
                                            <Form.Group controlId="formBasicCheckbox" className=''>
                                                <Form.Check type="checkbox" label="Accept all terms & Conditions" checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Button variant="primary" type="submit" onClick={handleSignUp} className='w-100 login-button py-2'>
                                        Create Account
                                    </Button>
                                    <div class="col-lg-12 new-user text-center mt-3">
                                        <p class="mb-0">Already have an account?
                                            <Link class="ps-1 text-decoration-none" to={`/login/${id}`}>Login</Link>
                                        </p>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Signup;
