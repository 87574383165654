import React, { useState } from 'react';
import { Container, Row, Col, Image, Button, Navbar, Nav } from 'react-bootstrap';
import memories from '../assests/memories.png';
import logo from '../assests/logo.png';
import { Link } from 'react-router-dom';
import { AuthVerify } from '../utils/auth.utils';

const backgroundStyle = {
  position: 'relative',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  //textShadow: '2px 2px 4px rgba(0,0,0,0)',
  //height: '100vh', 
  overflow: 'hidden',
};

const overlayStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 1,
};

const contentStyle = {
  position: 'relative',
  zIndex: 2,
};

const HeaderTerms = () => {
  const [expanded, setExpanded] = useState(false);
  const verify = AuthVerify();

  return (
    <>
      <Navbar expand="lg" className="primary-bg" expanded={expanded}>
        <Container className='gap-3 justify-content-between d-flex'>
          {/* <div className='px-3 mx-0  px-lg-5 mx-lg-5 '>
            <div className='ps-0 ps-lg-5'>

            </div>
          </div> */}
          <Navbar.Brand className="navbar-brand" >
            <Image src={logo} width="100%" height="100%" alt="" className='logo-img' />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")} />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className=" gap-3 ms-auto">
                {
                    verify?(
                        <Link to="/login" className="w-100">
                        <Button className='edit-btn w-100'>To Dashboard</Button>
                      </Link>
                    ):(
                        <>
                        <Link to="/login" className="w-100">
                        <Button className='edit-btn w-100'>Login</Button>
                      </Link>
                      <Link to="/" className="w-100">
                        <Button className='edit-btn w-100'>Home</Button>
                      </Link>
                      </>
                    )
                }
             
              {/* <Button className='edit-btn w-100' onClick={handleDemoClick}>Demo</Button> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* <nav className="navbar justify-content-between primary-bg">
        <Container className="d-flex justify-content-between align-items-center">
          <Link className="navbar-brand">
            <Image src={logo} width="100%" height="50" alt="" className='logo-img' />
          </Link>
          <div className="d-flex gap-3">
            <Link to="/login" className="w-100">
              <Button className='edit-btn w-100'>Login</Button>
            </Link>
            <Button className='edit-btn w-100' onClick={handleDemoClick}>Demo</Button>
          </div>
        </Container>
      </nav> */}
    </>
  );
};

export default HeaderTerms;
