import { GetApiData } from "../../utils/http-client";

export const getUser = function () {
    return GetApiData(`/user`, 'GET', true);
}


export const updateUserName = function (data) {
    return GetApiData(`/user`, 'PUT',data, true);
}
export const changeUserPassword = function (data) {
    return GetApiData(`/user/change-password`, 'POST',data, true);
}