import React from 'react'
import { Form, Image, Modal } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import hero from "../assests/Group 48096011.png"
const Congrats = ({ show, onClose }) => {
    const { id } = useParams();
    const handleClose = () => {
        if (onClose) onClose();
    };

    const handleContinue = () => {
        handleClose();
    };
    return (
        <>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header className='justify-content-center align-items-center border-0'>
                    <Modal.Title className='sign-heading text-center pb-2'>
                        <div>
                            <Image className="w-100" src={hero} alt="Congrats" />
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Modal.Title className='sign-heading text-center pb-5'>Congratulations! You’ve
                            successfully reset password</Modal.Title>
                    </div>
                    <Form>
                        <Link variant="primary" type="button" onClick={handleContinue} className='w-100 login-button py-2 text-decoration-none' to={`/login/${id}`}>
                            Login Now
                        </Link>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Congrats
