import { GetApiData } from "../../utils/http-client";

export const GetTributes = function (id) {
    return GetApiData(`/tribute/${id}`, 'GET', null, true);
}
export const UpdateTribute = function (id, data) {
    return GetApiData(`/tribute/${id}`, 'POST', data, null, true);
}
export const DeleteTribute = function (id) {
    return GetApiData(`/tribute/${id}`, 'DELETE', null, true);
}