import React, { useState, useEffect } from 'react';
import { Button, Image, Modal } from 'react-bootstrap';
import upload from "../../assests/profile-upload.png"
import profile from "../../assests/smallupload.png";
import { FaCheck, FaPencilAlt, FaRegCalendarAlt, FaTimes } from 'react-icons/fa'
import EditDateModal from './EditDateModal';
import { toast } from 'react-toastify';
import moment from 'moment'; // Import moment.js
import EditModal from './EditModal';
import { uploadUserFile } from '../../services/user/user.service';
import Cropper from "react-easy-crop";
import { useCallback } from 'react';
import getCroppedImg from '../ImageCrop/CropImage';
const TemplateFront3 = ({ isEditingPage, templateHead, prayerHead, prayer, profilename, dob, deceasedDate, profileImage, memorialLocation, onUpdate, grace }) => {
    const [localFullname, setLocalFullname] = useState(profilename);
    const [localDob, setLocalDob] = useState(dob);
    const [localGrace, setLocalGrace] = useState(grace);
    const [show, setShow] = useState(false);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedArea, setCroppedArea] = useState(null);
    const [showTemplateHeadModal, setShowTemplateHeadModal] = useState(false);
    const [localTemplateHead, setLocalTemplateHead] = useState(templateHead);
    const [localmemorial, setLocalMemorial] = useState(memorialLocation);
    const [localProfileImageCrop, setLocalProfileImageCrop] = useState(profileImage);
    const [localDeceasedDate, setLocalDeceasedDate] = useState(deceasedDate);
    const [localProfileImage, setLocalProfileImage] = useState(profileImage);
    const [showModal, setShowModal] = useState(false);
    const [showMemorialLoationModal, setShowMemorialLoationModal] = useState(false);
    const [showGraceModal, setShowGraceModal] = useState(false);
    const [showDateModal, setShowDateModal] = useState(false);
    // const [memorialLoation, setMemorialLoation] = useState('Enter Memorial Day Location');

    useEffect(() => {
        setLocalFullname(profilename);
        setLocalDob(dob);
        setLocalDeceasedDate(deceasedDate);
        setLocalProfileImage(profileImage);
        setLocalMemorial(memorialLocation);
        setLocalProfileImageCrop(profileImage);
        setLocalGrace(grace)
    }, [profilename, dob, deceasedDate, profileImage, memorialLocation, grace]);

    const handleDateSave = (newDOB, newDOD) => {
        setLocalDob(newDOB);
        setLocalDeceasedDate(newDOD);
        onUpdate(localTemplateHead, prayerHead, prayer, localFullname, newDOB, newDOD, localProfileImage, localmemorial, localGrace);
    };
    const handleMemorialSave = (newValue) => {
        setLocalMemorial(newValue)
        // onUpdate(localFullname, localDob, localDob, localProfileImage, newValue);
        onUpdate(localTemplateHead, prayerHead, prayer, localFullname, localDob, localDeceasedDate, localProfileImage, newValue, localGrace);
    };
    const handleTemplateHead = (newTemplateHead) => {
        setLocalTemplateHead(newTemplateHead);
        onUpdate(newTemplateHead, prayerHead, prayer, localFullname, localDob, localDeceasedDate, localProfileImage, localmemorial, localGrace);
        // onUpdate(newTemplateHead, prayerHead, prayer, localFullname, localDob, localDob, localProfileImage, localmemorial);
    };
    const handleGrace = (newGrace) => {
        setLocalGrace(newGrace);
        onUpdate(localTemplateHead, prayerHead, prayer, localFullname, localDob, localDeceasedDate, localProfileImage, localmemorial, newGrace);
        // onUpdate(newTemplateHead, prayerHead, prayer, localFullname, localDob, localDob, localProfileImage, localmemorial);
    };
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedArea(croppedAreaPixels);
    }, []);

    const handleCropSave = async () => {
        const toastId = toast.loading("Saving cropped image", { closeButton: true });
        try {
            const croppedImageBlob = await getCroppedImg(localProfileImage, croppedArea);
            // Convert Blob to File
            const croppedImageFile = new File([croppedImageBlob], "cropped.jpg", {
                type: "image/jpeg",
            });
            // Create form data
            const formData = new FormData();
            formData.append('attachments', croppedImageFile);

            // Upload cropped image to Cloudinary
            const response = await uploadUserFile(formData);
            setLocalProfileImage(response.data.data[0]);
            onUpdate(localFullname, localDob, localDeceasedDate, response.data.data[0]);
            toast.update(toastId, {
                render: "Image updated successfully",
                type: "success",
                isLoading: false,
                autoClose: 2000,
                hideProgressBar: true,
                closeButton: false,
            });
            setShow(false);
        } catch (e) {
            toast.update(toastId, {
                render: "Error saving cropped image",
                type: "error",
                isLoading: false,
                autoClose: 2000,
                hideProgressBar: true,
                closeButton: false,
            });
            console.error(e);
        }
    };
    const handleImageUpload = async (e) => {
        e.preventDefault();
        const toastId = toast.loading("Uploading Image", { closeButton: true });
        const file = e.target.files[0];
        e.target.value = null;
        if (file) {
            try {
                const formData = new FormData();
                formData.append('attachments', file);
                const response = await uploadUserFile(formData);
                setLocalProfileImage(response.data.data[0]);
                toast.update(toastId, {
                    render: "Image Uploaded. Please crop the image.",
                    type: "success",
                    isLoading: false,
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeButton: false,
                });
                handleShowImage(); // Show the cropping modal
            } catch (error) {
                toast.update(toastId, {
                    render: error.response.data.data,
                    type: "error",
                    isLoading: false,
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeButton: false,
                });
            }
        }
    };
    const handleCloseImage = () => {
        setShow(false);
        setLocalProfileImage(localProfileImageCrop);
    };
    const handleShowImage = () => setShow(true);
    const formattedDob = localDob !== "Enter Date of Birth" ? moment(localDob).format('LL') : localDob;
    const formattedDeceasedDate = localDeceasedDate !== "Enter Date Of Death" ? moment(localDeceasedDate).format('LL') : localDeceasedDate;

    return (
        <div className="my-1">
            <div className='main-background-image bg-card-temp ' style={{ width: 'fit-content' }}>
                <div className="d-flex align-items-center justify-content-center card-width-2 px-4 temp-font" style={{ height: '775px', width: "486px" }}>
                    <div className="d-flex align-items-center justify-content-center card-width-2 px-4 temp-font">
                        <div className="row align-items-center justify-content-center">
                            <div className="">
                                <div className="text-center pb-4 justify-content-center d-flex gap-2">
                                    <p className="lh-base fs-3 mb-0" style={{ width: "15rem" }}>{localTemplateHead}</p>
                                    {isEditingPage && (
                                        <FaPencilAlt
                                            style={{ cursor: "pointer" }}
                                            onClick={() => setShowTemplateHeadModal(true)}
                                        />
                                    )}
                                </div>
                                <div className="d-flex flex-column align-items-center">
                                    <div className="d-flex align-items-center justify-content-center pt-2" style={{ border: 'unset', width: "unset" }}>
                                        <div className=''>
                                            <Image
                                                src={localProfileImage || profile}
                                                alt="user"
                                                style={{ width: "184px" }}
                                                className="rounded-circle"
                                            />
                                        </div>
                                    </div>
                                    {isEditingPage && (
                                        <div className="" style={{ marginTop: "-42px", marginRight: "-94px", width: "42px", height: "42px" }}>
                                            <label htmlFor="dpImageInput" className="">
                                                <div className="upload-icon">
                                                    <Image
                                                        src={upload}
                                                        className="w-100 h-100"
                                                        alt="upload-icon"
                                                    />
                                                </div>
                                                <input
                                                    id="dpImageInput"
                                                    type="file"
                                                    accept="image/jpeg, image/png"
                                                    style={{ display: "none" }}
                                                    onChange={handleImageUpload}
                                                />
                                            </label>
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <div className="text-center justify-content-center d-flex gap-2 pt-4">
                                        <p className="fw-Bolder mb-0 lh-1 fs-3 text-capitalize">{localFullname}</p>
                                        {isEditingPage && (
                                            <FaPencilAlt
                                                style={{ cursor: "pointer", marginTop: "-8px" }}
                                                onClick={() => setShowModal(true)}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="w-100 d-flex align-items-center justify-content-center pt-4 gap-2">
                                    <p className="fw-normal mb-0 lh-1 fs-6">{formattedDob} - {formattedDeceasedDate}</p>
                                    {isEditingPage && (
                                        <FaRegCalendarAlt
                                            style={{ cursor: "pointer" }}
                                            onClick={() => setShowDateModal(true)}
                                        />
                                    )}
                                </div>
                                <div className="text-center pt-3 d-flex justify-content-center gap-1">
                                    <p className="lh-1 fs-5 mb-0 text-uppercase">{localGrace}</p>
                                    {isEditingPage && (
                                        <FaPencilAlt
                                            style={{ cursor: "pointer", marginTop: "-8px" }}
                                            onClick={() => setShowGraceModal(true)}
                                        />
                                    )}
                                </div>
                                <div className="text-center pt-3 mb-0">
                                    <p className="fw-Normal mb-0 lh-1 fs-5">Memorial Day Party</p>
                                </div>
                                <div className="text-center d-flex justify-content-center gap-1">
                                    <p className="fw-Normal mb-0 lh-1 fs-5" >{localmemorial}</p>
                                    {isEditingPage && (
                                        <FaPencilAlt
                                            style={{ cursor: "pointer", marginTop: "-8px" }}
                                            onClick={() => setShowMemorialLoationModal(true)}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Edit Modal */}
                    <EditModal
                        label="Name"
                        limit={80}
                        initialValue={localFullname}
                        onSave={(newValue) => setLocalFullname(newValue)}
                        onHide={() => setShowModal(false)}
                        show={showModal}
                    />
                    <EditModal
                        label="Memorial Location"
                        limit={100}
                        initialValue={localmemorial}
                        onSave={handleMemorialSave}
                        onHide={() => setShowMemorialLoationModal(false)}
                        show={showMemorialLoationModal}
                    />
                    <EditModal
                        label="Template Head"
                        limit={36}
                        initialValue={localTemplateHead}
                        onSave={handleTemplateHead}
                        onHide={() => setShowTemplateHeadModal(false)}
                        show={showTemplateHeadModal}
                    />
                    <EditModal
                        label="Prayer"
                        limit={36}
                        initialValue={localGrace}
                        onSave={handleGrace}
                        onHide={() => setShowGraceModal(false)}
                        show={showGraceModal}
                    />
                    <EditDateModal
                        dob={localDob}
                        dod={localDeceasedDate}
                        onSave={handleDateSave}
                        onHide={() => setShowDateModal(false)} // Close modal on hide
                        show={showDateModal} // Pass showModal state to control modal visibility
                    />
                    <Modal show={show} onHide={handleCloseImage} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Profile Image</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="d-flex align-items-center justify-content-center modal-crop">
                                <Cropper
                                    image={localProfileImage}
                                    crop={crop}
                                    zoom={zoom}
                                    aspect={1}
                                    onCropChange={setCrop}
                                    onZoomChange={setZoom}
                                    onCropComplete={onCropComplete}
                                />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button className="edit-btn border d-flex align-items-center justify-content-center gap-1 fw-bolds" onClick={handleCloseImage} style={{ color: 'black', backgroundColor: 'transparent', borderColor: '#dee2e6' }}>
                                <FaTimes size={15} color="black" />
                                CANCEL
                            </Button>
                            <Button className="edit-btn border d-flex align-items-center justify-content-center gap-2 fw-bold" onClick={handleCropSave}>
                                <FaCheck size={13} color="white" />
                                Crop & Save
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </div>
    );
};

export default TemplateFront3;
