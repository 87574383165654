import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ForgetPassword from './ForgetPassword';
import { AuthVerify } from '../utils/auth.utils';
import { LoginUser } from '../services/login/login.service';
import { toast } from 'react-toastify';
import { associateUsers } from '../services/siignup/signUp.service';
// import { associateUsers } from '../services/siignup/signUp.service';

const Login = () => {
    const { id } = useParams();
    const verify = AuthVerify();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [rememberMe, setRememberMe] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [showForgetPasswordModal, setShowForgetPasswordModal] = useState(false);
    const navigate = useNavigate();

    const validateEmail = (email) => {
        // Email validation regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validatePassword = (password) => {
        // Password validation regex
        return password.length >= 8 && password.length <= 20;
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setEmailError('');
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setPasswordError('');
    };
    const associateUser = async () => {
        const toastId = toast.loading("Associating", { closeButton: true });
        try {
            // await associateUsers(id);
            toast.update(toastId, {
                render: "User is associated with this Profile",
                type: "success",
                isLoading: false,
                autoClose: 2000,
                hideProgressBar: true,
                closeButton: false,
            });
            navigate(`/${id}`)
        } catch (error) {
            toast.update(toastId, {
                render: error.response.data.error,
                type: "error",
                isLoading: false,
                autoClose: 3000,
                hideProgressBar: true,
                closeButton: false,
            });
            // setError(error.response.data.error);
        }

    };
    const handleLogin = async (e) => {
        e.preventDefault();
        // Reset previous error messages
        setEmailError('');
        setPasswordError('');
        let isValid = true;
        // Validate email
        if (!validateEmail(email)) {
            setEmailError('Please enter a valid email address.');
            isValid = false;
        }
        // Validate password
        if (!validatePassword(password)) {
            setPasswordError('Password must be between 8 and 20 characters.');
            isValid = false;
        }
        if (isValid) {
            try {
                const response = await LoginUser({
                    email: email,
                    password: password,
                });
                const user = response.data.data;
                console.log("ProfileId user", user)
                localStorage.setItem("user", JSON.stringify(user));
                if (id) {
                    associateUser();
                    // navigate(`/${id}`)
                }
                else {
                    navigate(`/${user.profileId}?dashboard=true`)
                }
            } catch (error) {
                toast.error(error.response.data.error, {
                    isLoading: false,
                    autoClose: 5000,
                    // theme: "dark",
                    hideProgressBar: true,
                    closeButton: false,
                });
                // setError(error.response.data.error);
            }
        }

    };

    const handleForgotPassword = () => {
        setShowForgetPasswordModal(true);
    };

    const handleCloseForgetPasswordModal = () => {
        setShowForgetPasswordModal(false);
    };

    useEffect(() => {
        const Profile = JSON.parse(localStorage.getItem('user'));
        const profileId = Profile?.profileId
        if (verify) {
            // let loggedInUser = JSON.parse(localStorage.getItem('user'));
            // if (loggedInUser) {
            if (id) {

                navigate(`/${id}`)
            } else if (profileId) {
                navigate(`/${profileId}`)

            }
            // }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate]);

    return (
        <div className="login-bg d-flex justify-content-center align-items-center">
            <Container>
                <Row className="justify-content-center align-items-center">
                    <Col md={5}>
                        <Card className='card-shadow'>
                            <Card.Body>
                                <Card.Title className='sign-heading text-center pb-2'>Sign In</Card.Title>
                                <Form>
                                    <Form.Group controlId="formBasicEmail" className='pb-3'>
                                        <Form.Control type="email" placeholder="Email" value={email} onChange={handleEmailChange} />
                                        <Form.Text className="text-danger">{emailError}</Form.Text>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicPassword" className='pb-3'>
                                        <Form.Control type="password" placeholder="Password" value={password} onChange={handlePasswordChange} />
                                        <Form.Text className="text-danger">{passwordError}</Form.Text>
                                    </Form.Group>
                                    <Row className='justify-content-between pb-3'>
                                        <Col md={6}>
                                            <Form.Group controlId="formBasicCheckbox" className=''>
                                                <Form.Check type="checkbox" label="Remember me" checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6} className='text-end'>
                                            <Link variant="link" onClick={handleForgotPassword} className='p-0 '>
                                                Forgot Password?
                                            </Link>
                                        </Col>
                                    </Row>
                                    <Button variant="primary" type="submit" onClick={handleLogin} className='w-100 login-button py-2'>
                                        Login
                                    </Button>
                                    {
                                        id && (
                                            <div className="col-lg-12 new-user text-center mt-3">
                                            <p className="mb-0">Don't have an account?
                                                <Link className="ps-1 text-decoration-none" to={`/sign-up/${id}`}>Register</Link>
                                            </p>
                                        </div>
                                        )
                                    }
                                   
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {/* Render ForgetPassword modal */}
            <ForgetPassword show={showForgetPasswordModal} onClose={handleCloseForgetPasswordModal} />
        </div>
    );
};

export default Login;
