import React, { useEffect, useState } from "react";
import { FaPencilAlt } from "react-icons/fa";
import { Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";

const AboutEdit = ({
  bio,
  bioBorn,
  bioDied,
  bioOccupation,
  bioCountry,
  bioCity,
  bioAddress,
  onSave,
  isEditingPage,
}) => {
  const CHAR_LIMIT = 1000;

  const [value, setValue] = useState(bio);
  const [editedValue, setEditedValue] = useState(bio);
  const [details, setDetails] = useState({
    born: bioBorn,
    died: bioDied,
    occupation: bioOccupation,
    country: bioCountry,
    city: bioCity,
    address: bioAddress,
  });

  const [isEditing, setIsEditing] = useState(false);
  const [showFullBio, setShowFullBio] = useState(false);

  const handleEdit = () => {
    setEditedValue(value);
    setIsEditing(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "editedValue") {
      if (value.length <= CHAR_LIMIT) {
        setEditedValue(value);
      }
    } else {
      setDetails({
        ...details,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    setValue(bio);
    setDetails({
      born: bioBorn,
      died: bioDied,
      occupation: bioOccupation,
      country: bioCountry,
      city: bioCity,
      address: bioAddress,
    });
  }, [bio, bioBorn, bioDied, bioOccupation, bioCountry, bioCity, bioAddress]);

  const handleSave = () => {
    const toastId = toast.loading("Updating", { closeButton: true });
    toast.update(toastId, {
      render: "About is Successfully Updated",
      type: "success",
      isLoading: false,
      autoClose: 2000,
      hideProgressBar: true,
      closeButton: false,
    });
    onSave(editedValue, details);
    setIsEditing(false);
    setValue(editedValue);
  };

  const handleClose = () => {
    setIsEditing(false);
  };

  const toggleShowFullBio = () => {
    setShowFullBio(!showFullBio);
  };

  const truncatedBio =
    value.length > 200 ? `${value.substring(0, 200)}...` : value;

  return (
    <div>
      <div className="d-flex gap-3 align-items-center">
        <h4 className="pt-2 about-text">Biography: </h4>
        {isEditingPage && (
          <FaPencilAlt onClick={handleEdit} style={{ cursor: "pointer" }} />
        )}
      </div>
      <div className="pt-2">
        {!value &&
          !details.occupation &&
          !details.country &&
          !details.city &&
          !details.address && (
            <p className="mb-1 about-section">
              Nothing to show 
            </p>
          )}

        <p className="about-section" style={{ wordWrap: "break-word" }}>
          {showFullBio ? value : truncatedBio}{" "}
          {value.length > 200 && !showFullBio && (
            <span
              className="p-0 link-see primary-color"
              onClick={toggleShowFullBio}
            >
              See More...
            </span>
          )}
        </p>
        <div>
          {details.occupation && (
            <p className="mb-1 about-section" style={{ wordWrap: "break-word" }}>
              <strong className="primary-color">Occupation: </strong>
              {details.occupation}
            </p>
          )}
          {details.country && (
            <p className="mb-1 about-section" style={{ wordWrap: "break-word" }}>
              <strong className="primary-color">Country: </strong>
              {details.country}
            </p>
          )}
          {details.city && (
            <p className="mb-1 about-section" style={{ wordWrap: "break-word" }}>
              <strong className="primary-color">City: </strong>
              {details.city}
            </p>
          )}
          {details.address && (
            <p className="mb-1 about-section" style={{ wordWrap: "break-word" }}>
              <strong className="primary-color">Address: </strong>
              {details.address}
            </p>
          )}
        </div>
      </div>
      <Modal show={isEditing} onHide={handleClose} centered>
        <Modal.Header className="justify-content-center align-items-center border-0">
          <Modal.Title className="sign-heading text-center pb-2">
            Edit About
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formBio" className="pb-3">
              <Form.Label className="pass-input">About</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="editedValue"
                value={editedValue}
                placeholder="Enter Bio"
                onChange={handleChange}
              />
              <Form.Text className="text-muted">
                {editedValue.length}/{CHAR_LIMIT} characters
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="formOccupation" className="pb-3">
              <Form.Label className="pass-input">Occupation</Form.Label>
              <Form.Control
                type="text"
                name="occupation"
                value={details.occupation}
                placeholder="Enter Occupation"
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formCountry" className="pb-3">
              <Form.Label className="pass-input">Country</Form.Label>
              <Form.Control
                type="text"
                name="country"
                placeholder="Enter Country"
                value={details.country}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formCity" className="pb-3">
              <Form.Label className="pass-input">City</Form.Label>
              <Form.Control
                type="text"
                name="city"
                placeholder="Enter City"
                value={details.city}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group controlId="formAddress" className="pb-3">
              <Form.Label className="pass-input">Address</Form.Label>
              <Form.Control
                type="text"
                name="address"
                placeholder="Enter Address"
                value={details.address}
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="button" className="login-button" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AboutEdit;
