import React from "react";
import { Routes, Route } from "react-router-dom";
import MainPage from "./components/MainPage";
import Login from "./components/Login";
import ForgetPassword from "./components/ForgetPassword";
import Signup from "./components/Signup";
// import { AuthVerify } from "./utils/auth.utils";
import NotFound from "./components/NotFound";
import Prayercard from "./components/Prayercard";
import ArAnimation from "./components/ArAnimation";
import ArViewer from "./components/ArViewer";
import LandingPage from "./landigPage/LandingPage";
import Terms from "./components/Terms";

const Routesof = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/:id" element={<MainPage />} />
                <Route path="/prayer-card/:id" element={<Prayercard />} />
                <Route path="/AR/:id" element={<ArAnimation />} />
                <Route path="/sign-up/:id" element={<Signup />} />
                <Route path="/login" element={<Login />} />
                <Route path="/login/:id" element={<Login />} />
                <Route path="/forgot-password/:id" element={<ForgetPassword />} />
                <Route path="/ARView/:id" element={<ArViewer />} />
                <Route path="/terms-and-conditions" element={<Terms/>} />
                {/* <Route path="/ARPage" element={<ArPage/>} /> */}
                {/* <Route path="/target" element={<TargetCompiler/>} /> */}
                <Route path="*" element={<NotFound />} />
            </Routes>
        </>
    );
};

export default Routesof;