import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

const SlideshowCard = ({ title, description, imageSrc }) => {
  return (
    <Col lg={12} md={12} className="mb-4">
      <Card className="text-center card-transparent justify-content-between align-items-center pt-3 px-5" style={{ minHeight: '280px',borderRadius:"40px" }}>
        <Row className='align-items-center justify-content-center'>
          <Col sm={12} lg={4} className=''>
            <Card.Img 
              variant="top" 
              className='w-100 w-lg-50' 
              src={imageSrc} 
              style={{ height: "300px", margin: 'auto', padding: '15px',objectFit:"contain" }} 
            />
          </Col>
          <Col sm={12} lg={8}>
            <Card.Body className='text-start feature-body'>
              <Card.Title className='feature-title'>{title}</Card.Title>
              <Card.Text>{description}</Card.Text>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

export default SlideshowCard;
