import React, { useState, useEffect } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { uploadUserFile } from "../../services/user/user.service";
import { toast } from "react-toastify";

const Artemplate1 = ({
  isEditingPage,
  video1,
  video2,
  video3,
  videoType1,
  videoType2,
  videoType3,
  heading,
  onUpdate,
  setIsUploading,
}) => {
  console.log("video1", video1);
  const [media, setMedia] = useState({
    media1: video1,
    media2: video2,
    media3: video3,
    mediaType1: videoType1,
    mediaType2: videoType2,
    mediaType3: videoType3,
  });
  const [urls, setUrls] = useState({ url1: "", url2: "", url3: "" });
  const [fileInputs, setFileInputs] = useState({
    file1: null,
    file2: null,
    file3: null,
  });

  const handleFileChange = async (index, file) => {
    setIsUploading(true);
    if (!file) {
      console.log("no file");
      const newMedia = { ...media, [`media${index}`]: "" };
      const newFileInputs = { ...fileInputs, [`file${index}`]: null };
      setMedia(newMedia);
      setFileInputs(newFileInputs);
      onUpdate(newMedia);
      return;
    }

    const formData = new FormData();
    formData.append("attachments", file);
    const toastId = toast.loading("Uploading file", { closeButton: true });
    try {
      const response = await uploadUserFile(formData);
      const mediaUrl = response.data.data[0];
      // const mediaUrl = await uploadUserFile(formData);
      const newMedia = {
        ...media,
        [`media${index}`]: mediaUrl,
        [`mediaType${index}`]: file.type,
      };
      const newFileInputs = { ...fileInputs, [`file${index}`]: file };
      setMedia(newMedia);
      setFileInputs(newFileInputs);
      setUrls({ ...urls, [`url${index}`]: "" }); // Clear URL input if a file is chosen
      onUpdate(newMedia);
      toast.update(toastId, {
        render: "file Uploaded successfully",
        type: "success",
        isLoading: false,
        autoClose: 2000,
        hideProgressBar: true,
        closeButton: false,
      });
    } catch (error) {
      toast.update(toastId, {
        render: error.response.data.data,
        type: "error",
        isLoading: false,
        autoClose: 2000,
        hideProgressBar: true,
        closeButton: false,
      });
      console.error("Error uploading file:", error);
    } finally {
      setIsUploading(false);
    }
  };

  const handleUrlChange = (index, url) => {
    if (url) {
      const newMedia = {
        ...media,
        [`media${index}`]: url,
        [`mediaType${index}`]: "url",
      };
      const newUrls = { ...urls, [`url${index}`]: url };
      const newFileInputs = { ...fileInputs, [`file${index}`]: null }; // Clear file input if a URL is entered
      setMedia(newMedia);
      setUrls(newUrls);
      setFileInputs(newFileInputs);
      onUpdate(newMedia);
      if (!url.includes("youtube.com") && !url.includes("youtu.be")) {
        toast.error("Only YouTube URLs are supported.");
        return;
      }
    } else {
      const newMedia = {
        ...media,
        [`media${index}`]: "",
        [`mediaType${index}`]: "",
      };
      const newUrls = { ...urls, [`url${index}`]: "" };
      const newFileInputs = { ...fileInputs, [`file${index}`]: null };
      setMedia(newMedia);
      setUrls(newUrls);
      setFileInputs(newFileInputs);
      onUpdate(newMedia);
    }
  };

  const getYoutubeEmbedUrl = (url) => {
    const urlObj = new URL(url);
    const videoId = urlObj.searchParams.get("v");
    if (videoId) {
      return `https://www.youtube.com/embed/${videoId}?controls=1`;
    }
    const pathname = urlObj.pathname;
    if (pathname) {
      console.log(
        "embed",
        `https://www.youtube.com/embed${pathname}?controls=1`
      );
      return `https://www.youtube.com/embed${pathname}?controls=1`;
    }
    return url;
  };

  useEffect(() => {
    // Enable URL input when file input is cleared
    [1, 2, 3].forEach((i) => {
      if (fileInputs[`file${i}`] === null) {
        const newUrls = { ...urls, [`url${i}`]: urls[`url${i}`] };
        setUrls(newUrls);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileInputs]);

  return (
    <div>
      <div>
        <p className="about-text">{heading}</p>
      </div>
      {[1, 2, 3].map((i) => (
        <div key={i} className="mb-3">
          <Form.Label className="about-text">Media {i}:</Form.Label>
          <OverlayTrigger
            overlay={
              urls[`url${i}`] ? (
                <Tooltip id={`tooltip-file${i}`}>
                  You already entered a URL. Clear the URL input to enable this
                  field.
                </Tooltip>
              ) : (
                <Tooltip id={`tooltip-file${i}`} className="d-none" />
              )
            }
          >
            <div>
              <Form.Control
                type="file"
                accept="video/*,image/*"
                onChange={(e) => handleFileChange(i, e.target.files[0])}
                disabled={!!urls[`url${i}`]}
                className="mb-2"
              />
            </div>
          </OverlayTrigger>
          <OverlayTrigger
            overlay={
              fileInputs[`file${i}`] ? (
                <Tooltip id={`tooltip-url${i}`}>
                  You already selected a file. Clear the file input to enable
                  this field.
                </Tooltip>
              ) : (
                <Tooltip id={`tooltip-url${i}`} className="d-none" />
              )
            }
          >
            <div>
              <Form.Control
                type="url"
                placeholder="Or enter media URL"
                onChange={(e) => handleUrlChange(i, e.target.value)}
                value={urls[`url${i}`]}
                disabled={!!fileInputs[`file${i}`]}
                className="mb-2"
                style={{ display: "none" }}
              />
            </div>
          </OverlayTrigger>
          {urls[`url${i}`] && (
            <small className="primary-color">URL provided for media {i}</small>
          )}
          <div className="video-placeholder" style={{ marginTop: "10px" }}>
            {media[`media${i}`] &&
              (urls[`url${i}`].includes("youtube.com") ||
              urls[`url${i}`].includes("youtu.be") ? (
                <iframe
                  title={`Video ${i}`}
                  width="100%"
                  height="500"
                  src={getYoutubeEmbedUrl(media[`media${i}`])}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              ) : media[`mediaType${i}`] &&
                media[`mediaType${i}`].startsWith("image") ? (
                <img
                  src={media[`media${i}`]}
                  alt={`Media ${i}`}
                  style={{ width: "100%", height: "500px", objectFit: "cover" }}
                />
              ) : media[`media${i}`].match(/\.(jpeg|jpg|gif|png)$/i) ? (
                <img
                  src={media[`media${i}`]}
                  alt={`Media ${i}`}
                  style={{ width: "100%", height: "500px", objectFit: "cover" }}
                />
              ) : (
                <video
                  controls
                  src={media[`media${i}`]}
                  style={{ width: "100%", height: "500px", objectFit: "cover" }}
                />
              ))}
            {!media[`media${i}`] && (
              <div
                style={{
                  width: "100%",
                  height: "500px",
                  backgroundColor: "#e9ecef",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span className="primary-color">No media provided</span>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Artemplate1;
