import { GetApiData } from "../../utils/http-client";

// export const GetPrayerCard = function (id) {
//     return GetApiData(`/prayer-card/${id}`, 'GET', null, true);
// }
export const UpdateAR = function (id, data) {
    return GetApiData(`/AR/${id}`, 'POST', data, null, true);
}

export const uploadARFiles = function (data) {
    return GetApiData(`/upload/ar`, 'POST', data, null, true);
}