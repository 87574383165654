import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import time from '../assests/Degital1.png'
import book from '../assests/secure1.png'
import gift from '../assests/future1.png'
const Service = () => {
    return (
        <> 
            <section id="services" className="py-5"  style={{backgroundColor:'#e9e7e9'}} >
                <Container>
                    <h2 className="text-center mb-4 pb-4">Our Services</h2>
                    <Row className='pt-2 d-flex align-items-stretch gap-2 gap-md-0 justify-content-center'  >
                        <Col lg={4} md={6} className="mb-3">
                            <Card className="text-center card-transparent justify-content-center align-items-center pt-3" style={{ minHeight: '350px' }}>
                                <Card.Img variant="top" src={time} style={{ width: '25%', margin: 'auto', padding: '10px' }} />
                                <Card.Body>
                                    <h4 className="mt-1">Digital Time Capsules</h4>
                                    <p>Capsule of Memories leverages cutting-edge technology to create digital time capsules, where users can store photos, videos, messages, and other significant memorabilia.</p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4} md={6} className="mb-3">
                            <Card className="text-center card-transparent justify-content-center align-items-center pt-3" style={{ minHeight: '350px' }}>
                                <Card.Img variant="top" src={book} style={{ width: '23%', margin: 'auto', padding: '10px' }} />
                                <Card.Body>
                                    <h4 className="mt-3">Intuitive and Secure Platform</h4>
                                    <p>Our platform offers an intuitive and secure way to compile and organize your memories, ensuring they are preserved for future generations.</p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4} md={6} className="mb-3">
                            <Card className="text-center card-transparent justify-content-center align-items-center pt-3" style={{ minHeight: '350px' }}>
                                <Card.Img variant="top" src={gift} style={{ width: '25%', margin: 'auto', padding: '10px' }} />
                                <Card.Body>
                                    <h4 className="mt-1" >Preservation for Future Generations</h4>
                                    <p>Ensure your precious moments remain vivid and accessible forever with our innovative approach to memory preservation.
                                    </p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>

        </>
    )
}

export default Service
