import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

const EditInfoModal = ({ initialValue, onSave, onHide, show,limit=660 }) => {
    const countCharacters = (text) => {
        return text?.trim().length;
    };

    const [value, setValue] = useState(initialValue);
    const [characterCount, setCharacterCount] = useState(countCharacters(initialValue));

    const handleSave = () => {
        const toastId = toast.loading("Updating", { closeButton: true });
        toast.update(toastId, {
            render: "Info is Successfully Updated",
            type: "success",
            isLoading: false,
            autoClose: 2000,
            hideProgressBar: true,
            closeButton: false,
        });
        onSave(value);
        onHide();
    };

    const handleChange = (e) => {
        const text = e.target.value;
        const characters = countCharacters(text);
        if (characters <= limit) {
            setValue(text);
            setCharacterCount(characters);
        }
    };

    return (
        <Modal show={show} onHide={onHide} backdrop="static" keyboard={false}>
            <Modal.Header className='justify-content-center align-items-center border-0'>
                <Modal.Title className='sign-heading text-center pb-2'>Edit About</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formBasicPassword" className='pb-3'>
                        <Form.Label className='pass-input'>About</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={5}
                            placeholder="Name"
                            value={value}
                            onChange={handleChange}
                        />
                        <div className="text-end mt-2">
                            {characterCount}/{limit} characters
                        </div>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
                <Button type="button" className='login-button' onClick={handleSave}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditInfoModal;
