import React, { useState, useEffect } from 'react';
import pic1 from "../../assests/anonymous-avatar-icon-25.png";
import { Button, Col, Form, Image, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {updateUserName, changeUserPassword, getUser } from '../../services/Dashboard/settings.service';
import { uploadUserFile } from '../../services/user/user.service';

const Settings = () => {
  const [userInfo, setUserInfo] = useState({ email: '', fullName: '', profileImage: pic1 });
  const [loadingName, setLoadingName] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [loadingPassword, setLoadingPassword] = useState(false);
  const [fullName, setFullName] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => {
    // Fetch user info on component mount
    const fetchUserInfo = async () => {
      try {
        const response = await getUser();
        console.log("response",response.data);
        setUserInfo(response.data.data);
        setFullName(response.data.data.fullname);
      } catch (error) {
        toast.error('Error fetching user info');
      }
    };
    fetchUserInfo();
  }, []);

  const handleNameChange = async () => {
    setLoadingName(true);
    toast.info('Updating name...', { autoClose: false, toastId: 'updateName' });

    try {
      await updateUserName({ fullname:fullName });
      toast.update('updateName', {
        render: 'Name updated successfully!',
        type: "success",
        autoClose: 5000
      });
      setUserInfo((prev) => ({ ...prev, fullName }));
    } catch (error) {
      toast.update('updateName', {
        render: 'Error updating name',
        type: "error",
        autoClose: 5000
      });
    } finally {
      setLoadingName(false);
    }
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setLoadingImage(true);
    toast.info('Uploading image...', { autoClose: false, toastId: 'uploadImage' });

    try {
      const formData = new FormData();
      formData.append('attachments', file);
      const response = await uploadUserFile(formData);
      const profileImg=response.data.data[0];
      const saveChanges= await updateUserName({ profileImage:profileImg });
      
      toast.update('uploadImage', {
        render: 'Image uploaded successfully!',
        type: "success",
        autoClose: 5000
      });
      setUserInfo((prev) => ({ ...prev, profileImage: profileImg }));
    } catch (error) {
      toast.update('uploadImage', {
        render: 'Error uploading image',
        type: "error",
        autoClose: 5000
      });
    } finally {
      setLoadingImage(false);
    }
  };

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }

    setLoadingPassword(true);
    toast.info('Changing password...', { autoClose: false, toastId: 'changePassword' });

    try {
      await changeUserPassword({ currentPassword, newPassword });
      toast.update('changePassword', {
        render: 'Password changed successfully!',
        type: "success",
        autoClose: 5000
      });
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      toast.update('changePassword', {
        render: 'Error changing password',
        type: "error",
        autoClose: 5000
      });
    } finally {
      setLoadingPassword(false);
    }
  };

  return (
    <div className='p-3'>
      <h3>Settings</h3>
      <Row className='px-2 mb-3'>
        <Col lg={6} xs={12}>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <p className='fw-bold text-secondary'>{userInfo.email}</p>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicFullName">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Your Full Name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </Form.Group>
            <Button
              className='prof-btn see-btn'
              onClick={handleNameChange}
              disabled={loadingName}
            >
              {loadingName ? 'Saving...' : 'Save Changes'}
            </Button>
          </Form>
        </Col>
        <Col lg={6} xs={12} className='text-center'>
          <div className='my-2'>
            <Image src={userInfo.profileImage || pic1} className='w-50' style={{ borderRadius: "50%" }} />
          </div>
          <Form.Group controlId="formFile" className="mb-3" style={{display:"none"}}>
            <Form.Control accept=".jpg,.jpeg,.png,.gif" type="file" onChange={handleImageUpload} />
          </Form.Group>
          <Button
            variant="outline-primary"
            className='outlined'
            onClick={() => document.getElementById('formFile').click()}
            disabled={loadingImage}
          >
            {loadingImage ? 'Uploading...' : 'Choose Image'}
          </Button>
        </Col>
      </Row>
      <h3>Change Password</h3>
      <Row className='px-2 border-top my-3 py-2'>
        <Col lg={12} xs={12}>
          <Form>
            <Form.Group className="mb-3 w-100 current" controlId="formCurrentPassword">
              <Form.Label>Current Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
            </Form.Group>
            <Row>
              <Col xs={12} lg={6} className='pe-0'>
              <Form.Group className="mb-3 w-100" controlId="formNewPassword">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </Form.Group>
              </Col>
              <Col xs={12} lg={6} className='pe-0'>
              <Form.Group className="mb-3 ms-0 ms-lg-3 w-100" controlId="formConfirmPassword">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </Form.Group>
              </Col>
            </Row>
            <Button
              className='prof-btn see-btn'
              onClick={handleChangePassword}
              disabled={loadingPassword}
            >
              {loadingPassword ? 'Changing...' : 'Change Password'}
            </Button>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default Settings;
