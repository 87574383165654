import React from 'react'
import { Container } from 'react-floating-action-button'
import share from "../assests/Group 1000003598.png";
import { Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
const ShareComponent = ({ url, fullname, profileImage }) => {
    const location = useLocation();
    const handleShareLink = () => {
        const baseUrl = window.location.origin;
        const path = location.pathname;
        const shareUrl = `${baseUrl}${path}`;
        if (navigator.share && navigator.canShare({
            title: 'Check this out',
            url: shareUrl
        })
        ) {
            navigator.share({
                title: `Have a look at ${fullname} profile`,
                url: shareUrl
            }).then(() => {
                // console.log('Thanks for sharing!');
            }).catch((error) => {
                // console.error('Error sharing:', error);
            });
        } else {
            //   copyToClipboard(shareUrl);
            // console.log("error while copying to clipboard")
        }
    };
    return (
        <Container className="" >
            {/* <Button onClick={copyToClipboard} tooltip="Copy Link" styles={{ backgroundColor: "transparent" }}>
                <Image className='copy pt-2 ' src={copy} alt='share-btn' style={{ cursor: "pointer" }} />
            </Button>
            <Button
                tooltip="WhatsApp">
                <WhatsappShareButton url={url}>
                    <WhatsappIcon size={42} round />
                </WhatsappShareButton>
            </Button>
            <Button
                tooltip="Messenger">
                <FacebookMessengerShareButton url={url}>
                    <FacebookMessengerIcon size={42} round />
                </FacebookMessengerShareButton>
            </Button>
            <Button
                tooltip="Facebook">
                <FacebookShareButton url={url}>
                    <FacebookIcon size={42} round />
                </FacebookShareButton>
            </Button> */}
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="share-tooltip">Share Profile</Tooltip>}
            >
                <Image
                    className='img-size-share rounded-shadow '
                    src={share}
                    alt='share-btn'
                    style={{ cursor: "pointer" }}
                    onClick={handleShareLink}
                />
            </OverlayTrigger>
        </Container>
    )
}

export default ShareComponent
