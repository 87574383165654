// EditModal.js

import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

const EditNameModal = ({ initialValue, onSave, onHide, show }) => {
    const [value, setValue] = useState(initialValue);

    const handleSave = () => {
        const toastId = toast.loading("Updating", { closeButton: true });
        toast.update(toastId, {
            render: "Name is Succesfully Updated",
            type: "success",
            isLoading: false,
            autoClose: 2000,
            hideProgressBar: true,
            closeButton: false,
        });
        onSave(value);
        onHide();
    };

    return (
        <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} centered>
            <Modal.Header className='justify-content-center align-items-center border-0'>
                <Modal.Title className='sign-heading text-center pb-2'>Edit Name</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formBasicPassword" className='pb-3'>
                        <Form.Label className='pass-input'>Name</Form.Label>
                        <Form.Control type="text" placeholder="Name" value={value} onChange={(e) => setValue(e.target.value)} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
                <Button type="button" className='login-button' onClick={handleSave}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditNameModal;
