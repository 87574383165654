import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { GetUserInfo } from '../services/user/user.service';
import ARTemplate1 from "./ARTemplate1.js";
import ARTemplate2 from './ARTemplate2.js';
import ARTemplate3 from './ARTemplate3.js';

const ArViewer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [media, setMedia] = useState([]);
  const [template, setTemplate] = useState(1);
  const [target, setTarget] = useState(null);
  const [fullname, setFullname] = useState(null);
  const [dob, setDob] = useState(null);
  const [dod, setDod] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchProfile = async () => {
    try {
      setLoading(true);
      const response = await GetUserInfo(id);
      const data = response.data;
      console.log("response ar",response.data);
      // console.log("data", data);
      if (data && data.data.arAnimation === null) {
        navigate(`/${id}`);
      }
      const arData = data?.data?.arAnimation;
      setMedia(arData.media);
      setTarget(arData.target);
      setTemplate(arData.template);
      setFullname(data.data.fullname);
      setDod(data.data.deceasedDate);
      setDob(data.data.dob);
      setLoading(false);
    } catch (error) {
      navigate("*");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!id) {
      navigate("*");
    }
    fetchProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, navigate]);

  const renderTemplate = () => {
    switch (template) {
      case 1:
        return <ARTemplate1 media={media} template={template} target={target} fullname={fullname} dob={dob} dod={dod} />;
      case 2:
        return <ARTemplate2 media={media} template={template} target={target} fullname={fullname} dob={dob} dod={dod} />;
      case 3:
        return <ARTemplate3 media={media} template={template} target={target} fullname={fullname} dob={dob} dod={dod} />;
      default:
        return null;
    }
  };

  return (
    <div>
      {loading ? (
        <div className="loader-block text-center d-flex align-items-center justify-content-center flex-column gap-2 fw-bold fs-4">
          <div className="loader">
          </div>
          Loading the AR Experience for you
        </div>
      ) : (
        renderTemplate()
      )
      }
    </div>
  );
};

export default ArViewer;
