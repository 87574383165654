import React from 'react';
import { Container } from 'react-bootstrap';
import HeaderTerms from '../landigPage/HeaderTerms';
import Footer from './Footer';

const Terms= () => {
    
  return (
    <>
    
    <HeaderTerms />

    <Container>
    <div className="terms-and-conditions my-5 ">
      <h1>Terms and Conditions</h1>
      <p>Welcome to Capsule of Memories! These terms and conditions outline the rules and regulations for the use of Capsule of Memories' website.</p>
      
      <h2>Introduction</h2>
      <p>By accessing this website, we assume you accept these terms and conditions in full. Do not continue to use Capsule of Memories' website if you do not accept all of the terms and conditions stated on this page.</p>
      
      <h2>Accounts</h2>
      <p>When you create an account with us, you must provide us with accurate, complete, and current information at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our service.</p>
      
      <h2>Content Ownership</h2>
      <p>You retain ownership of any content you upload to Capsule of Memories. However, by uploading content, you grant Capsule of Memories a worldwide, non-exclusive, royalty-free license to use, copy, modify, and display the content on the site.</p>
      
      <h2>Privacy</h2>
      <p>Your privacy is important to us. Capsule of Memories will not share your personal information with third parties except as required by law or as necessary to provide you with the services of the site.</p>
      
      <h2>Prohibited Activities</h2>
      <p>You agree not to use Capsule of Memories to upload, post, or transmit any content that:</p>
      <ul>
        <li>Is unlawful, harmful, threatening, abusive, or defamatory</li>
        <li>Infringes on the rights of any third party, including copyright, trademark, or privacy rights</li>
        <li>Contains viruses, malware, or any other harmful code</li>
        <li>Impersonates any person or entity, or otherwise misrepresents your affiliation with a person or entity</li>
      </ul>
      
      <h2>Termination</h2>
      <p>We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including, without limitation, if you breach the Terms.</p>
      
      <h2>Changes to These Terms</h2>
      <p>Capsule of Memories reserves the right to revise these terms and conditions at any time. By continuing to access or use our site after those revisions become effective, you agree to be bound by the revised terms.</p>
      
      <h2>Governing Law</h2>
      <p>These terms and conditions are governed by and construed in accordance with the laws of the country in which Capsule of Memories operates.</p>
      
      <h2>Contact Us</h2>
      <p>If you have any questions about these Terms, please contact us.</p>
      
      <p>By using Capsule of Memories, you signify your acceptance of these terms and conditions. If you do not agree to these terms, please do not use our site.</p>
    </div>
    </Container>
    <Footer/>
    </>
  );
};

export default Terms;
