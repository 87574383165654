import React, { useEffect, useState } from 'react'
import { Button, Image, Modal } from 'react-bootstrap'

const ImageModal = ({ show, onHide, imageUrl, Name, large }) => {
    const [image, setImage] = useState(imageUrl);

    useEffect(() => {
        setImage(imageUrl);
    }, [imageUrl]);

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
            dialogClassName="transparent-modal" // Custom class for styling
            size='lg'
        >
            <Modal.Header closeButton style={{ borderBottom: 'none' }}>
                {/* <Modal.Title className='sign-heading text-center pb-2'>{Name}</Modal.Title> */}
            </Modal.Header>
            <Modal.Body className="d-flex justify-content-center align-items-center p-0" style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
                <Image
                    src={image}
                    style={{ width: "100%", height: "auto", objectFit: "contain", maxWidth: '80vw', maxHeight: '80vh' }}
                    alt='selected'
                />
            </Modal.Body>
        </Modal>
    );
};

export default ImageModal;
