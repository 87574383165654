import React, { useState, useRef, useEffect } from "react";
import { Modal, Col, Image, Form, Button, Row, Card } from "react-bootstrap";
import Avatar from "../../assests/anonymous-avatar-icon-25.png";
import { FaTrash } from "react-icons/fa";
import {
  DeleteComments,
  GetComments,
  UpdateComments,
} from "../../services/comments/comments.service";
import moment from "moment";
import { toast } from "react-toastify";

const ImageModal = ({
  showModal,
  isEditingPage,
  closeModal,
  selectedImage,
  commentId,
  caption
}) => {
  const [value, setValue] = useState(caption);
  const [showFullBio, setShowFullBio] = useState(false);
  const [comments, setComments] = useState([]);
  const [visibleComments, setVisibleComments] = useState(5); // Number of comments initially visible
  const [commentText, setCommentText] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const commentInputRef = useRef(null);
  const commentsContainerRef = useRef(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [commentToDelete, setCommentToDelete] = useState(null);

  const fetchComments = async (commentId) => {
    setLoading(true);
    setIsEmpty(false);
    try {
      const response = await GetComments(commentId);
      const comment = response?.data?.comments;
      setComments(comment);
      setIsEmpty(comment?.length === 0);
    } catch (error) {
      if (error?.response?.data?.code === 404) {
        // handle error
      }
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (date) => {
    return moment(date).format("dddd, MMMM D, YYYY [at] h:mm A");
  };

  const handleCommentSubmit = async (text) => {
    setIsSubmitting(true);
    try {
      await UpdateComments(commentId, { text });
      fetchComments(commentId);
    } catch (error) {
      if (error?.response?.data?.code === 404) {
        // handle error
      }
    } finally {
      setIsSubmitting(false);
    }
    setCommentText("");
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleCommentSubmit(e.target.value);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleCommentSubmit(commentText);
  };

  const handleIconClick = () => {
    if (commentText.trim()) {
      handleCommentSubmit(commentText);
    }
  };

  const handleclose = () => {
    setCommentText("");
    closeModal();
    setShowFullBio(false);
  };

  const handleDeleteClick = (commentId) => {
    setCommentToDelete(commentId);
    setShowDeleteModal(true);
  };

  const confirmDeleteComment = async () => {
    if (commentToDelete) {
      const toastId = toast.loading("Deleting", { closeButton: true });
      try {
        await DeleteComments(commentToDelete);
        toast.update(toastId, {
          render: "Comment Deleted Successfully",
          type: "success",
          isLoading: false,
          autoClose: 2000,
          hideProgressBar: true,
          closeButton: false,
        });
        fetchComments(commentId);
      } catch (error) {
        toast.update(toastId, {
          render: error?.response?.data?.message,
          type: "error",
          isLoading: false,
          autoClose: 2000,
          hideProgressBar: true,
          closeButton: false,
        });
      }
    }
    setShowDeleteModal(false);
  };

  useEffect(() => {
    setValue(caption);
    if (showModal) {
      fetchComments(commentId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentId, showModal]);

  const toggleShowFullBio = () => {
    setShowFullBio(!showFullBio);
  };

  const truncatedBio =
    value?.length > 50 ? `${value.substring(0, 50)}...` : value;

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleclose}
        centered
        dialogClassName="custom-modal-width"
      >
        <Modal.Header closeButton>
          {/* Modal Header Content */}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={7}>
              <div className="comment-img-size border shadow-sm">
                <Image
                  src={selectedImage}
                  fluid
                  className="gallery-img"
                  style={{ objectFit: "contain" }}
                />
              </div>
              {caption && (
                <h6 className="about-text px-2 text-center">
                  <span className="about-section" style={{ wordWrap: "break-word" }}>
                    {showFullBio ? value : truncatedBio}{" "}
                    {value?.length > 50 && !showFullBio && (
                      <span
                        className="p-0 link-see primary-color"
                        onClick={toggleShowFullBio}
                      >
                        See More...
                      </span>
                    )}
                  </span>
                </h6>
              )}
            </Col>
            <Col md={5} className="py-3 position-relative">
              <h5 className="about-text pb-2">Comments</h5>
              <div
                className="comments-container"
                ref={commentsContainerRef}
                style={{ maxHeight: "320px", overflowY: "auto" }}
              >
                {loading ? (
                  <div className="pb-5 mb-5">
                    <div className="loader-block">
                      <div className="loader-small"></div>
                    </div>
                  </div>
                ) : isEmpty ? (
                  <p className="text-center about-section">No comments</p>
                ) : (
                  comments.slice(0, visibleComments).map((comment, index) => (
                    <Col key={index} className="d-flex mb-4 align-items-start">
                      <img
                        src={Avatar}
                        alt="avatar"
                        className="rounded-circle me-2"
                        width="32"
                        height="32"
                      />
                      <div className="flex-grow-1">
                        <div className="d-flex gap-1">
                          <Card className="py-0 comment-card d-inline-block border-0">
                            <Card.Body className="py-2 fs-6 name-para">
                              {comment.text}
                            </Card.Body>
                          </Card>
                          {isEditingPage && (
                            <div>
                              <FaTrash
                                className=""
                                style={{ cursor: "pointer", color: "red" }}
                                onClick={() => handleDeleteClick(comment._id)}
                              />
                            </div>
                          )}
                        </div>
                        <div className="pt-1 ps-2 date-time">
                          {formatDate(comment.createdAt)}
                        </div>
                      </div>
                    </Col>
                  ))
                )}
                {!loading && comments?.length > visibleComments && (
                  <Button
                    className="text-decoration-none"
                    variant="link"
                    onClick={() =>
                      setVisibleComments((prevVisible) => prevVisible + 5)
                    }
                    style={{ marginTop: "-10px" }}
                  >
                    View More Comments
                  </Button>
                )}
              </div>
              <Form
                onSubmit={handleFormSubmit}
                className="mt-4 comment-position"
                style={{ bottom: 0 }}
              >
                <Form.Group controlId="comment">
                  <Form.Control
                    className="comment-card"
                    as="textarea"
                    rows={3}
                    placeholder="Write a comment..."
                    required
                    onKeyPress={handleKeyPress}
                    ref={commentInputRef}
                    style={{
                      maxHeight: "100px",
                      overflowY: "auto",
                      paddingRight: "90px",
                    }}
                    value={commentText}
                    onChange={(e) => setCommentText(e.target.value)}
                  />
                  <div
                    className="position-absolute"
                    style={{
                      bottom: "10px",
                      right: "20px",
                      cursor: "pointer",
                      color: commentText.trim() ? "blue" : "gray",
                    }}
                    onClick={handleIconClick}
                  >
                    {isSubmitting ? (
                      <div
                        className="spinner-border spinner-border-sm text-primary"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-send"
                        viewBox="0 0 16 16"
                      >
                        <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z" />
                      </svg>
                    )}
                  </div>
                </Form.Group>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this comment?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDeleteComment}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ImageModal;