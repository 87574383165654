import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    return (
        <footer className="text-white py-5 footer-landing">
        <Container>
            <Row>
                <Col lg={3} md={6}>
                    <h4>Capsule of Memories</h4>
                    <p className='text-white'>Our mission is to help you preserve your memories for generations to come.</p>
                </Col>
                <Col lg={3} md={6}>
                    <h4>Quick Links</h4>
                    <ul className="list-unstyled">
                        <li><a href="#about" className="text-white li-footer">About Us</a></li>
                        <li><a href="#features" className="text-white li-footer">Features</a></li>
                        <li><a href="#vision" className="text-white li-footer">Vision</a></li>
                        <li><a href="#services" className="text-white li-footer">Service</a></li>
                        <li><a href="/terms-and-conditions" className="text-white li-footer">Terms and Conditions</a></li>
                    </ul>
                </Col>
                <Col lg={3} md={6}>
                    <h4>Support</h4>
                    <ul className="list-unstyled">
                        <li><a href="#contact" className="text-white li-footer">Contact Us</a></li>
                        {/* <li><a href="#faq" className="text-white li-footer">FAQ</a></li>
                        <li><a href="#pricing" className="text-white li-footer">Pricing</a></li>
                        <li><a href="#testimonial" className="text-white li-footer">Testimonials</a></li> */}
                    </ul>
                </Col>
                <Col lg={3} md={6}>
                    <h4>Follow Us</h4>
                    <ul className="list-unstyled d-flex justify-content-start">
                        <li><a href="https://facebook.com" className="text-white li-footer me-3"><FontAwesomeIcon icon={faFacebook} size="2x" /></a></li>
                        <li><a href="https://twitter.com" className="text-white li-footer me-3"><FontAwesomeIcon icon={faTwitter} size="2x" /></a></li>
                        <li><a href="https://instagram.com" className="text-white li-footer me-3"><FontAwesomeIcon icon={faInstagram} size="2x" /></a></li>
                        <li><a href="https://linkedin.com" className="text-white li-footer"><FontAwesomeIcon icon={faLinkedin} size="2x" /></a></li>
                    </ul>
                </Col>
            </Row>
        </Container>
    </footer>
    )
}

export default Footer
