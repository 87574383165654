import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import OTPInput from 'react-otp-input';
import ResetPassword from './ResetPassword';
import { toast } from 'react-toastify';
import { VerificationCode } from '../services/login/login.service';

const Code = ({ show, onClose, email }) => {
    const [otp, setOtp] = useState('');
    const [showResetModal, setShowResetModal] = useState(false);
    const handleClose = () => {
        if (onClose) onClose();
    };

    // const handleContinue = () => {
    //     setShowResetModal(true);
    //     handleClose();
    // };
    const handleContinue = async (e) => {
        e.preventDefault();
        const toastId = toast.loading("Verification", { closeButton: true });
        try {
            await VerificationCode({
                email: email,
                code: otp,
            });
            // navigate(`/${id}`)
            toast.update(toastId, {
                render: "Code is Succesfully Verified",
                type: "success",
                isLoading: false,
                autoClose: 2000,
                hideProgressBar: true,
                closeButton: false,
            });
            setShowResetModal(true);
            handleClose();
        } catch (error) {
            toast.update(toastId, {
                render: error.response.data.data.message,
                type: "error",
                isLoading: false,
                autoClose: 3000,
                hideProgressBar: true,
                closeButton: false,
            });
            // setError(error.response.data.error);
        }
    };
    const handleCloseResetModal = () => {
        setShowResetModal(false);
        handleClose();
    };
    return (
        <>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header className='justify-content-center align-items-center border-0'>
                    <Modal.Title className='sign-heading text-center pb-2'>Verification Code</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div className='otp-input'>
                            <OTPInput
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                // renderSeparator={<span>&nbsp</span>}
                                renderInput={(props) => <input {...props} />}
                            />
                        </div>
                        <Button variant="primary" type="button" onClick={handleContinue} className='w-100 login-button py-2 mt-3'>
                            Continue
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
            <ResetPassword show={showResetModal} onClose={handleCloseResetModal} email={email} otp={otp} />
        </>
    );
};

export default Code;
