import React from 'react'
import { Container, Row} from 'react-bootstrap' 
import img1 from '../assests/landing-1.jpeg'
import img2 from '../assests/landing-4.png'
import img3 from '../assests/landing-3.gif'
import img4 from '../assests/landing-5.png'
import img5 from '../assests/landing-6.png'
import img6 from '../assests/landing-7.png'
import SlideshowCard from './SlideshowCard'

const slideshowData = [
    {
        "title": "Digital Flowers",
        "description": "Page creators and visitors can now send beautiful digital flowers to your loved ones with just one click, as a respectful gesture.",
        "imageSrc": img1
      },
    {
      "title": "Slideshare",
      "description": "Create slideshows for family to see and watch forever! Slideshows can also be saved to be sent separately or to your service directors to be shown.",
      "imageSrc": img3
    },
    {
      "title": "Photo/video commenting",
      "description": "Comments can be added by visitors on any photo or video they see to cherish memories of a special time.",
      "imageSrc": img2
    },
    {
      "title": "Tributes",
      "description": "Visitors can add a dedicated tribute to their loved one to bring together all the memories they’ve shared.",
      "imageSrc": img4
    },
    {
      "title": "Prayer card portal",
      "description": "Page creators can use our prayer card portal to digitally make a prayer card that they can send to be made into physical cards or to save and share with family.",
      "imageSrc": img5
    },
    {
      "title": "Augmented reality portal",
      "description": "Our innovated augmented reality system will generate a custom QR code which we automatically generate on your digital prayer card that will display a beautiful animation with highlighted videos or photos which can be added.",
      "imageSrc": img6
    }
  ]

const Features = () => {
    return (
        <>      
             <section id="features" className="py-5"  >
                <Container>
                    <h2 className="text-center mb-4 pb-4">Key Features</h2>
                    <Row className='pt-0 pt-md-2 d-flex align-items-stretch'>
                        
                        
                        {slideshowData.map((data, index) => (
          <SlideshowCard 
            key={index} 
            title={data.title.toUpperCase()} 
            description={data.description} 
            imageSrc={data.imageSrc} 
          />
        ))}
                     
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Features
