import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import Congrats from './Congrats';
import { ResetUserPassword } from '../services/login/login.service';
import { toast } from 'react-toastify';

const ResetPassword = ({ show, onClose, email, otp }) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showCongratsModal, setShowCongratsModal] = useState(false);
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setconfirmPasswordError] = useState('');

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setPasswordError('');
    };
    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        setconfirmPasswordError('');
    };
    const validatePassword = (password) => {
        // Password validation regex
        return password.length >= 8 && password.length <= 20;
    };
    const validateConfirmPassword = (confirmPassword) => {
        // Password validation regex
        return password === confirmPassword;
    };
    const handleClose = () => {
        if (onClose) onClose();
    };

    // const handleContinue = () => {
    //     setShowCongratsModal(true);
    //     handleClose();
    // };
    const handleContinue = async (e) => {
        e.preventDefault();
        setPasswordError('');
        setconfirmPasswordError('');

        let isValid = true;

        // Validate confirm password
        if (!validateConfirmPassword(confirmPassword)) {
            setconfirmPasswordError('Password and Confirm Password should Match.');
            isValid = false;
        }

        // Validate password
        if (!validatePassword(password)) {
            setPasswordError('Password must be between 8 and 20 characters.');
            isValid = false;
        }

        if (isValid) {
            const toastId = toast.loading("Reseting Password", { closeButton: true });
            try {
                await ResetUserPassword({
                    code: otp,
                    email: email,
                    password: password,
                });
                toast.update(toastId, {
                    render: "Password is Succesfully Reset",
                    type: "success",
                    isLoading: false,
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeButton: false,
                });
                setShowCongratsModal(true);
                handleClose();
            } catch (error) {
                toast.update(toastId, {
                    render: error.response.data.error,
                    type: "error",
                    isLoading: false,
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeButton: false,
                });
            }
        }
    };
    const handleCloseCongratsModal = () => {
        setShowCongratsModal(false);
        handleClose();
    };

    return (
        <>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header className='justify-content-center align-items-center border-0'>
                    <Modal.Title className='sign-heading text-center pb-2'>Reset Password?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formBasicPassword" className='pb-3'>
                            <Form.Label className='pass-input'>Password</Form.Label>
                            <Form.Control type="password" placeholder="New Password" value={password} onChange={handlePasswordChange} />
                            <Form.Text className="text-danger">{passwordError}</Form.Text>
                        </Form.Group>
                        <Form.Group controlId="formBasicPassword" className='pb-3'>
                            <Form.Label className='pass-input'>Re-enter Password</Form.Label>
                            <Form.Control type="password" placeholder="Re-enter Password" value={confirmPassword} onChange={handleConfirmPasswordChange} />
                            <Form.Text className="text-danger">{confirmPasswordError}</Form.Text>
                        </Form.Group>
                        <Button variant="primary" type="button" onClick={handleContinue} className='w-100 login-button py-2'>
                            Continue
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
            <Congrats show={showCongratsModal} onClose={handleCloseCongratsModal} />
        </>
    );
};

export default ResetPassword;
