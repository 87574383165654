import React, { useEffect, useState } from 'react';
import pic1 from "../../assests/anonymous-avatar-icon-25.png"; // Ensure you have a default image or handle image paths dynamically
import { Col, Image, Row } from 'react-bootstrap';
import QRCode from 'qrcode.react';
import { Link } from 'react-router-dom';
import { getProfiles } from '../../services/Dashboard/dashboard.service';
import { toast } from 'react-toastify';

const QrCodes = () => {
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const BASE_URL = process.env.REACT_APP_WEB_BASE_URL; 

  const fetchProfiles = async () => {
    setLoading(true);
    try {
      const response = await getProfiles();
      setProfiles(response.data.data);
    } catch (error) {
      toast.error('Error fetching profiles');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProfiles();
  }, []);

  const downloadQRCode = (id) => {
    const canvas = document.getElementById(id);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${id}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const shareQRCode = async (id) => {
    const canvas = document.getElementById(id);
    canvas.toBlob(async (blob) => {
      const file = new File([blob], `${id}.png`, { type: 'image/png' });
      try {
        await navigator.share({
          files: [file],
          title: 'QR Code',
          text: 'Check out this QR code',
        });
      } catch (error) {
        console.error('Error sharing', error);
      }
    });
  };

  return (
    <div className='p-3'>
      <h3>Qr Codes</h3>
      <Row className='px-2'>
        {profiles?.map((profile, index) => (
          <Col key={index} sm={12} className='prof-item rounded-4 py-4 px-3 text-white position-relative my-2'>
            <Row className='justify-content-between'>
              <Col sm={6} className='img-col ms-2 mb-2 ms-lg-0 mb-lg-0 d-flex flex-row'>
                <Image src={profile?.profileImage || pic1} className='pro-pic' />
                <div className='ms-3 my-auto'>
                  <h5 className='mb-0'>{profile.fullname || "Profile Name"}</h5>
                  <ul className={`m-0 privacy-bullet ps-0 ${profile.isPrivate ? 'private' : 'public'}`}>
                    <li className='d-flex align-items-center' style={{ fontSize: "15px" }}>{profile.isPrivate ? "Private" : "Public"}</li>
                  </ul>
                </div>
              </Col>
              <Col sm={3} className='img-col ms-2 mb-2 ms-lg-0 mb-lg-0 d-flex flex-column align-items-center'>
                <QRCode
                  // id={`qrCodeCanvas-${index}`}
                  value={`${BASE_URL}/${profile._id}`}
                  size={80}
                  level={"H"}
                  includeMargin={true}
                />
                <QRCode
                  id={`qrCodeCanvas-${index}`}
                  style={{display:"none"}}
                  value={`${BASE_URL}/${profile._id}`}
                  size={512}
                  level={"H"}
                  includeMargin={true}
                />
                <div className='d-flex flex-row gap-2'>
                  <Link className='text-white' onClick={() => downloadQRCode(`qrCodeCanvas-${index}`)}>
                    <small>Download</small>
                  </Link>
                  <Link className='text-white' onClick={() => shareQRCode(`qrCodeCanvas-${index}`)}>
                    <small>Share</small>
                  </Link>
                </div>
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default QrCodes;
