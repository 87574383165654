import './App.css';
import Routesof from './routes';

function App() {
  return (
    <>
      <Routesof />
    </>
  );
}

export default App;
