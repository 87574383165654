import React from 'react'
import { Container, Row, Col, Image } from 'react-bootstrap'
import vision from '../assests/vision 4 (1).svg'
const Vision = () => {
    return (
        <>
            <section id="vision" className="py-5" style={{backgroundColor:'#F6F5F6'}} >
                <Container>
                    <Row>
                        <Col lg={6} className='d-flex flex-column justify-content-center '>
                        <div className="text-center text-lg-start">
                        <h2 className='pb-3 pb-lg-0'>Our Vision</h2>
                        <p style={{ fontSize: '1.1rem', lineHeight: '1.6', color: '#555' }}>We envision a world where memories never fade and are always within reach. Through continuous innovation and commitment to quality, Capsule of Memories aims to become the leading platform for digital memory preservation, ensuring that the stories of today can be shared and celebrated by future generations.</p>
                        </div>
                           
                        </Col>
                        <Col lg={6}>
                            <div className='d-flex justify-content-center aling-item-center'>
                                <Image src={vision} width="90%" alt="About Image"  className="rounded rounded-4" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

        </>
    )
}

export default Vision
