import React, { useEffect } from 'react';
import './LandingPage.css';
import Header from './Header';
import About from './About';
import Features from './Features';
import Vision from './Vision';
import Service from './Service';
import TargetMarket from './TargetMarket';
import Contact from './Contact';
import Footer from './Footer';
import Team from './Team';

const scrollToSection = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

const LandingPage = () => {
  useEffect(() => {
    const hash = window.location.hash.substring(1); // Remove the '#' from the hash
    if (hash) {
      scrollToSection(hash);
    }
  }, []); 

  return (
    <>
      <Header />
      <About id="about" />
      <Features id="features" />
      <Vision id="vision" />
      <TargetMarket id="targetMarket" />
      <Service id="services" />
      <Team id="team" />
      <Contact id="contact" />
      <Footer />
    </>
  );
};

export default LandingPage;
