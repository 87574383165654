import React, { useState } from 'react';
import { Container, Row, Col, Image, Button, Navbar, Nav } from 'react-bootstrap';
import memories from '../assests/memories.png';
import logo from '../assests/logo.png';
import { Link } from 'react-router-dom';

const backgroundStyle = {
  position: 'relative',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  //textShadow: '2px 2px 4px rgba(0,0,0,0)',
  //height: '100vh', 
  overflow: 'hidden',
};

const overlayStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 1,
};

const contentStyle = {
  position: 'relative',
  zIndex: 2,
};

const Header = () => {
  const handleDemoClick = () => {
    window.location.href = 'https://capsuleofmemories.com/666893c8a54bf81638c80e3c';
  };
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <Navbar expand="lg" className="primary-bg" expanded={expanded}>
        <Container className='gap-3 justify-content-between d-flex'>
          {/* <div className='px-3 mx-0  px-lg-5 mx-lg-5 '>
            <div className='ps-0 ps-lg-5'>

            </div>
          </div> */}
          <Navbar.Brand className="navbar-brand" >
            <Image src={logo} width="100%" height="100%" alt="" className='logo-img' />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")} />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className=" gap-3 ms-auto">
              <Link to="/login" className="w-100">
                <Button className='edit-btn w-100'>Login</Button>
              </Link>
              {/* <Button className='edit-btn w-100' onClick={handleDemoClick}>Demo</Button> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* <nav className="navbar justify-content-between primary-bg">
        <Container className="d-flex justify-content-between align-items-center">
          <Link className="navbar-brand">
            <Image src={logo} width="100%" height="50" alt="" className='logo-img' />
          </Link>
          <div className="d-flex gap-3">
            <Link to="/login" className="w-100">
              <Button className='edit-btn w-100'>Login</Button>
            </Link>
            <Button className='edit-btn w-100' onClick={handleDemoClick}>Demo</Button>
          </div>
        </Container>
      </nav> */}
      <div style={{ ...backgroundStyle, backgroundImage: `url(${memories})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
        <div style={overlayStyle}></div>
        <Container style={contentStyle}>
          <section id="home" className="container py-4">
            <Row>
              <Col lg={6} className="pt-lg-5 pt-0">
                <div className="hero py-lg-5 pt-3 text-center text-lg-start">
                  <h1>
                    Preserve Your <span>Memories</span> Forever
                  </h1>
                  <p className="color-p">
                    Experience a groundbreaking approach to preserving and cherishing personal memories with Capsule of Memories.
                  </p>
                  <Link to={"https://capsuleofmemories.com/666893c8a54bf81638c80e3c"}>
                  <Button variant="primary" type="button" className='about-btn shadow-lg px-4 py-2 login-button fs-5 '>
                            Visit Demo Page
                    </Button>
                  </Link>
                
                </div>
               
              </Col>
            </Row>
          </section>
        </Container>
      </div>
    </>
  );
};

export default Header;
