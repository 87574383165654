import React, { useEffect, useState } from 'react'
import { Button, Form, Image, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify';
import { editCaptionAPI } from '../../services/user/user.service';
import ReactPlayer from 'react-player';

const CaptionModal = ({
    showCaptionEditModal,
    closeEditModal,
    selectedImage,
    selectedVideo,
    profileId,
    photomediaId,
    editCaption,
    fetchUser,
    type
}) => {
    // console.log("Caption", editCaption)
    const [editCaptionValue, setEditCaptionValue] = useState(editCaption);

    const handleclose = () => {
        closeEditModal();
        setEditCaptionValue(editCaption);
    };

    const handleSaveCaption = async () => {
        if (editCaptionValue) {
            const toastId = toast.loading("Updating...", { closeButton: true });
            try {
                const response = await editCaptionAPI(profileId, photomediaId, editCaptionValue);
                toast.update(toastId, {
                    render: "Caption Updated Successfully",
                    type: "success",
                    isLoading: false,
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeButton: false,
                });
                setEditCaptionValue("");
                handleclose();
                fetchUser();
            } catch (error) {
                toast.update(toastId, {
                    render: error?.response?.data?.error,
                    type: "error",
                    isLoading: false,
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeButton: false,
                });
            }
        }
    };

    const handleCaptionChange = (e) => {
        setEditCaptionValue(e.target.value);
    };
    useEffect(() => {
        setEditCaptionValue(editCaption);
    }, [editCaption]);
    return (
        <Modal show={showCaptionEditModal} onHide={handleclose}>
            <Modal.Header closeButton>
                <Modal.Title className='sign-heading text-center pb-2'>Edit Caption</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {type === 'Video' ? (
                    <>
                        <div className="d-flex gap-2 mb-2" style={{ overflowX: "auto", overflowY: "hidden" }}>
                            <div>
                                <ReactPlayer
                                    className={`gallery-img selected`}
                                    url={selectedVideo}
                                    width='100px'
                                    height='80px'
                                    style={{ cursor: 'pointer', border: '2px solid blue' }}
                                />
                            </div>
                        </div>
                        <div className='player-wrapper mb-5 mb-lg-0' style={{ paddingTop: "56.25%", marginBottom: '20px', height: "100%" }}>
                            <ReactPlayer
                                className='video-player comment-player h-100 '
                                url={selectedVideo}
                                width='100%'
                                height={'100%'}
                                controls
                            />
                        </div>
                    </>
                ) : (
                    <>

                        <div className="mb-3 d-flex gap-2 " style={{ overflow: "auto" }}>
                            <div>
                                <Image
                                    src={selectedImage}
                                    className={`gallery-img selected`}
                                    alt='thumbnail'
                                    style={{ cursor: 'pointer', height: "100px", width: "100px", border: '2px solid blue' }}
                                />
                            </div>
                        </div>
                        <div className='mx-auto d-flex align-items-center'>
                            <Image src={selectedImage} style={{ width: "300px", height: "300px", objectFit: "contain" }} className='gallery-img mb-3 mx-auto' alt='selected' />
                        </div>
                    </>
                )}
                <Form.Group controlId="caption" className='pt-3'>
                    <Form.Label className='pass-input'>Caption</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter caption"
                        value={editCaptionValue}
                        onChange={handleCaptionChange}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleclose}>
                    Close
                </Button>
                <Button variant="primary" className='login-button' onClick={handleSaveCaption}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CaptionModal;
