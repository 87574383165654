import React, { useState, useEffect } from 'react';
import { Col, Image, Row, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import pic1 from "../../assests/anonymous-avatar-icon-25.png";
import { getNotifications, ReadNotification } from '../../services/Dashboard/notification.service';
import { toast } from 'react-toastify';


const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [visibleNotifications, setVisibleNotifications] = useState(4);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const fetchNotifications = async () => {
    try {
      setIsLoading(true);
      const response = await getNotifications();
      console.log("Notifications", response?.data?.notifications)
      setNotifications(response?.data?.notifications);
      console.log("notifications", response?.data?.notifications);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching notifications', error);
    }
  };
  useEffect(() => {

    fetchNotifications();
  }, []);

  const handleSeeMore = () => {
    setVisibleNotifications((prevVisibleNotifications) => prevVisibleNotifications + 4);
  };

  const handleNotificationClick = async (notification) => {
    try {
      await ReadNotification(notification?._id);
      fetchNotifications();
      const { type, profile, media } = notification;
      let path = `/${profile._id}`;

      if (type === 'tribute') {
        path += '?type=tribute';
      } else if (type === 'comment') {
        path += `?type=comment&media=${media}`;
      }

      window.location.href = path;
    } catch (error) {
      console.error("Error marking notification as read:", error);
      toast.error("Failed to mark notification as read", {
        type: "error",
        isLoading: false,
        autoClose: 2000,
        hideProgressBar: true,
        closeButton: false,
      });
    }
  };


  return (
    <div className='p-3'>
      <h3>Notifications</h3>
      {isLoading ? (
        <div className="d-flex justify-content-center">
          <div className="loader-small"></div>
        </div>
      ) : notifications.length === 0 ? (
        <div>
          <p>There are no notifications currently</p>
        </div>
      ) : (
        <>
          <Row className='px-2'>
            {notifications?.slice(0, visibleNotifications).map((notification, index) => (
              <Col key={index} sm={12}
                className={`border-bottom py-4 px-3 ${notification?.isRead
                  ? ""
                  : "customHover"
                  }`}>
                <Button className='d-flex flex-row align-items-center bg-transparent border-0' onClick={() => handleNotificationClick(notification)}>
                  <Image src={notification?.profile?.profileImage || pic1} className='' width={45} height={45} style={{ borderRadius: "50%" }} />
                  <Link className='text-decoration-none text-dark'>
                    <div className='ms-2'>
                      <p className='mb-0'><b>{notification?.profile?.fullname}</b> {notification?.message}</p>
                      <p style={{ fontSize: "10px", textAlign: "left" }} className='mb-0 '>{new Date(notification.createdAt).toLocaleString()}</p>
                    </div>
                  </Link>
                </Button>
              </Col>
            ))}
          </Row>
          {visibleNotifications < notifications?.length && (
            <div className='text-center mt-3'>
              <Button onClick={handleSeeMore} className='prof-btn see-btn'>See More</Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Notifications;

