import React, { useEffect, useState } from 'react';
import { Button, Image, Container, Navbar, Nav, Dropdown } from 'react-bootstrap';
import logo from "../assests/logo.png";
import { Link, useNavigate, useParams, useLocation, matchPath } from 'react-router-dom';
import { AuthVerify } from '../utils/auth.utils';
import { toast } from 'react-toastify';
import { GetUserData } from '../services/user/user.service';
import { messaging } from "../firebase";
import { getToken, onMessage } from "firebase/messaging";
import {
  SaveToken
} from "../services/user/user.service";
import DashboardModal from './DashboardModal';
import { getNotifications } from '../services/Dashboard/notification.service';
const Header = ({ user, onEditPage, showButtons, arlink }) => {
  const { id } = useParams();
  const verify = AuthVerify();
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [notificationLength, setNotificationLength] = useState();
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);
  const [showDashboard, setShowDashboard] = useState(false);
  const [activeKey, setActiveKey] = useState("first");
  const searchParams = new URLSearchParams(location.search);
  const dashboard = searchParams.get("dashboard");
  const [dashboardActive, setDashboardActive] = useState(dashboard);
  const baseUrl = process.env.REACT_APP_WEB_BASE_URL;
  const handleShowModal = () => setShowDashboard(true);
  const handleCloseModal = () => {
    setShowDashboard(false);
    setActiveKey("first");
  };
  const fetchUser = async () => {
    try {
      setLoading(true);
      const response = await GetUserData();
      const user = response?.data?.data;
      setUserInfo(user);
    } catch (error) {
      // console.log("errro code", error.response.data.code)
      if (error.response.data.code === 404 || error.code === "ERR_NETWORK") {
        navigate("*")
      }
    } finally {
      setLoading(false);

    }
  };
  useEffect(() => {
    if (verify) {
      fetchUser();
      if (dashboardActive) {
        handleShowModal();
        setDashboardActive(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verify]);

  const handleDashboard = async () => {
    if (handleShowModal) {
      handleShowModal();
    }
    else {
      navigate(`/${id}?dashboard=true`)
    }
  };

  const handleEditPage = () => {
    if (verify) {
      const toastId = toast.loading("Loading", { closeButton: true });
      toast.update(toastId, {
        render: "You are now able to Edit this Page",
        type: "success",
        isLoading: false,
        autoClose: 2000,
        hideProgressBar: true,
        closeButton: false,
      });
      onEditPage();
    } else {
      navigate(`/login/${id}`);
    }
  };

  const handleNavigate = () => {
    window.location.href = `${baseUrl}/ARView/${id}`;
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    navigate("/");
  };



  const isPrayerCardRoute = matchPath('/prayer-card/:id', location.pathname);
  const isARAnimationRoute = matchPath('/aranimation/:id', location.pathname) || matchPath('/AR/:id', location.pathname);
  const fetchNotificationsLength = async () => {
    try {
      const response = await getNotifications();
      setNotificationLength(response?.data?.unreadCount);
      console.log("notifications", response?.data);
      console.log("notifications length", response?.data?.unreadCount);
    } catch (error) {
      console.error("Error fetching notifications", error);
    }
  };
  useEffect(() => {
    const requestPermission = async () => {
      try {
        await Notification.requestPermission();
        const token = await getToken(messaging, {
          vapidKey:
            "BN17Nxnv-8RFEemevO60enU2dNvDuUT1JunbsLBlPJN4TnefbzITaS9pNKMyaUeluia92KU5bPqeg-vmqk28Bhk",
        });
        console.log("Token received: ", token);
        let payload = {
          deviceToken: token,
        };
        const response = await SaveToken(payload);
        const data = await response.data;
        localStorage.setItem("deviceToken", token);
        console.log("saved it!");
        console.log(data.message);
      } catch (error) {
        console.error("Permission denied or error:", error);
      }
    };

    requestPermission();
    fetchNotificationsLength();
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener("message", (event) => {
        if (event.data?.type === "FETCH_NOTIFICATIONS") {
          fetchNotificationsLength();
        }
      });
    }

    onMessage(messaging, (payload) => {
      console.log("Message received. ", payload);
      fetchNotificationsLength();
      toast.info(`Notification: ${payload.notification.title}`, {
        autoClose: 4000,
        onClick: () => {
          setActiveKey("second");
          setShowDashboard(true);
        },
      });
      // alert(`Notification: ${payload.notification.title} - ${payload.notification.body}`);
    });
  }, []);
  return (
    <>
      <Navbar expand="lg" className="primary-bg" expanded={expanded}>
        <Container className=''>
          <Navbar.Brand as={Link} to={`/${id}`} className='navbar-logo'>
            <Image src={logo} width="100%" height="100%" alt="" className='logo-img' />
          </Navbar.Brand>
          {
            !verify ? (
              <>
                <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")} />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className=" gap-3 ms-auto">
                    {(isPrayerCardRoute || isARAnimationRoute) && (
                      <Link to={`/${id}`}>
                        <Button className='edit-btn w-100'>Profile</Button>
                      </Link>
                    )}
                    {/* <Link to={`/prayer-card/${id}`}>
                      <Button className='edit-btn w-100'>Prayer Card</Button>
                    </Link> */}
                    {!showButtons && (
                      <Button className='edit-btn' onClick={handleEditPage}>Edit this Page</Button>
                    )}

                  </Nav>
                </Navbar.Collapse>
              </>
            ) : (
              <Dropdown align="end" className='profile-dropdown'>
                {notificationLength !== 0 && (
                  <span className="badge badge-notify">
                    {notificationLength}
                  </span>
                )}
                <Dropdown.Toggle variant="light" className='gap-3 ms-auto bg-transparent border-0' id="dropdown-basic">
                  <img
                    src={!loading && userInfo ? userInfo?.profileImage || `https://api.dicebear.com/9.x/initials/svg?seed=${userInfo?.fullname}` : "https://api.dicebear.com/9.x/avataaars-neutral/svg?seed=Pumpkin"}
                    className="rounded-circle pro-header-image "
                    width="40"
                    height="40"
                    alt="Avatar"
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-animated">
                  {(isPrayerCardRoute || isARAnimationRoute) && (
                    <Dropdown.Item as={Link} to={`/${id}`}>
                      Profile
                    </Dropdown.Item>
                  )}
                  {/* <Dropdown.Item as={Link} to={`/prayer-card/${id}`}>
                    Prayer Card
                  </Dropdown.Item> */}
                  <Dropdown.Item onClick={handleDashboard} className="d-flex justify-content-between align-items-center">
                    <span>Dashboard</span>
                    {notificationLength !== 0 && (
                      <span className="badge badge-notify" style={{ position: 'static' }}>{notificationLength}</span>
                    )}
                  </Dropdown.Item>
                  {!showButtons && (
                    <Dropdown.Item onClick={handleEditPage}>
                      Edit this Page
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item onClick={handleLogout}>
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )
          }
        </Container>
      </Navbar>
      <DashboardModal
        show={showDashboard}
        handleClose={handleCloseModal}
        activeKey={activeKey}
        setActiveKey={setActiveKey}
        notificationLength={notificationLength}
      />
    </>
  );
};

export default Header;


