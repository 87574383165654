import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import Code from './Code';
import { ForgotPassword } from '../services/login/login.service';
import { toast } from 'react-toastify';

const ForgetPassword = ({ show, onClose }) => {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [showCodeModal, setShowCodeModal] = useState(false);

    const handleClose = () => {
        if (onClose) onClose();
    };
    const validateEmail = (email) => {
        // Email validation regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setEmailError('');
    };
    const handleContinue = async (e) => {
        e.preventDefault();
        // Reset previous error messages
        setEmailError('');
        let isValid = true;
        // Validate email
        if (!validateEmail(email)) {
            setEmailError('Please enter a valid email address.');
            isValid = false;
        }
        if (isValid) {
            const toastId = toast.loading("Sending Email", { closeButton: true });
            try {
                await ForgotPassword({
                    email: email,
                });
                // navigate(`/${id}`)
                toast.update(toastId, {
                    render: "Email is Succesfully sent",
                    type: "success",
                    isLoading: false,
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeButton: false,
                });
                setShowCodeModal(true);
                handleClose();
            } catch (error) {
                toast.update(toastId, {
                    render: error.response.data.error,
                    type: "error",
                    isLoading: false,
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeButton: false,
                });
                // setError(error.response.data.error);
            }
        }
    };

    const handleCloseCodeModal = () => {
        setShowCodeModal(false);
        handleClose();
    };
    return (
        <div>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header className='justify-content-center align-items-center border-0'>
                    <Modal.Title className='sign-heading text-center pb-2'>Forgot Password?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formBasicEmail" className='pb-3'>
                            <Form.Control type="email" placeholder="Email" value={email} onChange={handleEmailChange} />
                            <Form.Text className="text-danger">{emailError}</Form.Text>
                        </Form.Group>
                        <Button variant="primary" type="button" onClick={handleContinue} className='w-100 login-button py-2'>
                            Continue
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
            <Code show={showCodeModal} onClose={handleCloseCodeModal} email={email} />
        </div>
    );
};

export default ForgetPassword;
