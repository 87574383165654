import React, { useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';

const PasswordModal = ({ show, onSubmit, onClose }) => {
    const [password, setPassword] = useState('');

    const handleContinue = () => {
        onSubmit(password); // Call onSubmit function with the entered password
        handleClose(); // Close the modal
    };

    const handleClose = () => {
        onClose(); // Call onClose function
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleContinue();
        }
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        handleContinue();
    };

    return (
        <div>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header className='justify-content-center align-items-center border-0'>
                    <Modal.Title className='sign-heading text-center pb-2'>Put Password On the Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleFormSubmit}>
                        <Form.Group controlId="formBasicPassword" className='pb-3'>
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                onKeyPress={handleKeyPress}
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit" className='w-100 login-button py-2'>
                            Save
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default PasswordModal;
