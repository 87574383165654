import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
// import { FaPencilAlt } from 'react-icons/fa'
import QRCode from 'qrcode.react';
import EditInfoModal from './EditInfoModal';
import moment from 'moment';
import { FaPencilAlt } from 'react-icons/fa';
const TemplateBack1 = ({ isEditingPage, templateHead, sign, number, company, profilename, dob, deceasedDate, info, prayerLocation, prayerDate, intermentLocation, profileImage, arlink, onUpdate }) => {
    console.log("NAMe full", profilename)
    const { id } = useParams();
    const [localFullname, setLocalFullname] = useState(profilename);
    const baseUrl = process.env.REACT_APP_WEB_BASE_URL;
    const [localDob, setLocalDob] = useState(dob);
    const [showModal, setShowModal] = useState(false);
    const [localInfo, setLocalInfo] = useState(info);
    const [localDeceasedDate, setLocalDeceasedDate] = useState(deceasedDate);
    // const [localProfileImage, setLocalProfileImage] = useState(profileImage);
    const qrLink = `${baseUrl}/${id}`;
    const handleNameSave = (newInfo) => {
        setLocalInfo(newInfo);
        onUpdate(templateHead, sign, number, company, profilename, localDob, localDeceasedDate, newInfo, prayerLocation, prayerDate, intermentLocation);
    };
    useEffect(() => {
        setLocalFullname(profilename);
        setLocalDob(dob);
        setLocalDeceasedDate(deceasedDate);
        // setLocalProfileImage(profileImage);
    }, [profilename, dob, deceasedDate, info]);
    const formattedDob = localDob !== "Enter Date of Birth" ? moment(localDob).format('MMMM DD, YYYY') : localDob;
    const formattedDeceasedDate = localDeceasedDate !== "Enter Date Of Death" ? moment(localDeceasedDate).format('MMMM DD, YYYY') : localDeceasedDate;
    return (
        <>
            <div className="my-1">
                <div className="pt-4 temp-font position-relative" style={{ width: "570px", height: "940px", backgroundColor: 'white' }}>
                    <div className="text-center">
                        <h1 className="fw-bold localFullname">{localFullname}</h1>
                    </div>
                    <div className="w-100 d-flex flex-column align-items-center justify-content-center pt-1">
                        <h2 className="text-center fw-bolder mb-0"> {formattedDob} - <br />{formattedDeceasedDate}</h2>
                    </div>
                    {/* <div className="text-center pt-3">
                        <h3 className="fw-bolder">Pslam 23</h3>
                    </div> */}
                    <div className="text-center d-flex justify-content-center pt-3 gap-2">
                        <h4 style={{ width: "22rem", wordWrap: "break-word" }}>
                            {localInfo}
                        </h4>
                        {isEditingPage && (
                            <FaPencilAlt
                                style={{ cursor: "pointer" }}
                                onClick={() => setShowModal(true)}
                            />
                        )}
                    </div>
                    <div className="d-flex justify-content-center ">
                        {/* {arlink &&
                            <div className='d-flex flex-column align-items-center justify-content-center'>
                                <QRCode value={arlink} size={110} level={"H"} includeMargin={true} className="qr-code-left" />
                                <p className='m-0 text-primary-emphasis lh-sm' style={{ fontSize: "0.7rem" }}>Experience AR</p>
                            </div>
                        } */}
                        <div className='d-flex flex-column align-items-center justify-content-center' style={{ position: "absolute", bottom: "10px" }}>
                            <QRCode value={qrLink} size={110} level={"H"} includeMargin={true} className="qr-code-left" />
                            <p className='m-0 text-primary-emphasis lh-sm' style={{ fontSize: "0.7rem" }}>Visit Profile</p>
                        </div>
                    </div>
                </div>
            </div>
            <EditInfoModal
                initialValue={info}
                onSave={handleNameSave}
                onHide={() => setShowModal(false)} // Close modal on hide
                show={showModal} // Pass showModal state to control modal visibility
            />
        </>
    )
}

export default TemplateBack1
