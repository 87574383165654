import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { ContactApi } from '../services/Landing/landing.service.js';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const { name, email, message } = formData;

        // Validate fields
        if (!name || !email || !message) {
            toast.error("All fields are required!");
            return;
        }

        if (!validateEmail(email)) {
            toast.error("Please enter a valid email address!");
            return;
        }

        if (message.length > 700) {
            toast.error("Message cannot be longer than 700 characters!");
            return;
        }

        try {
            const response = await ContactApi(formData);
            console.log('API response:', response.data);
            setFormData({ name: '', email: '', message: '' });
            toast.success("Your message has been sent!");
        } catch (error) {
            console.error('There was an error submitting the form!', error);
            toast.error("There was an error submitting the form!");
        }
    };

    return (
        <section id="contact" className="py-5" style={{ backgroundColor: '#F6F5F6' }} >
            <Container>
                <h2 className="text-center mb-4 pb-4">Get in Touch</h2>
                <Row>
                    <Col lg={7} className='pt-3'>
                        <h4>Contact Details</h4>
                        <p>Email: info@capsuleofmemories.com</p>
                        {/* <p>Phone: +1 234 567 890</p>
                        <p>Address: 123 Memory Lane, Nostalgia City, 45678</p> */}
                    </Col>
                    <Col lg={5}>
                        <h4>Send Us a Message</h4>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="name">Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    id="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="email">Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    id="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="message">Message</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    id="message"
                                    rows={4}
                                    value={formData.message}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                            <Button variant="light" className='button' type="submit">
                                Submit
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Contact;
