import React from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import './LandingPage.css'
import person1 from '../assests/umer (1).jpg'
import founder1 from '../assests/ceo (1).jpg'
import founder from '../assests/fouder (1).jpg'
const Team = () => {
    return (
        <>
            <section className="py-5" >
                <Container>
                    <h2 className="text-center mb-3 pb-2">Team</h2>
                    <Row className='pt-3 justify-content-center'>
                        <Col lg={4} md={6} className="text-center mb-4">
                            <Card className="text-center card-transparent1 justify-content-center align-items-center">
                                {/* <div style={{ width: "160px", height: '160px' }}>
                                    <Card.Img variant="top" src={founder1} className='card-transparent' style={{ width: '100%', height: "100%", margin: 'auto', borderRadius: '50%', backgroundColor: "#F6F5F6" }} />
                                </div> */}
                                <Card.Body>

                                    <h3 className="mt-3 ">Joe Ruscetti</h3>
                                    <p>Co-Founder </p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4} md={6} className="text-center mb-4">
                            <Card className="text-center card-transparent1 justify-content-center align-items-center">
                                {/* <div style={{ width: "160px", height: '160px' }}>
                                    <Card.Img variant="top" src={founder} className='card-transparent' style={{ width: '100%', height: "100%", margin: 'auto', borderRadius: '50%', backgroundColor: "#F6F5F6" }} />
                                </div> */}

                                <Card.Body>
                                    <h3 className="mt-3 ">Anthony Ruscetti</h3>
                                    <p>Co-Founder </p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={4} md={6} className="text-center mb-4">
                            <Card className="text-center card-transparent1 justify-content-center align-items-center">
                                {/* <div style={{ width: "160px", height: '160px' }}>
                                    <Card.Img variant="top" src={person1} className='card-transparent' style={{ width: '100%', height: '100%', margin: 'auto', borderRadius: '50%', backgroundColor: "#F6F5F6" }} />

                                </div> */}

                                <Card.Body>
                                    <h3 className="mt-3 ">Umer Shehzad</h3>
                                    <p>CTO</p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>



        </>
    )
}

export default Team
