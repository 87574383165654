import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";
import Autocomplete from "react-google-autocomplete";

const GraveyardLocation = ({ isEditingPage, address, coordinates, updateAddress, updateCoordinates }) => {
    const [userLocation, setUserLocation] = useState(null);
    const [markers, setMarkers] = useState([]);
    const [mapKey, setMapKey] = useState(0);
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: "AIzaSyDFh1g2GhFvl3b_T8G__6C7Qh0pNo_8PdI"
    });
    // console.log("Coordinates in map", coordinates)
    const [center, setCenter] = useState({
        lat: coordinates[0],
        lng: coordinates[1],
    });

    const handlePlaceSelect = (selected) => {
        const location = {
            lat: selected.geometry.location.lat(),
            lng: selected.geometry.location.lng(),
        };
        setCenter(location); // Update the map's center to the selected place
    };
    const enableLocation = () => {
        let watchId = null;
        if (navigator.geolocation) {
            watchId = navigator.geolocation.watchPosition(
                (position) => {
                    const userLoc = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    setUserLocation(userLoc);
                    // setCenter(userLoc); // Update user's location
                },
                (error) => {
                    console.error("Error fetching the user's location", error);
                },
                {
                    enableHighAccuracy: true,
                    maximumAge: 0,
                    timeout: 5000,
                }
            );
        }

        return () => {
            if (watchId) {
                navigator.geolocation.clearWatch(watchId);
            }
        };
    };
    const handleLocationClick = () => {
        enableLocation();
        if (userLocation) {
            setCenter(userLocation);
            setMapKey(prevKey => prevKey + 1);
        }
    };

    const handleMapClick = async (event) => {
        if (isEditingPage) {

            const newMarker = {
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
            };
            const address = await getAddress(event.latLng.lat(), event.latLng.lng());
            // console.log("evenet", address)
            updateAddress(address);
            updateCoordinates([event.latLng.lat(), event.latLng.lng()]);
            setMarkers([newMarker]);
        }
    };

    const handleMarkerClick = (index) => {
        if (isEditingPage) {
            const newMarkers = [...markers];
            newMarkers.splice(index, 1);
            setMarkers(newMarkers);
        }
    };
    const handleGoToMapClick = () => {
        // Open map with directions to the destination address
        window.open(`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(address)}`);
    };

    // useEffect(() => {
    // let watchId = null;
    // if (navigator.geolocation) {
    //     watchId = navigator.geolocation.watchPosition(
    //         (position) => {
    //             const userLoc = {
    //                 lat: position.coords.latitude,
    //                 lng: position.coords.longitude,
    //             };
    //             setUserLocation(userLoc);
    //             // setCenter(userLoc); // Update user's location
    //         },
    //         (error) => {
    //             console.error("Error fetching the user's location", error);
    //         },
    //         {
    //             enableHighAccuracy: true,
    //             maximumAge: 0,
    //             timeout: 5000,
    //         }
    //     );
    // }

    // return () => {
    //     if (watchId) {
    //         navigator.geolocation.clearWatch(watchId);
    //     }
    // };
    // }, []);

    useEffect(() => {
        setMarkers([{
            lat: coordinates[0] || 40.7375664,
            lng: coordinates[1] || -74.0024799
        }]);
    }, [coordinates]);
    const getAddress = async (lat, lng) => {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyDFh1g2GhFvl3b_T8G__6C7Qh0pNo_8PdI`;
        try {
            const response = await fetch(url);
            const data = await response.json();
            return data.results[0].formatted_address; // Returns the formatted address
        } catch (error) {
            console.error("Error fetching address:", error);
            return null;
        }
    };

    // useEffect(() => {
    //     if (cancel) {
    //         setMarkers([]);
    //     }
    //     if (save && markers.length !== 0) {
    //         localStorage.setItem("location", JSON.stringify(markers));
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [cancel, save]);

    return (
        <>
            {isLoaded && (
                <>

                    <div className="d-flex justify-content-between align-items-center pt-5 pb-3 location gap-2">
                       
                <h3 className='pt-2 about-text'>Gravesite Location </h3>

                        {isEditingPage ? (
                            <div className="d-flex justify-content-between lg:gap-3 gap-2 align-items-center">
                                <div className='input-location'>
                                    <Form.Group>
                                        <Autocomplete
                                            apiKey={"YOUR_API_KEY"}
                                            options={{
                                                types: [],
                                            }}
                                            onPlaceSelected={handlePlaceSelect}
                                        />
                                    </Form.Group>
                                </div>
                                <Button
                                    className='location-btn'
                                    onClick={handleLocationClick}
                                    style={{ margin: "10px", marginLeft: "auto" }}
                                >
                                    My Location
                                </Button>
                            </div>
                        ) : (
                            <Button className='edit-btn' onClick={handleGoToMapClick}>Get directions on Google Maps</Button>
                        )}
                    </div>

                    <GoogleMap
                        mapContainerStyle={{ width: '100%', height: '400px', borderRadius: '15px' }}
                        key={mapKey}
                        center={center}
                        zoom={18}
                        onClick={handleMapClick}
                    >
                        {markers.map((marker, index) => (
                            <MarkerF
                                key={index}
                                position={marker}
                                onClick={() => handleMarkerClick(index)}
                            />
                        ))}
                        {userLocation && (
                            <MarkerF
                                position={userLocation}
                                icon={{
                                    path: window.google.maps.SymbolPath.CIRCLE,
                                    scale: 7,
                                    fillColor: "#4285F4",
                                    fillOpacity: 1,
                                    strokeWeight: 2,
                                    strokeColor: "white",
                                }}
                            />
                        )}
                    </GoogleMap>
                </>
            )
            }
        </>
    );
}

export default GraveyardLocation;
