import React, { useEffect } from "react";
import "aframe";
import "mind-ar/dist/mindar-image-aframe.prod.js";
import "aframe-extras";
import { Entity, Scene } from "aframe-react";
import "../Ar.css";
import qr from "../assests/placeholder.png";
import bg from "../assests/images/bg-3.png";
import iconPlay from "../assests/icons/play.png";
import iconLeft from "../assests/icons/left.png";
import iconRight from "../assests/icons/right.png";
import preview1 from "../assests/images/img-1.jpg";
import preview2 from "../assests/images/img-2.jpg";
import preview3 from "../assests/images/img-3.jpg";
import flowerModel from "../assests/models/bouquet.glb";
import flower2 from "../assests/models/t2-flower.glb";
import butterfliesModel from "../assests/models/t3-butterflies.glb";
import butterfly2 from "../assests/models/t3-blueButterfly.glb";
import moment from "moment";

const ARTemplate3 = ({ media, template, target, fullname, dob, dod }) => {
  useEffect(() => {
    let animationsRun = false;

    // console.log({ media, template, target });

    const showInfo = () => {
      const profileButton = document.getElementById("profile-button");
      const webButton = document.getElementById("web-button");
      const emailButton = document.getElementById("email-button");
      const locationButton = document.getElementById("location-button");
      const text = document.getElementById("text");

      if (profileButton) profileButton.setAttribute("visible", true);
      setTimeout(() => {
        if (webButton) webButton.setAttribute("visible", true);
      }, 300);
      setTimeout(() => {
        if (emailButton) emailButton.setAttribute("visible", true);
      }, 600);
      setTimeout(() => {
        if (locationButton) locationButton.setAttribute("visible", true);
      }, 900);

      let currentTab = "";
      if (webButton) {
        webButton.addEventListener("click", function () {
          if (text) text.setAttribute("value", "https://softmind.tech");
          currentTab = "web";
        });
      }
      if (emailButton) {
        emailButton.addEventListener("click", function () {
          if (text) text.setAttribute("value", "hello@softmind.tech");
          currentTab = "email";
        });
      }
      if (profileButton) {
        profileButton.addEventListener("click", function () {
          if (text)
            text.setAttribute("value", "AR, VR solutions and consultation");
          currentTab = "profile";
        });
      }
      if (locationButton) {
        locationButton.addEventListener("click", function () {
          if (text) text.setAttribute("value", "Vancouver, Canada | Hong Kong");
          currentTab = "location";
        });
      }

      if (text) {
        text.addEventListener("click", function () {
          if (currentTab === "web") {
            window.location.href = "https://softmind.tech";
          }
        });
      }
    };

    const showPortfolio = (done) => {
      const portfolio = document.getElementById("portfolio-panel");
      const portfolioLeftButton = document.getElementById(
        "portfolio-left-button"
      );
      const portfolioRightButton = document.getElementById(
        "portfolio-right-button"
      );

      let y = 0;
      let currentItem = 0;

      if (portfolio) portfolio.setAttribute("visible", true);

      const showPortfolioItem = (item) => {
        media.forEach((portfolioItem, index) => {
          const itemElement = document.getElementById(`portfolio-item${index}`);
          if (itemElement) itemElement.setAttribute("visible", index === item);
        });
      };

      const id = setInterval(() => {
        y += 0.008;
        if (y >= 0.8) {
          clearInterval(id);
          if (portfolioLeftButton)
            portfolioLeftButton.setAttribute("visible", true);
          if (portfolioRightButton)
            portfolioRightButton.setAttribute("visible", true);

          if (portfolioLeftButton) {
            portfolioLeftButton.addEventListener("click", () => {
              const videoElement = document.getElementById(
                `video-${currentItem}`
              );
              if (videoElement && !videoElement.paused) videoElement.pause();
              currentItem = (currentItem + 1) % media.length;
              showPortfolioItem(currentItem);
            });
          }

          if (portfolioRightButton) {
            // console.log("right clicked");
            portfolioRightButton.addEventListener("click", () => {
              const videoElement = document.getElementById(
                `video-${currentItem}`
              );
              if (videoElement && !videoElement.paused) videoElement.pause();
              currentItem = (currentItem - 1 + media.length) % media.length;
              showPortfolioItem(currentItem);
            });
          }

          const playVideo = () => {
            // console.log("play");
            const currentPortfolioItem = media[currentItem];
            if (currentPortfolioItem.type === "video") {
              const previewButton = document.getElementById(
                `paintandquest-preview-button`
              );
              if (previewButton) previewButton.setAttribute("visible", false);
              const playButton = document.getElementById(
                `play-button-${currentItem}`
              );
              if (playButton) {
                playButton.setAttribute("visible", false);
              }
              const videoElement = document.getElementById(
                `video-${currentItem}`
              );
              if (videoElement && videoElement.paused) {
                videoElement.play();
              } else {
                videoElement.pause();
              }
            }
          };

          const currentPortfolioItem = media[1];
          // console.log("currentPortfolioItem", currentPortfolioItem);
          if (currentPortfolioItem.type === "video") {
            const previewButton = document.getElementById(
              "paintandquest-preview-button"
            );
            if (previewButton) {
              previewButton.addEventListener("click", playVideo);
            }
          }

          media.forEach((item, index) => {
            const videoElement = document.getElementById(`video-${index}`);
            if (videoElement) {
              videoElement.addEventListener("pause", () => {
                if (currentPortfolioItem.type === "video") {
                  const playButton = document.getElementById(
                    `play-button-${index}`
                  );
                  if (playButton) playButton.setAttribute("visible", true);
                }
              });
            }
          });

          setTimeout(() => {
            done();
          }, 500);
        }
        if (portfolio) portfolio.setAttribute("position", "0 " + y + " -0.01");
      }, 10);
    };

    const showAvatar = (onDone) => {
      const avatar = document.getElementById("avatar");
      let z = -0.3;
      const id = setInterval(() => {
        z += 0.01;
        if (z >= 0.2) {
          clearInterval(id);
          onDone();
        }
        if (avatar) avatar.setAttribute("position", "0 -0.65 " + z);
      }, 10);
    };

    const handleTargetFound = () => {
      if (!animationsRun) {
        // showAvatar(() => {
        //   setTimeout(() => {
        //     showPortfolio(() => {
        //       setTimeout(() => {
        //         showInfo();
        //       }, 300);
        //     });
        //   }, 300);
        // });
        animationsRun = true;
      }
    };

    const handleTargetLost = () => {
      media.forEach((item, index) => {
        const videoElement = document.getElementById(`video-${index}`);
        if (videoElement && !videoElement.paused) {
          videoElement.pause();
        }
      });
    };

    const myTarget = document.getElementById("mytarget");
    if (myTarget) {
      myTarget.addEventListener("targetFound", handleTargetFound);
      myTarget.addEventListener("targetLost", handleTargetLost);
    }

    const butterfly = document.getElementById("butterfly");
    if (butterfly) {
      butterfly.setAttribute("animation-mixer", "clip: Take 01");
    }

    return () => {
      if (myTarget) {
        myTarget.removeEventListener("targetFound", handleTargetFound);
        myTarget.removeEventListener("targetLost", handleTargetLost);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [media]);

  return (
    <div className="example-container">
      <div id="example-scanning-overlay" className="hidden">
        <div className="inner">
          <img src={qr} alt="QR" />
          <div className="scanline"></div>
        </div>
      </div>

      <Scene
        mindar-image={`imageTargetSrc: ${target}; showStats: false; filterMinCF:0.00001; filterBeta: 0.01; warmupTolerance: 3; missTolerance: 10; uiScanning: #example-scanning-overlay;`}
        embedded
        color-space="sRGB"
        renderer="colorManagement: true, physicallyCorrectLights"
        xr-mode-ui="enabled: false"
        device-orientation-permission-ui="enabled: false"
      >
        <a-assets>
          <img id="card" src={bg} alt="Card" />
          <img id="icon-web" src={bg} alt="Web Icon" />
          <img id="icon-location" src={bg} alt="Location Icon" />
          <img id="icon-profile" src={bg} alt="Profile Icon" />
          <img id="icon-phone" src={bg} alt="Phone Icon" />
          <img id="icon-email" src={bg} alt="Email Icon" />
          <img id="icon-play" src={iconPlay} alt="Play Icon" />
          <img id="icon-left" src={iconLeft} alt="Left Icon" />
          <img id="icon-right" src={iconRight} alt="Right Icon" />
          <img id="paintandquest-preview" src={preview1} alt="Preview 1" />

          {media.map(
            (item, index) =>
              item.type === "video" && (
                <video key={index} id={`video-${index}`} controls>
                  <source src={item.url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )
          )}

          <img id="coffeemachine-preview" src={preview2} alt="Preview 2" />
          <img id="peak-preview" src={preview3} alt="Preview 3" />

          <a-asset-item id="avatarModel" src={flower2}></a-asset-item>
          <a-asset-item id="flowerLeft" src={flowerModel}></a-asset-item>
          <a-asset-item id="flowerRight" src={flowerModel}></a-asset-item>
          <a-asset-item id="butterflyModel" src={butterfly2}></a-asset-item>
          <a-asset-item id="pigeon" src="../assets/models/bird_orange.glb"></a-asset-item>
          <a-asset-item
            id="butterfliesModel"
            src={butterfliesModel}
          ></a-asset-item>
        </a-assets>

        <Entity
          primitive="a-camera"
          position="0 0 0"
          look-controls="enabled: false"
          cursor="fuse: false; rayOrigin: mouse;"
          raycaster="far: 10000; objects: .clickable"
        ></Entity>

        <Entity
          smooth="true"
          id="mytarget"
          mindar-image-target="targetIndex: 0"
          mytarget
        >
          <Entity
            primitive="a-plane"
            src="#card"
            position="0 0 0.05"
            height="0.7176"
            width="1.3"
            rotation="0 0 0"
            visible={false}
          ></Entity>
          <Entity
            primitive="a-text"
            value={`In the Memory of ${fullname || "User"} \n 
    ${dob ? moment(dob).format("DD-MMMM-YYYY") : "Date of Birth"} - 
    ${dod ? moment(dod).format("DD-MMMM-YYYY") : "Date of Death"}`}
            color="black"
            align="center"
            width="1.3"
            position="0 0 0.05"
            visible={false}
          ></Entity>
          <Entity
            primitive="a-entity"
            visible={false}
            id="portfolio-panel"
            position="0 0 -0.01"
          >
            {media.map((item, index) => (
              <Entity
                id={`portfolio-item${index}`}
                key={item._id}
                visible={index === 0}
              >
                {item.type === "video" || item.type === "url" ? (
                  <>
                    <Entity
                      primitive="a-video"
                      src={`#video-${index}`}
                      id={`video-${index}`}
                      webkit-playsinline
                      playsinline
                      width="1.3"
                      height="0.7176"
                      position="0 0 0"
                    ></Entity>
                    <Entity
                      primitive="a-image"
                      id="paintandquest-preview-button"
                      className="clickable"
                      src="#paintandquest-preview"
                      alpha-test="0.5"
                      position="0 0 0"
                      visible={false}
                      height="0.7176"
                      width="1.3"
                    ></Entity>
                    <Entity
                      primitive="a-image"
                      visible={true}
                      id={`play-button-${index}`}
                      src="#icon-play"
                      position="0 0 0.01"
                      height="0.26"
                      width="0.26"
                    ></Entity>
                  </>
                ) : (
                  <>
                    <Entity
                      primitive="a-image"
                      id={`video-${index}`}
                      src={item.url}
                      alpha-test="0.5"
                      position="0 0 0"
                      height="0.7176"
                      width="1.3"
                    ></Entity>
                    <Entity
                      primitive="a-image"
                      id="paintandquest-preview-button"
                      className="clickable"
                      src="#paintandquest-preview"
                      alpha-test="0.5"
                      position="0 0 0"
                      visible={false}
                      height="0.7176"
                      width="1.3"
                    ></Entity>
                  </>
                )}
              </Entity>
            ))}

            <Entity
              primitive="a-image"
              visible={false}
              id="portfolio-left-button"
              className="clickable"
              src="#icon-left"
              position="-0.91 0 0"
              height="0.195"
              width="0.195"
            ></Entity>
            <Entity
              primitive="a-image"
              visible={false}
              id="portfolio-right-button"
              className="clickable"
              src="#icon-right"
              position="0.91 0 0"
              height="0.195"
              width="0.195"
            ></Entity>
          </Entity>

          {/* <Entity
    primitive="a-gltf-model"
    id="avatar"
    rotation="0 0 0"
    position="0 0 0"
    scale="0.325 0.325 0.325"
    src="#avatarModel"
    animation-mixer="clip: *; loop: repeat"
  ></Entity> */}

          <Entity
            primitive="a-gltf-model"
            id="flowerLeft"
            rotation="0 0 0"
            position="-0.845 -0.65 0"
            scale="0.65 0.65 0.65"
            src="#flowerLeft"
          ></Entity>

          <Entity
            primitive="a-gltf-model"
            id="flowerRight"
            rotation="0 0 0"
            position="0.52 -0.65 0"
            scale="0.65 0.65 0.65"
            src="#flowerLeft"
          ></Entity>

          <Entity
            primitive="a-gltf-model"
            id="butterfly"
            rotation="0 0 0"
            position="-0.39 0.26 0.65"
            scale="0.195 0.195 0.195"
            src="#butterflyModel"
            animation-mixer="clip: Take 01; loop: repeat"
          ></Entity>
            <Entity
    primitive="a-gltf-model"
    id="pigeon"
    rotation="0 -45 0"
    position="0.46 -0.7 -0.4"
    scale="0.2 0.2 0.2"
    src="#pigeon"
    animation-mixer="clip: anim; loop: pingpong;"
  ></Entity>

          <Entity
            primitive="a-gltf-model"
            id="butterflies"
            rotation="0 0 0"
            position="0 -0.65 0.52"
            scale="0.52 0.52 0.52"
            src="#butterfliesModel"
            animation-mixer="clip: Take 001; loop: repeat"
          ></Entity>
        </Entity>
      </Scene>
    </div>
  );
};

export default ARTemplate3;
