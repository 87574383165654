import { Compiler } from 'mind-ar/dist/mindar-image.prod';
import { uploadTarget } from '../user/user.service';

const uploadFile = async (buffer) => {
  const blob = new Blob([buffer]);
  const formData = new FormData();
  formData.append('attachment', blob, 'targets.mind');

  try {
    const response = await uploadTarget(formData);
    console.log("response", response.data);
    return response?.data?.data;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
};

const loadImage = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });
};

export const compileTargetImage = async (imageSrc) => {
  const compiler = new Compiler();
  const images = [];

  try {
    const image = await loadImage(imageSrc);
    images.push(image);
    await compiler.compileImageTargets(images, (progress) => {
      console.log(`progress: ${progress.toFixed(2)}%`);
    });
    // eslint-disable-next-line
    const exportedBuffer = await compiler.exportData();
    const fileUrl = await uploadFile(exportedBuffer);
    return fileUrl;
  } catch (error) {
    console.error('Error compiling image:', error);
    throw error;
  }
};
